import styled from 'styled-components'

interface IStaticTextProps {
    displayBlock?: boolean
    textColor?: string
    textSize?: string
    bold?: boolean
    italic?: boolean
    marginLeft?: string
    marginRight?: string
    marginTop?: string
    marginBottom?: string
    textAlign?: string
    minWidth?: string
    maxHeight?: string
    minHeight?: string
    flex?: string
    border?: string
    borderRadius?: string
    backgroundColor?: string
    padding?: string
}

export const StaticText = styled.div<IStaticTextProps>`
    display: ${props => props.displayBlock ? 'block' : 'inline-block'};
    font-size: ${props => props.textSize || '1em'};
    color: ${props => props.textColor || '#000'};
    font-style: ${props => props.italic ? 'italic' : ''};
    font-weight: ${props => props.bold ? 'bold' : ''};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    text-align: ${props => props.textAlign || 'left'};
    min-width: ${props => props.minWidth || 'auto'};
    flex: ${props => props.displayBlock ? props.flex || '1' : 'none'};
    max-width: 100%;
    padding: ${props => props.padding || 'none'};
    border: ${props => props.border || 'none'};
    border-radius: ${props => props.borderRadius || 'none'};
    background-color: ${props => props.backgroundColor || 'transparent'};
    white-space: pre-wrap;
    max-height: ${props => props.maxHeight || 'auto'};
    height: ${props => props.minHeight || props.maxHeight || 'auto'};
    min-height: ${props => props.minHeight || 'auto'};
`