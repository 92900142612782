import { TipoOrigem } from "../enums/TipoOrigem.enum"

export class ItemPedidoAberto {
    pedidoItemId?: number | null = null
    fornecedorId: number = 0
    itemCardapioId: number = 0
    precoId: number = 0
    sessaoClienteId?: number | null = null
    clienteId?: string | null = null
    origem: string = ''
    tipoOrigem: TipoOrigem = TipoOrigem.Site
    quantidade: number = 0
}