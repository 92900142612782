import React, { useState } from 'react'
import { SaborPorFornecedor } from '../../../models/SaborPorFornecedor'
import { AlertService } from '../../../services/AlertService'
import Modal from '../../Modal/Modal'
import CardSabor from './CardSabor'

interface IModalSaboresProps {
    visible: boolean
    close: (saboresSelecionados: SaborPorFornecedor[]) => void
    produtoId: number
    saboresDisponiveis: SaborPorFornecedor[]
    saboresPedido: SaborPorFornecedor[]
    categoriaNome: string
    escolher: boolean
    quantidadeMaxima: number
    ocultarPrecoSabores: boolean
}

const ModalSabores: React.FC<IModalSaboresProps> = ({
    visible,
    close,
    produtoId,
    saboresDisponiveis,
    saboresPedido,
    categoriaNome,
    escolher,
    quantidadeMaxima,
    ocultarPrecoSabores
}) => {

    const [saboresSelecionados, setSaboresSelecionados] = useState<SaborPorFornecedor[]>(saboresPedido || []);

    const aoFechar = () => {
        close(saboresSelecionados || [])
    }

    const aoEscolher = (sabor: SaborPorFornecedor) => {
        const saborPedido = saboresSelecionados.find(s => s.id === sabor.id)
        
        if (!saborPedido) {
            const novaQuantidade = saboresSelecionados.length + 1
            if (quantidadeMaxima > 0 && novaQuantidade > quantidadeMaxima) {
                const alert = new AlertService();
                if (quantidadeMaxima === 1) {
                    alert.info('Atenção!', `Escolha no máximo um sabor.`)
                } else {
                    alert.info('Atenção!', `Escolha no máximo ${quantidadeMaxima} sabores.`)
                }
                return
            }

            const novoSabor = {...sabor}
            const novosSaboresSelecionados = [...saboresSelecionados]
            novosSaboresSelecionados.push(novoSabor)
            setSaboresSelecionados(novosSaboresSelecionados)
            return
        }

        const novosSaboresSelecionados = saboresSelecionados.filter(s => s.id !== sabor.id)
        setSaboresSelecionados(novosSaboresSelecionados)
    }

    const renderBody = () => {
        if (!saboresDisponiveis) return null

        return saboresDisponiveis
            .filter(sabor => sabor.id !== produtoId)
            .sort((sa, sb) => {
                if (sa.nome > sb.nome) return +1
                if (sa.nome < sb.nome) return -1
                return 0
            })
            .map(sabor => {
                const saborPedido = saboresSelecionados.filter(s => s.id === sabor.id).length > 0
                return (
                    <CardSabor 
                        key={`sabor-fornecedor-${sabor.id}`}
                        nome={sabor.nome}
                        detalhes={sabor.detalhes}
                        preco={sabor.preco}
                        categoriaNome={sabor.categoria}
                        escolher={escolher}
                        aoEscolher={() => aoEscolher(sabor)}
                        selecionado={saborPedido}
                        ocultarPrecoSabores={ocultarPrecoSabores}
                    />
                )
            })
    }

    return (
        <Modal
            visible={visible}
            title={'Sabores'}
            onClose={aoFechar}
        >
            {renderBody()}
        </Modal>
    )
}
  
export default ModalSabores