import styled from 'styled-components'
import {isMobile} from 'react-device-detect'

interface ISideBarProps {
    position?: string
    top?: string
}

export const SideBar = styled.div<ISideBarProps>`
    display: flex;
    flex-direction: row;
    float: ${props => props.position ? (props.position === 'right' ? 'right' : 'left') : 'none' };
    right: ${props => props.position ? (props.position === 'right' ? (isMobile ? '10px' : '0px') : 'auto') : 'auto' };
    left: ${props => props.position ? (props.position === 'left' ? (isMobile ? '10px' : '0px') : 'auto') : 'auto' };
    position: absolute;
    margin-top: ${props => props.top || '0px'};
    align-items: center;
    align-content: center;
    justify-content: center;
    width: ${props => props.position === 'center' ? '100%' : 'auto'};
    z-index: ${props => props.position === 'center' ? '5' : '10'};
`