import React from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page/Page'
import Fixed from '../../components/Fixed/Fixed'
import NavMenuLC from '../../components/NavMenuLC/NavMenuLC'
import BodyNav from '../../components/BodyNav/BodyNav'
import {Card} from '../../components/Card/Card'
import { StaticText } from '../../components/StaticText/StaticText'
import { Container } from '../../components/Container/Container'
import StorageService from '../../services/StorageService'

const ResumoPedidoFechado: React.FC = () => {

  const history = useHistory()

  const storage = new StorageService()

  const pedidoFechado = storage.obterPedidoFechadoParaResumo()
  const nomeFornecedor = pedidoFechado?.fornecedor?.nome
  const fornecedor = nomeFornecedor || 'o fornecedor'

  const voltar = () => {
      history.push(`/`)
  }

  window.scrollTo(0, 0)

  return (
    <Page>
      <Fixed position='top'>
        <NavMenuLC clickVoltar={voltar} textCenter='Resumo' />
      </Fixed>
      <BodyNav bottom='85px'>
        <Card>
            <Container columnDirection padding='10px'>
                <StaticText textAlign='center'>Seu pedido já está com {fornecedor}.</StaticText>
                <StaticText textAlign='center'>Em breve deve estar pronto.</StaticText>
            </Container>
            <Container columnDirection padding='10px'>
                <StaticText textAlign='center' textColor='var(--color-gray-2)'>Qualquer problema entre em contato com {fornecedor}.</StaticText>
            </Container>
            <Container columnDirection padding='10px'>
                <StaticText textAlign='center' bold textColor='var(--color-green)'>Bom apetite!</StaticText>
                <div style={{fontSize:'1.5em', textAlign:'center'}}>
                    &#128521;
                </div>
            </Container>
        </Card>
      </BodyNav>
    </Page>
  )
}
  
export default ResumoPedidoFechado