import React from 'react'
import BarButton from '../BarButton/BarButton'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

interface IBarButtonVoltarProps {
    click?: () => void
}

const BarButtonVoltar: React.FC<IBarButtonVoltarProps> = ({
    click
}) => {
    return (
        <BarButton click={click} iconLeft={faChevronLeft} text='Voltar' noBorder />
    )
}
  
export default BarButtonVoltar