import { LocalizacaoService } from '../../../services/LocalizacaoService'
import { AlertService } from '../../../services/AlertService'

const useHomeService = () => {

    const localizador = new LocalizacaoService()
    const alert = new AlertService()

    return {
        localizador,
        alert
    }
}

export default useHomeService