import React from 'react'
import logo from '../../assets/logo_100.png'

interface IPlateloLogoProps {
    size?: string
}

const PlateloLogo: React.FC<IPlateloLogoProps> = ({
    size
}) => {

    const logoSize = size ? `${size}px` : '100px'

    return (
        <img
            src={logo}
            alt='Platelo'
            style={{
                width: logoSize,
                height: logoSize,
                maxWidth: logoSize,
                maxHeight: logoSize
            }}
        />
    )
}

export default PlateloLogo