import React from 'react'
import { TabMenuContainer } from './styles'

const TabMenu: React.FC = ({
    children
}) => (
    <TabMenuContainer>
        {children}
    </TabMenuContainer>
)

export default TabMenu