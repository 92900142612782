import React from 'react'
import {Card} from '../Card/Card'
import {Container} from '../Container/Container'
import {StaticText} from '../StaticText/StaticText'
import {currencyFormat} from '../../services/CurrencyService'

interface ICardTotalizadorProps {
    numeroItens: number
    numeroItensSemPreco: number
    subTotal: number
    total?: number
    taxaEntrega?: number
    taxa: string
    taxaColuna: number
}

const CardTotalizador: React.FC<ICardTotalizadorProps> = ({
    numeroItens,
    numeroItensSemPreco,
    subTotal,
    taxaEntrega,
    total,
    taxa,
    taxaColuna
}) => {
    
    let texto = ''

    if (numeroItens > 0) {
        if (numeroItens === 1) {
            texto += `Um item`
        } else {
            texto += `${numeroItens} itens`
        }

        if (numeroItensSemPreco > 0) {
            if (numeroItensSemPreco === 1) {
                texto += ' (um item sem preço)'
            } else {
                texto += ` (${numeroItensSemPreco} itens sem preço)`
            }
        }

        if (taxaEntrega && taxaEntrega > 0) {
            const taxaFormatada = currencyFormat(taxaEntrega, 'R$')
            texto += ` + ${taxaFormatada} de taxa de entrega`
        }
    } else {
        texto = 'Carrinho vazio!'
    }

    const emojiRostoChorando = numeroItens <= 0 ? <div><span style={{fontSize:'1.5em'}}>&#x1F622;</span></div> : null
    const textSubTotal = ((subTotal > 0 || (subTotal === 0 && numeroItens > 0)) && !total) ? (
        <StaticText textSize='1.3em' textColor='var(--color-green)' bold>
            {currencyFormat(subTotal, 'R$ ')}
        </StaticText>
    ) : null
    const textTotal = (total && total > 0) ? (
        <StaticText textSize='1.3em' textColor='var(--color-green)' bold>
            {currencyFormat(total || 0, 'R$ ')}
        </StaticText>
    ) : null

    const linhas: string[] = []
    if (taxa) {
        if (taxaColuna > 0 && taxa.length > taxaColuna) {
            const meio = Math.ceil(taxaColuna / 1.5)
            let restoTaxa = taxa
            while (restoTaxa) {
                const separador = restoTaxa.indexOf(' ', meio)
                if (separador >= 0) {
                    const parte = restoTaxa.substr(0, separador)
                    linhas.push(parte)
                    restoTaxa = restoTaxa.substr(separador)
                } else {
                    linhas.push(restoTaxa)
                    restoTaxa = ''
                }
            }
        } else {
            linhas.push(taxa)
        }
    }

    const textTaxa = taxa && (subTotal > 0 || (subTotal === 0 && numeroItens > 0)) && !total ? (
        <StaticText textSize='0.8em' textColor='var(--color-orange-1)' textAlign='center'>
            {linhas.map((linha, linhaIndex) => <div key={`ln-${linhaIndex}`}>{linha}</div>)}
        </StaticText>
    ) : null

    return (
        <Card backgroundColor='#fff' borderRadiusBottom={'10px'} >
            <Container columnDirection center paddingTop='10px' paddingBottom='10px'
                borderBottom={'3px solid var(--color-gray-4)'}
            >
                {emojiRostoChorando}
                <StaticText textSize='0.8em' textColor='var(--color-green)'>
                    {texto}
                </StaticText>
                {textSubTotal}
                {textTotal}
                {textTaxa}
            </Container>
        </Card>
    )
}
  
export default CardTotalizador