import { ItemCardapio } from "./ItemCardapio"
import { PrecoItemCardapio } from "./PrecoItemCardapio"
import { PedidoItemOpcao } from "./PedidoItemOpcao"
import { PedidoAdicional } from "./PedidoAdicional"
import { SaborPedido } from "./SaborPedido"
import { EstrategiaPreco } from "../enums/EstrategiaPreco.enum"

export class PedidoItem {
    pedidoItemId: number = 0
    pedidoId: number = 0
    itemCardapio?: ItemCardapio | null = null
    itemCardapioId: number = 0
    itemCardapioNome: string = ''
    itemCardapioDetalhes: string = ''
    precoItemCardapioId?: number | null = null
    precoItemCardapio?: PrecoItemCardapio | null = null
    precoItemCardapioDescricao: string = ''
    quantidade: number = 0
    preco?: number = 0
    categoriaId: number = 0
    ativo: boolean = true
    dataCadastro?: Date | null = null
    opcoes: PedidoItemOpcao[] = []
    adicionais: PedidoAdicional[] = []
    sabores: SaborPedido[] = []
    precoItemCardapioEstrategiaPreco: EstrategiaPreco = EstrategiaPreco.PrecoDefinido
    precoItemCardapioAgrupadorMultisabores: string = ''
    itemCardapioSaboresQuantidadeMinima: number = 0
    itemCardapioSaboresQuantidadeMaxima: number = 0
    indisponivel: boolean = false
}