import React from 'react'
import { FixedContainerTop, FixedContainerBottom } from './Styles'

interface IFixedProps {
    position: string
}

const Fixed: React.FC<IFixedProps> = ({
    position,
    children
}) => {

    if (position === 'top') {
        return (
            <FixedContainerTop>
                {children}
            </FixedContainerTop>
        )
    }

    if (position === 'bottom') {
        return (
            <FixedContainerBottom>
                {children}
            </FixedContainerBottom>
        )
    }

    return null
}

export default Fixed