import React from 'react'
import CardFornecedor from './CardFornecedor'
import { DadosSobreFornecedor } from '../../models/DadosSobreFornecedor'

interface ICardFornecedorBottom {
    dadosFornecedor?: DadosSobreFornecedor
    municipioIdentificador: string
    estadoIdentificador: string
}

const CardFornecedorBottom: React.FC<ICardFornecedorBottom> = ({
    dadosFornecedor,
    municipioIdentificador,
    estadoIdentificador
}) => {

    return (
        <CardFornecedor
          nome={dadosFornecedor?.nome || ''}
          identificador={dadosFornecedor?.codigo || '@for'}
          municipioIdentificador={municipioIdentificador}
          estadoIdentificador={estadoIdentificador}
          funcionamento={dadosFornecedor?.funcionamento || []}
          logo={dadosFornecedor?.logo}
          backgroundColor={'#fff'} 
          noBorder
          noLink
          containerBorderTop={'3px solid var(--color-gray-4)'}
          borderRadiusTop={'10px'}
          observacao={dadosFornecedor?.observacao}
        />
    )
}
  
export default CardFornecedorBottom