import React from 'react'
import BarButton from '../BarButton/BarButton'

interface IBarButtonAvancarProps {
    click?: () => void
    text?: string
    noPrimary?: boolean
    loading?: boolean
    noBorder?: boolean
}

const BarButtonAvancar: React.FC<IBarButtonAvancarProps> = ({
    click,
    text,
    noPrimary,
    loading,
    noBorder
}) => {
    return (
        <BarButton click={click} text={text} primary={noPrimary ? false : true} loading={loading} noBorder={noBorder} />
    )
}
  
export default BarButtonAvancar