export class OpcaoItemPorFornecedor {
    id: number = 0
    itemCardapioIdOpcao: number = 0
    quantidadeMinima: number = 0
    quantidadeMaxima: number = 0
    quantidadeInicial: number = 0
    nome: string = ''
    detalhes: string = ''
    preco: number = 0
    ativo: boolean = true
    excluido: boolean = false
    grupoId?: number
}