import React from 'react'
import {Container, Local} from './styles'
import {StaticText} from '../../components/StaticText/StaticText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

interface ILocalizacaoAtualProps {
    municipio: string
    estado: string
    click: () => void
}

const LocalizacaoAtual: React.FC<ILocalizacaoAtualProps> = ({
    municipio,
    estado,
    click
}) => (
    <Container onClick={() => click()}>
        <Local>
            <StaticText textSize='0.7em'>Localização atual</StaticText>
            <StaticText textSize='0.9em' bold>{`${municipio}, ${estado}`}</StaticText>
        </Local>
        <div>
            <FontAwesomeIcon icon={faMapMarkerAlt} size='lg' color='var(--color-gray-2)' />
        </div>
    </Container>
)
  
export default LocalizacaoAtual