import React from 'react'
import { DadosSobreFornecedor } from '../../models/DadosSobreFornecedor'
import FixedBottom from '../Fixed/FixedBottom'
import CardFornecedorBottom from './CardFornecedorBottom'

interface ICardFornecedorBottom {
    dadosFornecedor?: DadosSobreFornecedor
    municipioIdentificador: string
    estadoIdentificador: string
}

const CardFornecedorFixedBottom: React.FC<ICardFornecedorBottom> = ({
    dadosFornecedor,
    municipioIdentificador,
    estadoIdentificador
}) => {

    return (
        <FixedBottom>
            <CardFornecedorBottom
                dadosFornecedor={dadosFornecedor}
                municipioIdentificador={municipioIdentificador}
                estadoIdentificador={estadoIdentificador}
            />
        </FixedBottom>
    )
}
  
export default CardFornecedorFixedBottom