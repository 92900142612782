import { ItemCardapio } from "./ItemCardapio"
import { PrecoItemCardapio } from "./PrecoItemCardapio"

export class PedidoAdicional {
    pedidoAdicionalId: number = 0
    pedidoItemId: number = 0
    quantidade: number = 0
    preco: number = 0
    dataCadastro?: Date | null = null
    itemCardapioAdicional?: ItemCardapio | null = null
    itemCardapioAdicionalId: number = 0
    itemCardapioAdicionalNome: string = ''
    itemCardapioAdicionalDetalhes: string = ''
    precoItemCardapio?: PrecoItemCardapio | null = null
    precoItemCardapioId: number = 0
    precoItemCardapioNome: string = ''
    indisponivel: boolean = false
}