import { Login } from '../models/Login'
import {RespostaBase} from '../models/RespostaBase'
import { ValorBase } from '../models/ValorBase'
import { Cliente } from '../models/Cliente'
import { NovoCliente } from '../models/NovoCliente'
import HttpService from './HttpService'
import StorageService from './StorageService'

export class ClienteService {
    private http: HttpService = new HttpService()
    private storage: StorageService = new StorageService()

    autenticarLogin = (login: Login) => {
        return this.http.post<RespostaBase<ValorBase<string>>, Login>('AuthCliente/Login', login)
    }

    obterPorCodigo = (codigo: string) => {
        return this.http.get<RespostaBase<Cliente>>(`Clientes/PorCodigo/${codigo}`)
    }

    salvarPorCodigo = (codigo: string, cliente: Cliente) => {
        return this.http.post<RespostaBase<ValorBase<boolean>>, Cliente>(`Clientes/PorCodigo/${codigo}`, cliente)
    }

    salvarNovo = (novoCliente: NovoCliente) => {
        return this.http.post<RespostaBase<string>, NovoCliente>(`NovoCliente/SemSessao`, novoCliente)
    }

    obterLinkNovaSenha = (email: string) => {
        return this.http.get<RespostaBase<ValorBase<boolean>>>(`LinkNovaSenha?email=${email}`)
    }
}