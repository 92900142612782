import React from 'react'

interface IImagemCategoriaProps {
    size?: string
    imagem: any
    alt?: string
}

const ImagemCategoria: React.FC<IImagemCategoriaProps> = ({
    size,
    imagem,
    alt
}) => {

    const imageSize = size ? `${size}px` : '100px'

    return (
        <img
            src={imagem}
            alt={alt}
            style={{
                width: imageSize,
                height: imageSize,
                maxWidth: imageSize,
                maxHeight: imageSize,
                borderRadius: '5px',
                border: '2px solid var(--color-gray-4)'
            }}
        />
    )
}

export default ImagemCategoria