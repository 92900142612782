import { useEffect, useRef } from 'react'

export default function useIsMountedRef(){
    const isMountedRef = useRef(false)

    useEffect(() => {
      isMountedRef.current = true
      return () => { isMountedRef.current = false }
    });

    return isMountedRef
}