import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {StaticText} from '../StaticText/StaticText'
import { Body, Container, Header, Footer, BodyContent, ContainerFilter, HeaderBody, HeaderTitle } from './styles'
import Field from '../Field/Field'

interface IModalProps {
    visible: boolean
    title?: string
    onClose?: () => void
    footer?: any
    onFilter?: (value: any) => void
}

const Modal: React.FC<IModalProps> = ({
    children,
    visible,
    title,
    onClose,
    footer,
    onFilter
}) => {

    if (!visible) return null

    let minHeight = onFilter ? '92px' : null
    let topBody = onFilter ? '93px' : null

    const filter = onFilter ? (
        <HeaderBody>
            <ContainerFilter>
                <Field 
                    type='search' label='' flexSizeInput={'1'} 
                    center rowDirection paddingContainer={'0px'} paddingInput={'5px'} 
                    change={(value) => onFilter(value)}
                    autoFocus
                />
            </ContainerFilter>
        </HeaderBody>
    ) : null

    const footerRender = footer ? (
        <Footer>
            {footer}
        </Footer>
    ) : null

    return (
        <Container>
            <Header minHeight={minHeight}>
                <HeaderTitle>
                    <StaticText displayBlock bold flex='1' textSize='1.1em'>{title}</StaticText>
                    <FontAwesomeIcon icon={faTimes} size='lg' color='#000' title='Fechar' style={{cursor:'pointer'}} onClick={onClose}/>                
                </HeaderTitle>
                {filter}
            </Header>
            <Body top={topBody}>
                <BodyContent>
                    {children}
                </BodyContent>
            </Body>
            {footerRender}
        </Container>
    )
}
  
export default Modal