import Swal from 'sweetalert2'

const confirmButtonColor = '#0277bd'
const cancelButtonColor = 'rgb(151, 0, 0)'

export class AlertService {

    error = (title: string, text: string) => {
        Swal.fire({
            title,
            text,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: confirmButtonColor
          })
    }

    info = (title: string, text: string) => {
        Swal.fire({
            title,
            text,
            icon: 'info',
            confirmButtonText: 'OK',
            confirmButtonColor: confirmButtonColor
          })
    }

    warning = (title: string, text: string) => {
        Swal.fire({
            title,
            text,
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: confirmButtonColor
          })
    }

    success = (title: string, text: string) => {
        Swal.fire({
            title,
            text,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: confirmButtonColor
          })
    }

    question = (title: string, text: string) => { 
        return Swal.fire({
            width: 500,
            padding: '1em',
            title,
            html: text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            focusCancel: true,
            confirmButtonColor: confirmButtonColor,
            cancelButtonColor: cancelButtonColor
          })
    }

}