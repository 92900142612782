import styled from 'styled-components'

interface IButtonProps {
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
    textSize?: string
    textColor?: string
    textAlign?: string
}

export const Button = styled.button<IButtonProps>`
    flex: 1;
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    padding: 0;
    border: none!important;
    text-decoration: none;
    color: ${props => props.textColor || 'var(--color-blue-1)'} !important;
    background-color: transparent;
    font-size: ${props => props.textSize || '0.9em'};
    text-align: ${props => props.textAlign || 'left'};
    font-weight: 500;

    &:hover, &:active, &:focus {
        text-decoration: underline!important;
        border: none!important;
        outline: none!important;
    }
`