import React from 'react'
import {SideBar} from '../SideBar/SideBar'
import PlateloLogo from '../PlateloLogo/PlateloLogo'
import {StaticText} from '../StaticText/StaticText'
import { isMobile } from 'react-device-detect'
import {ContainerCenter} from './styles'

interface IPlateloSideBarProps {
    center?: boolean
}

const PlateloSideBar: React.FC<IPlateloSideBarProps> = ({
    center
}) => {

    if (center) {
        return (
            <ContainerCenter top={isMobile ? '8px' : '5px'}>
                <PlateloLogo size='28' />
            </ContainerCenter>
        )
    }

    return (
        <SideBar position='left' top={isMobile ? '8px' : '5px'}>
            <PlateloLogo size='28' />
            <StaticText
                textColor='var(--color-blue-platelo)'
                textSize={isMobile ? '1.2em' : '1.1em'}
                marginLeft='5px'
                bold
            >Platelo</StaticText>        
        </SideBar>
    )
}

export default PlateloSideBar