import React, { useState } from 'react'
import { ItemAdicionalPorFornecedor } from '../../../models/ItemAdicionalPorFornecedor'
import { PedidoAdicional } from '../../../models/PedidoAdicional'
import Modal from '../../Modal/Modal'
import CardAdicional from './CardAdicional'

interface IModalAdicionaisProps {
    visible: boolean
    close: (adicionaisPedido: PedidoAdicional[]) => void
    fornecedorId: number
    adicionaisFornecedor?: ItemAdicionalPorFornecedor[]
    adicionaisPedido?: PedidoAdicional[]
}

const ModalAdicionais: React.FC<IModalAdicionaisProps> = ({
    visible,
    close,
    fornecedorId,
    adicionaisFornecedor,
    adicionaisPedido
}) => {

    const [adicionais, setAdicionais] = useState<PedidoAdicional[]>(adicionaisPedido || []);

    const aoFechar = () => {
        close(adicionais || [])
    }

    const clickAumentar = (adicionalId: number, precoId: number) => {
        let novoAdicionais = [...adicionais]
        let adicional = novoAdicionais?.find(ap => ap.precoItemCardapioId === precoId && ap.itemCardapioAdicionalId === adicionalId)
        if (adicional) {
            adicional.quantidade += 1
            novoAdicionais = novoAdicionais.map(adic => 
                adic.precoItemCardapioId === adicional?.precoItemCardapioId && adic.itemCardapioAdicionalId === adicional.itemCardapioAdicionalId
                    ? adicional
                    : adic
            )
        } else {
            adicional = new PedidoAdicional()
            adicional.quantidade = 1
            adicional.precoItemCardapioId = precoId
            adicional.itemCardapioAdicionalId = adicionalId
            novoAdicionais?.push(adicional)
        }
        setAdicionais(novoAdicionais)
    }

    const clickDiminuir = (adicionalId: number, precoId: number) => {
        let novoAdicionais = [...adicionais]
        let adicional = novoAdicionais?.find(adic => adic.precoItemCardapioId === precoId && adic.itemCardapioAdicionalId === adicionalId)
        if (adicional) {
            adicional.quantidade -= 1
        }
        novoAdicionais = novoAdicionais.filter(adic => adic.quantidade > 0)
        setAdicionais(novoAdicionais)
    }

    const renderBody = () => {
        if (!adicionaisFornecedor) return null

        return adicionaisFornecedor.map(adicionalFornecedor => {
            const precosAdicionaisPedido = adicionais?.filter(ap => ap.itemCardapioAdicionalId === adicionalFornecedor.id)
            return (
                <CardAdicional 
                    key={`adicional-fornecedor-${adicionalFornecedor.id}`}
                    nome={adicionalFornecedor.nome}
                    detalhes={adicionalFornecedor.detalhes}
                    precos={adicionalFornecedor.precos}
                    precosAdicionaisPedido={precosAdicionaisPedido}
                    fornecedorId={fornecedorId}
                    clickAumentar={(precoId) => clickAumentar(adicionalFornecedor.id, precoId)}
                    clickDiminuir={(precoId) => clickDiminuir(adicionalFornecedor.id, precoId)}
                />
            )
        })
    }

    return (
        <Modal
            visible={visible}
            title={'Adicionais'}
            onClose={aoFechar}
        >
            {renderBody()}
        </Modal>
    )
}
  
export default ModalAdicionais