import React from 'react'

interface ILinkProps {
    href: string
    flexSize?: string
    marginLeft?: string
    marginRight?: string
}

const Link: React.FC<ILinkProps> = ({
    children,
    href,
    flexSize,
    marginLeft,
    marginRight
}) => (
    <a href={`#${href}`} style={{
        fontStyle:'normal',
        textDecoration: 'none',
        cursor: 'pointer',
        flex: flexSize || 'auto',
        marginLeft: marginLeft || 'none',
        marginRight: marginRight || 'none'
    }}>{children}</a>
)
  
export default Link