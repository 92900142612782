import styled from 'styled-components'

export const ItemIndisponivel = styled.span`
    color: red;
    font-size: 0.8em;
    margin-left: 5px;
`

export const TextoEmAberto = styled.span`
    color: green;
    font-size: 0.8em;
    margin-left: 5px;
    font-weight: bold;
`