import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { DadosSobreFornecedor } from '../../../models/DadosSobreFornecedor'
import { Pedido } from '../../../models/Pedido'
import { IOptions } from '../../../components/CheckboxField/CheckboxField'
import { TipoEntrega } from '../../../enums/TipoEntrega.enum'
import { Localizacao } from '../../../models/Localizacao'
import { Area } from '../../../enums/Area.enum'

type CardapioParams = {
    fornecedor: string
    municipio: string
    estado: string
}

const useFechaPedidoState = () => {

    const {fornecedor, municipio, estado} = useParams<CardapioParams>()
    const [dadosFornecedor, setDadosFornecedor] = useState<DadosSobreFornecedor>()
    const [estadoIdentificador, setEstadoIdentificador] = useState<string>(estado)
    const [municipioIdentificador, setMunicipioIdentificador] = useState<string>(municipio)
    const [pedido, setPedido] = useState<Pedido>()
    const [etapa, setEtapa] = useState(0)
    const [areaAberta, setAreaAberta] = useState(true)
    const [tipoEntrega, setTipoEntrega] = useState<TipoEntrega>(TipoEntrega.DeliveryOuRetirarNoLocal)
    const [nomeCliente, setNomeCliente] = useState('')
    const [pagamento, setPagamento] = useState<IOptions[]>([])
    const [observacao, setObservacao] = useState('')
    const [atualizacao, setAtualizacao] = useState(false)
    const [taxaEntrega, setTaxaEntrega] = useState(0)
    const [enderecoEstado, setEnderecoEstado] = useState('')
    const [enderecoMunicipio, setEnderecoMunicipio] = useState('')
    const [enderecoLogradouro, setEnderecoLogradouro] = useState('')
    const [enderecoArea, setEnderecoArea] = useState<Area>(Area.NaoInformado)
        
    return {
        fornecedor, municipio, estado,
        dadosFornecedor, setDadosFornecedor,
        estadoIdentificador, setEstadoIdentificador,
        municipioIdentificador, setMunicipioIdentificador,
        pedido, setPedido,
        etapa, setEtapa,
        areaAberta, setAreaAberta,
        tipoEntrega, setTipoEntrega,
        enderecoLogradouro, setEnderecoLogradouro,
        enderecoEstado, setEnderecoEstado,
        enderecoMunicipio, setEnderecoMunicipio,
        nomeCliente, setNomeCliente,
        pagamento, setPagamento,
        observacao, setObservacao,
        atualizacao, setAtualizacao,
        taxaEntrega, setTaxaEntrega,
        enderecoArea, setEnderecoArea
    }
}

export default useFechaPedidoState