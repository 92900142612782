import styled from 'styled-components'

interface IInputProps {
    padding?: string
    minWidth?: string
    flexSize?: string
}

export const Input = styled.input<IInputProps>`
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;

    padding: ${props => props.padding || '8px 10px'} !important;
    border: 1px solid var(--color-gray-4) !important;
    border-radius: 3px !important;

    min-width: ${props => props.minWidth || 'auto'} !important;
    flex: ${props => props.flexSize || 'normal'} !important;

    &:focus, &:focus-visible, &:active {
        box-shadow: 0px 0px 0px 3px var(--color-gray-4) !important;
        border: 1px solid var(--color-gray-3) !important;
        outline: 0px !important;
    }
`

export const InputSearch = styled.input<IInputProps>`
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;

    padding: ${props => props.padding || '8px 10px'} !important;
    border: 0px !important;
    border-radius: 0px !important;

    min-width: ${props => props.minWidth || 'auto'} !important;
    flex: ${props => props.flexSize || 'normal'} !important;

    &:focus, &:focus-visible, &:active {
        border: 0px !important;
        outline: 0px !important;
    }
`

export const TextArea = styled.textarea`
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;

    padding: 8px 10px !important;
    border: 1px solid var(--color-gray-4) !important;
    border-radius: 3px !important;
    resize: none !important;

    &:focus, &:focus-visible, &:active {
        box-shadow: 0px 0px 0px 3px var(--color-gray-4) !important;
        border: 1px solid var(--color-gray-3) !important;
        outline: 0px !important;
    }
`