import React from 'react'
import {Card} from '../Card/Card'
import {Container} from '../Container/Container'
import {StaticText} from '../StaticText/StaticText'
import LogoFornecedor from '../LogoFornecedor/LogoFornecedor'
import TextoFuncionamentoFornecedor from '../TextoFuncionamentoFornecedor/TextoFuncionamentoFornecedor'
import Link from '../Link/Link'
import { FuncionamentoParaDadosSobreFornecedor } from '../../models/FuncionamentoParaDadosSobreFornecedor'

interface ICardFornecedor {
    nome: string
    logo?: any
    identificador: string
    municipioIdentificador: string
    estadoIdentificador: string
    noBorder?: boolean
    noLink?: boolean
    backgroundColor?: string
    clickable?: boolean
    observacao?: string
    linkTo?: string
    funcionamento?: FuncionamentoParaDadosSobreFornecedor[]
    borderTop?: boolean
    containerBorderTop?: string
    containerBorderRadiusTop?: string
    marginTop?: string
    borderRadiusTop?: string
}

const CardFornecedor: React.FC<ICardFornecedor> = ({
    nome,
    logo,
    identificador,
    municipioIdentificador,
    estadoIdentificador,
    noBorder,
    backgroundColor,
    noLink,
    clickable,
    observacao,
    linkTo,
    funcionamento,
    borderTop,
    containerBorderTop,
    containerBorderRadiusTop,
    marginTop,
    borderRadiusTop
}) => {

    if (!nome) {
        return null
    }
    
    const linkBase = linkTo || 'cardapio'

    const renderCard = () => {
        const observacaoRender = observacao ? (
            <Container paddingLeft='10px' paddingRight='10px' paddingBottom='5px' paddingTop='5px' borderTop='1px solid var(--color-body)'>
                <StaticText textSize='0.6em' textColor='#000' textAlign='justify'>{observacao}</StaticText>
            </Container>
        ) : null

        const textFuncionamento = funcionamento && funcionamento.length > 0 ? funcionamento[0] : null

        return (
            <Card clickable={clickable} noBorder={noBorder} borderTop={borderTop} backgroundColor={backgroundColor} marginTop={marginTop} borderRadiusTop={borderRadiusTop}>
                <Container center padding='10px' borderTop={containerBorderTop} borderRadiusTop={containerBorderRadiusTop}>
                    <LogoFornecedor logo={logo || ''} nomeFornecedor={nome} />
                    <Container columnDirection={true} paddingLeft='8px'>
                        <StaticText textSize='1.2em' bold={true}>
                            {nome}
                        </StaticText>
                        <TextoFuncionamentoFornecedor 
                            funcionamento={textFuncionamento}
                        />
                    </Container>
                </Container>
                {observacaoRender}
            </Card>
        )
    }

    if (noLink)
        return renderCard()

    return (
        <Link href={`/${linkBase}/${identificador}/em/${municipioIdentificador}/${estadoIdentificador}`}>
            {renderCard()}
        </Link>
    )
}
  
export default CardFornecedor