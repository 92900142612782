import React, { useEffect, useState } from 'react'
import latinize from 'latinize'
import { OpcaoPorLocalizacao } from '../../../models/OpcaoPorLocalizacao'
import CardCategoria from '../../../components/CardCategoria/CardCategoria'
import {CardapioService} from '../../../services/CardapioService'
import Aguarde from '../../../components/Aguarde/Aguarde'
import Mensagem from '../../../components/Mensagem/Mensagem'

interface ITabCategoriasProps {
  estado: string
  municipio: string
  filtro?: string
}

const TabCategorias: React.FC<ITabCategoriasProps> = ({
  estado,
  municipio,
  filtro
}) => {

  const [opcoes, setOpcoes] = useState<OpcaoPorLocalizacao[]>([])
  const [carregando, setCarregando] = useState(true)

  useEffect(() => {
    const cardapioService = new CardapioService()
    cardapioService
      .obterApenasOpcoesPorLocalizacao(estado, municipio)
      .then(dados => {
        setCarregando(false)
        setOpcoes(dados || [])
      })
  }, [estado, municipio])

  const render = () => {
    const latfiltro = latinize(filtro || '').toLocaleLowerCase()
    const lista = filtro ? opcoes.filter(o => latinize(o.categoria).toLocaleLowerCase().indexOf(latfiltro) >= 0) : opcoes
    if (!lista.length) {
      return <Mensagem texto='Nenhuma categoria encontrada!' />
    }
    return lista.map((opcao) => {
        return (
          <CardCategoria key={`categoria-${opcao.identificador}`}
              opcaoIdentificador={opcao.identificador}
              opcaoCategoria={opcao.categoria}
              opcaoOpcoes={opcao.opcoes}
              municipioIdentificador={municipio}
              estadoIdentificador={estado}
          />
        )
    })
  }

  if (carregando) {
    return <Aguarde texto='Obtendo categorias...' />
  }

  return (
    <>
    {render()}
    </>
  )
}
  
export default TabCategorias