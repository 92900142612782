import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import BarButton from '../../../components/BarButton/BarButton'

interface IBarButtonEntrarProps {
    click?: () => void
}

const BarButtonEntrar: React.FC<IBarButtonEntrarProps> = ({
    click
}) => (
    <BarButton click={click} text='' marginLeft='5px' title='Entrar' iconLeft={faSignInAlt} noBorder/>
)

export default BarButtonEntrar