import React from 'react'
import { Container } from '../../Container/Container'
import {StaticText} from '../../StaticText/StaticText'
import Checkbox from '../../Checkbox/Checkbox'
import {isMobile} from 'react-device-detect'

interface ICardOpcionalGrupoProps {
    nome: string
    escolher?: boolean
    aoEscolher: () => void
    selecionado?: boolean
}

const CardOpcionalGrupo: React.FC<ICardOpcionalGrupoProps> = ({
    nome,
    escolher,
    aoEscolher,
    selecionado
}) => {

    const checkBoxRender = escolher ? (
        <Container columnDirection flexSize={isMobile ? '0.17' : '0.09'}>
            <Checkbox size={27} click={() => aoEscolher ? aoEscolher() : null} selected={selecionado} />
        </Container>
    ) : null

    return (
        <Container borderBottom='1px solid var(--color-gray-4)' padding='10px' center>
            {checkBoxRender}
            <Container columnDirection flexSize={'1'} clickable={escolher} onClick={() => escolher ? aoEscolher() : null}>
                <StaticText bold>{nome}</StaticText>
            </Container>
        </Container>
    )
}
  
export default CardOpcionalGrupo