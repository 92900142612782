import React from 'react'
import {Container} from '../../Container/Container'
import {StaticText} from '../../StaticText/StaticText'
import PainelQuantidade from '../../PainelQuantidade/PainelQuantidade'
import {PrecoItemPorFornecedor} from '../../../models/PrecoItemPorFornecedor'
import CardPreco from '../../CardPreco/CardPreco'
import { PedidoItem } from '../../../models/PedidoItem'

interface IPrecosProps {
    precos?: PrecoItemPorFornecedor[]
    fornecedorId: number
    clickAumentar?: (precoId: number) => void
    clickDiminuir?: (precoId: number) => void
    itens?: PedidoItem[]
    estaAumentandoPrecoId?: number
    estaDiminuindoPrecoId?: number
    produtoMultisabores: boolean
    categoriaProduto: string
    clickVerSabores?: (precoId: number, agrupador: string) => void
    clickEscolherSabores?: (precoId: number, agrupador: string) => void
    pageCarrinho?: boolean
}

const Precos: React.FC<IPrecosProps> = ({
    precos,
    fornecedorId,
    clickAumentar,
    clickDiminuir,
    itens,
    estaAumentandoPrecoId,
    estaDiminuindoPrecoId,
    produtoMultisabores,
    categoriaProduto,
    clickVerSabores,
    clickEscolherSabores,
    pageCarrinho
}) => {

    if (!precos || precos.length === 0) {
        return (
            <Container borderTop='1px solid var(--color-gray-4)' paddingTop='5px' paddingBottom='5px' center>
                <Container columnDirection>
                    <StaticText textSize='1.1em' bold textColor='var(--color-green)'>
                        Preço não informado
                    </StaticText>
                </Container>
                <PainelQuantidade quantidade={0} clickAumentar={() => {}} clickDiminuir={() =>{}} />
            </Container>
        )
    }

    const render = precos.map((preco) => {
        const item = itens?.find(i => i.precoItemCardapioId === preco.id)
        return (
            <CardPreco
                key={`preco-${preco.id}`}
                preco={preco}
                fornecedorId={fornecedorId} 
                clickAumentar={() => clickAumentar ? clickAumentar(preco.id) : null}
                clickDiminuir={() => clickDiminuir ? clickDiminuir(preco.id) : null}
                item={item}
                estaAumentando={estaAumentandoPrecoId === preco.id}
                estaDiminuindo={estaDiminuindoPrecoId === preco.id}
                produtoMultisabores={produtoMultisabores}
                categoriaProduto={categoriaProduto}
                clickVerSabores={() => clickVerSabores ? clickVerSabores(preco.id, preco.agrupadorMultisabores) : null}
                clickEscolherSabores={() => clickEscolherSabores ? clickEscolherSabores(preco.id, preco.agrupadorMultisabores) : null}
                pageCarrinho={pageCarrinho}
            /> 
        )
    })

    return (
        <>
            {render}
        </>
    )
}
  
export default Precos