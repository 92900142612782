import React from 'react'
import ic_add from '../../assets/ic_add.svg'
import ic_remove from '../../assets/ic_remove.svg'
import {StaticText} from '../StaticText/StaticText'
import { Container } from '../Container/Container'
import {Image} from './styles'
import Spinner from '../Spinner/Spinner'

interface IPainelQuantidadeProps {
    quantidade: number
    clickDiminuir?: () => void
    clickAumentar?: () => void
    estaDiminuindo?: boolean
    estaAumentando?: boolean
    aguardar?: boolean
}

const PainelQuantidade: React.FC<IPainelQuantidadeProps> = ({
    quantidade,
    clickDiminuir,
    clickAumentar,
    estaDiminuindo,
    estaAumentando,
    aguardar = true
}) => {

    const diminuir = () => {
        if (clickDiminuir) {
            clickDiminuir()
        }
    }

    const aumentar = () => {
        if (clickAumentar) {
            clickAumentar()
        }
    }

    const imgDiminuir = quantidade > 0
        ? (estaDiminuindo 
            ? <Spinner color='var(--color-gray-3)' style={{fontSize:'1.86em'}} />
            : <Image src={ic_remove} alt='Remover' onClick={diminuir} />)
        : null
    const txtQuantidade = quantidade > 0
        ? <StaticText textSize='1em' bold marginLeft='5px' marginRight='5px' minWidth='20px' textAlign='center'>
            {quantidade}
          </StaticText>
        : null
    const imgAumentar = (estaAumentando 
        ? <Spinner color='var(--color-gray-3)' style={{fontSize:'1.86em'}} />
        : <Image src={ic_add} alt='Adicionar' onClick={aumentar} />)

    return (
        <Container flexSize='0.5' center justifyContent={'flex-end'} paddingRight='3px'>
            {imgDiminuir}
            {txtQuantidade}
            {imgAumentar}
        </Container>
    )
}
  
export default PainelQuantidade