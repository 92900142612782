import styled from 'styled-components'

interface IContainerProps {
    paddingLeft?: string
    paddingRight?: string
    primary?: boolean
    minWidth: string
    marginLeft?: string
    marginRight?: string
    noBorder?: boolean
}

export const Container = styled.button<IContainerProps>`
    padding: ${props => props.noBorder || props.primary ? '4px 4px' : '2px 4px'};
    padding-left: ${props => props.paddingLeft || '3px'};
    padding-right: ${props => props.paddingRight || '3px'};
    font-size: 0.9em;
    background-color: ${props => props.primary ? 'var(--color-blue-1)' : 'transparent'};
    border-radius: 3px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: ${props => props.primary || props.noBorder ? 'none' : '1px solid var(--color-blue-1)'}; 
    min-width: ${props => props.minWidth};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};

    &:hover, &:active, &:focus  {
        background-color: ${props => props.primary ? 'var(--color-blue-platelo)' : 'var(--color-blue-5)'};
        color: ${props => props.primary ? '#fff' : 'var(--color-blue-platelo)'};
    }
`

export const Badge = styled.span`
    background-color: #6d73ff;
    border-radius: 50px;
    margin-left: 3px;
    padding: 1px 7px;
    font-size: 0.85em;
    color: #fff;
`