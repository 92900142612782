import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { StaticText } from '../StaticText/StaticText'
import {Container, Badge} from './styles'

interface IBarButtonProps {
    click?: () => void
    noBorder?: boolean
    title?: string
    text?: string
    inner?: boolean
    paddingLeft?: string
    paddingRight?: string
    marginLeft?: string
    marginRight?: string
    iconLeft?: any
    iconRight?: any
    primary?: boolean
    badgeRight?: string
    loading?: boolean
    iconSize?: any
}

const BarButton: React.FC<IBarButtonProps> = ({
    click,
    title,
    text,
    paddingLeft,
    paddingRight,
    marginLeft,
    marginRight,
    iconLeft,
    iconRight,
    primary,
    badgeRight,
    loading,
    noBorder,
    iconSize
}) => {

    const iconSizeDef = iconSize || 'lg'
    const colorText = primary ? '#fff' : 'var(--color-blue-1)'
    const staticTextMarginLeft = iconLeft && text ? '4px' : '0px'
    const staticTextMarginRight = iconRight && text ? '4px' : '0px'
    const buttonTitle = title || text || ''
    const css = primary ? '' : 'bar-button-no-primary'

    if (loading && iconLeft) iconLeft = faSpinner
    if (loading && iconRight) iconRight = faSpinner

    const onClick = () => click && !loading ? click() : {}

    const bagdeRightRender = badgeRight ? <Badge>{badgeRight}</Badge> : null
    const iconLeftRender = iconLeft ? <FontAwesomeIcon icon={iconLeft} size={iconSizeDef} color={colorText} title={buttonTitle} pulse={loading}/> : null
    const iconRightRender = iconRight ? <FontAwesomeIcon icon={iconRight} size={iconSizeDef} color={colorText} title={buttonTitle} pulse={loading}/> : null

    return (
        <Container 
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            primary={primary}
            noBorder={noBorder}
            minWidth={text ? '60px' : '0px'}
            onClick={onClick}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            {iconLeftRender}
            <StaticText marginLeft={staticTextMarginLeft} marginRight={staticTextMarginRight} textColor={colorText} className={css}>{text}</StaticText>
            {bagdeRightRender}
            {iconRightRender}
        </Container>
    )
}

export default BarButton