import React, {useEffect, useMemo} from 'react'
import Page from '../../components/Page/Page'
import useIsMountedRef from '../../hooks/useIsMountedRef'
import PageHeader from './components/PageHeader'
import PageBody from './components/PageBody'
import PageFooter from './components/PageFooter'
import useHome from './hooks/useHome'

const Home: React.FC = () => {

  const isMountedRef = useIsMountedRef()
  const {$, $exec} = useHome()

  useEffect(() => {
    $exec.clickLocalizacao(isMountedRef, false, false)
  }, [isMountedRef])

  const localizacaoDefinida = useMemo(() => 
    !$.obtendoLocalizacao && !!$.localizacao
  , [$.obtendoLocalizacao, $.localizacao])

  window.scrollTo(0, 0)

  return (
    <Page>
      <PageHeader
        activeMenu={$.activeMenu}
        localizacaoDefinida={localizacaoDefinida}
        clickEntrar={$exec.irParaEntrar}
        clickMenu={$exec.irParaMenu}
        pesquisaFornecedoresTexto={$.pesquisaFornecedoresTexto}
        pesquisaCategoriasTexto={$.pesquisaCategoriasTexto}
        pesquisar={(pesq) => $exec.pesquisar(pesq)}
        pesquisaFornecedores={$.pesquisaFornecedores}
        pesquisaCategorias={$.pesquisaCategorias}
        aoPesquisar={(texto) => $exec.aoPesquisar(texto)}
        ativarTabCategorias={$exec.ativarTabCategorias}
        ativarTabFornecedores={$exec.ativarTabFornecedores}
      />
      <PageBody
        obtendoLocalizacao={$.obtendoLocalizacao}
        localizacao={$.localizacao}
        definirLocalizacao={(local) => $exec.definirLocalizacao(local)}
        activeMenu={$.activeMenu}
        tabCategorias={$.tabCategorias}
        pesquisaCategoriasTexto={$.pesquisaCategoriasTexto}
        pesquisaFornecedoresTexto={$.pesquisaFornecedoresTexto}
      />
      <PageFooter
        localizacaoDefinida={localizacaoDefinida}
        localizacao={$.localizacao}
        clickLocalizacao={(renovar, questionar) => $exec.clickLocalizacao(isMountedRef, renovar, questionar)}
      />
    </Page>
  )
}
  
export default Home