import React, { useState } from 'react'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import BarButton from '../BarButton/BarButton'
import { Container } from '../Container/Container'
import Field from '../Field/Field'

interface IBarButtonPesquisarProps {
    click?: (pesquisar: boolean) => void
    pesquisar?: boolean
    pesquisaTexto?: string
    aoPesquisar?: (texto: string) => void
    maxWidth?: string
}

const BarButtonPesquisar: React.FC<IBarButtonPesquisarProps> = ({
    click,
    pesquisar,
    pesquisaTexto,
    aoPesquisar,
    maxWidth
}) => {
    const [autoFocus, setAutoFocus] = useState(false)

    const onClick = () => {
        setAutoFocus(true)
        if (click) click(!pesquisar)
    }

    if (pesquisar) {
        return (
            <Container>
                <Field label='' type='text' 
                    change={(value) => aoPesquisar ? aoPesquisar(value) : null} 
                    paddingContainer='0px' 
                    paddingInput='2px 5px' 
                    maxWidth={maxWidth || '60px'}
                    value={pesquisaTexto} 
                    autoFocus={autoFocus}
                />
                <BarButton click={onClick} paddingLeft='5px' paddingRight='5px' title='Voltar' iconLeft={faTimes} marginLeft='1px' noBorder />
            </Container>
        )    
    }
    return (
        <Container>
            <BarButton click={onClick} paddingLeft='5px' paddingRight='5px' title='Pesquisar' iconLeft={faSearch} noBorder />
        </Container>
    )
}

export default BarButtonPesquisar