import { useHistory } from 'react-router-dom'
import useHomeState from './useHomeState'
import useHomeService from './useHomeService'
import { Localizacao } from '../../../models/Localizacao'

const useHome = () => {

    const history = useHistory()
    const $ = useHomeState()
    const $service = useHomeService()

    const definirLocalizacaoObtida = (isMountedRef: React.MutableRefObject<boolean>, local?: Localizacao | null) => {
        if (!local) {
            $service.localizador.limparLocalizacao()
        }
        if (isMountedRef.current) {
            $.setLocalizacao(local)
            $.setObtendoLocalizacao(false)  
        }
    }
    
    const clickLocalizacao = (isMountedRef: React.MutableRefObject<boolean>, renovar: boolean, questionar: boolean) => {
        $service.localizador.obterApenasLocalizacao(renovar)
          .then(dados => {
            const dadosValidos = dados && dados.uf && dados.municipioIdentificador;
            if (dadosValidos && questionar) {
                $service.alert.question('', `Você está em <strong>${dados?.municipio} ${dados?.uf}</strong>?`)
                    .then(resp => {
                        if (resp.isConfirmed) {
                            definirLocalizacaoObtida(isMountedRef, dados)
                        } else {
                            definirLocalizacaoObtida(isMountedRef, null)
                        }
                    })
                    .catch(() => {
                        definirLocalizacaoObtida(isMountedRef, null)
                    })
            } else {
              definirLocalizacaoObtida(isMountedRef, dadosValidos ? dados : null)
            }
          })
          .catch(() => {
            definirLocalizacaoObtida(isMountedRef, null)
          })
    }
    
    const ativarTabFornecedores = () => {
        $.setActiveMenu('for')
        history.push('/home?tab=fornecedores')
    }
    
    const ativarTabCategorias = () => {
        $.setActiveMenu('cat')
        history.push('/home?tab=categorias')
    }
    
    const irParaEntrar = () => {
        history.push('entrar')
    }
    
    const irParaMenu = () => {
        history.push('menu')
    }
    
    const definirLocalizacao = (local: Localizacao) => {
        $.setLocalizacao(local)
        $service.localizador.salvarLocalizacaoSelecionada(local)
    }
    
    const pesquisar = (pesq: boolean) => {
        if ($.activeMenu === 'cat') {
            if (!pesq) $.setPesquisaCategoriasTexto('')
            $.setPesquisaCategorias(pesq)
        } else if ($.activeMenu === 'for') {
            if (!pesq) $.setPesquisaFornecedoresTexto('')
            $.setPesquisaFornecedores(pesq)
        }
    }
    
    const aoPesquisar = (texto: string) => {
        if ($.activeMenu === 'cat') {
            $.setPesquisaCategoriasTexto(texto)
        } else if ($.activeMenu === 'for') {
            $.setPesquisaFornecedoresTexto(texto)
        }
    }

    const $exec = {
        clickLocalizacao,
        definirLocalizacao,
        definirLocalizacaoObtida,
        ativarTabFornecedores,
        ativarTabCategorias,
        irParaEntrar,
        irParaMenu,
        pesquisar,
        aoPesquisar
    }

    return {
        $,
        $exec,
        $service
    }
}

export default useHome