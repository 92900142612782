import React from 'react'
import {Card} from '../Card/Card'
import {Container} from '../Container/Container'
import {StaticText} from '../StaticText/StaticText'
import Link from '../Link/Link'
import ImagemCategoria from '../ImagemCategoria/ImagemCategoria'

interface ICardCategoriaProps {
    opcaoIdentificador: string
    opcaoCategoria: string
    opcaoOpcoes: number
    opcaoImagem?: any
    municipioIdentificador: string
    estadoIdentificador: string
    fornecedorIdentificador?: string
    noBorder?: boolean
}

const CardCategoria: React.FC<ICardCategoriaProps> = ({
    opcaoIdentificador,
    opcaoImagem,
    opcaoCategoria,
    opcaoOpcoes,
    municipioIdentificador,
    estadoIdentificador,
    fornecedorIdentificador,
    noBorder
}) => {

    const href = fornecedorIdentificador
        ? `/${opcaoIdentificador}/de/${fornecedorIdentificador}/em/${municipioIdentificador}/${estadoIdentificador}`
        : `/${opcaoIdentificador}/em/${municipioIdentificador}/${estadoIdentificador}`
    const urlBase = process.env.REACT_APP_BASE_URL_IMAGE || ''
    const imagem = `${urlBase}cat_${opcaoIdentificador}.png`
    const textOpcoes = opcaoOpcoes > 1 ? `${opcaoOpcoes} opções disponíveis` : `Uma opção disponível`

    return (
        <Link href={href}>
            <Card clickable noBorder={noBorder}>
                <Container padding='10px'>
                    <ImagemCategoria imagem={opcaoImagem || imagem} alt={opcaoCategoria} size='50' />
                    <Container columnDirection={true} paddingLeft='8px'>
                        <StaticText textSize='1.2em' bold={true}>
                            {opcaoCategoria}
                        </StaticText>
                        <StaticText textSize='0.8em' textColor='var(--color-gray-2)'>
                            {textOpcoes}
                        </StaticText>
                    </Container>
                </Container>
            </Card>
        </Link>
    )
}
  
export default CardCategoria