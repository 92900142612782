import styled from 'styled-components'

interface IRoundBoxProps {
    size: string
    borderWidth?: string
    borderColor?: string
}

export const RoundBox = styled.div<IRoundBoxProps>`
    width: ${props => props.size};
    max-width: ${props => props.size};
    min-width: ${props => props.size};
    height: ${props => props.size};
    max-height: ${props => props.size};
    border-radius: ${props => props.size};
    border-width: ${props => props.borderColor ? props.borderWidth || '1px' : '0px'};
    border-style: ${props => props.borderColor ? 'solid' : ''};
    border-color: ${props => props.borderColor || 'transparent'};
    overflow: hidden;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`