import React from 'react'
import { Container } from '../Container/Container'
import Checkbox from '../Checkbox/Checkbox'
import { StaticText } from '../StaticText/StaticText'

export interface IOptions {
    value: any
    text: string
    selected: boolean
}

interface ICheckboxFieldProps {
  label: string
  options: IOptions[]
  click?: (options: IOptions[]) => void
}

const CheckboxField: React.FC<ICheckboxFieldProps> = ({
    label,
    options,
    click
}) => {
  const onClick = (optionValue: any) => {
    options = options.map(option => {
      if (option.value === optionValue) {
        option.selected = !option.selected
      }
      return option
    })

    if (click) {
        click(options)
    }
  }
  
  const renderOptions = () => {
      if (!options || options.length === 0) return null

      return options.map(option =>
        <Checkbox
            key={`check-${option.value}`}
            selected={option.selected}
            text={option.text} 
            click={() => onClick(option.value)} />
      )
  }

  return (
    <Container columnDirection padding='10px'>
        <StaticText bold marginBottom='3px'>{label}</StaticText>
        <Container columnDirection border='1px solid var(--color-gray-4)' padding='8px 10px' borderRadius='3px'>
            {renderOptions()}
        </Container>
    </Container>
  )
}
export default CheckboxField