import React from 'react'
import {Card} from '../../../components/Card/Card'
import { Container } from '../../../components/Container/Container'
import Field from '../../../components/Field/Field'
import CheckboxField, { IOptions } from '../../../components/CheckboxField/CheckboxField'

interface IEtapaPagamentoProps {
    pagamento: IOptions[]
    setPagamento: (value: IOptions[]) => void
    observacao: string
    setObservacao: (value: string) => void
    setAreaAberta: (value: boolean) => void
}

const EtapaPagamento: React.FC<IEtapaPagamentoProps> = ({
    pagamento,
    setPagamento,
    observacao,
    setObservacao,
    setAreaAberta
}) => {

    const checkboxFieldRender = pagamento && pagamento.length ? (
      <CheckboxField
        label='Como pretender pagar'
        options={pagamento}
        click={(options) => setPagamento(options)}
      />
    ): null

    return (
        <Card>
          <Container columnDirection>
            {checkboxFieldRender}
            <Field
              type='textarea'
              label='Deseja deixar alguma observação'
              value={observacao}
              focus={() => setAreaAberta(false)}
              blur={() => setAreaAberta(true)}
              change={(value) => setObservacao(value)}
            />
          </Container>
        </Card>
    )
}

export default EtapaPagamento