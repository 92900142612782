import React from 'react'
import CardTotalizador from '../../../components/CardTotalizador/CardTotalizador'
import { Pedido } from '../../../models/Pedido'

interface ITotalizadorProps {
    etapa: number
    ampliarTela: boolean
    pedido?: Pedido
}

const Totalizador: React.FC<ITotalizadorProps> = ({
    etapa,
    ampliarTela,
    pedido
}) => {

    if (etapa !== 2 || ampliarTela) return null

    const nroItens = pedido?.numeroItens || 0

    return (
        <CardTotalizador
          numeroItens={nroItens}
          numeroItensSemPreco={pedido?.numeroItensSemPreco || 0}
          subTotal={pedido?.subTotal || 0}
          taxaEntrega={pedido?.taxaEntrega || 0}
          total={pedido?.total || 0}
          taxa={''}
          taxaColuna={0}
        />
    )
}

export default Totalizador