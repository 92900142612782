import React from 'react'
import {PageContainer, PageCenter} from './Styles'

const Page: React.FC = ({
    children
}) => (
    <PageContainer>
        <PageCenter>
            {children}
        </PageCenter>
    </PageContainer>
)
  
export default Page