import React, { useState } from 'react'
import {Card} from '../../../components/Card/Card'
import Field from '../../../components/Field/Field'

export class FormData {
    nome: string = ''
    email: string = ''
    senha: string = ''
    confirmacao: string = ''
}

interface IFormCadastrarProps {
    change: (form: FormData) => void
}

const FormCadastrar: React.FC<IFormCadastrarProps> = ({
    change
}) => {

    const [formData, setFormData] = useState<FormData>({
        nome: '',
        confirmacao: '',
        email: '',
        senha: ''
    })

    const setFormValue = (fieldName: string, fieldValue: string) => {
        formData[fieldName as keyof FormData] = fieldValue
        setFormData(formData)
        change(formData)
    }
    
    return (
        <Card>
            <Field type='text' label='Nome' change={(value) => setFormValue('nome', value)} placeHolder='Informe seu nome aqui' />
            <Field type='email' label='E-mail' change={(value) => setFormValue('email', value)} placeHolder='Informe seu e-mail de contato aqui' />
            <Field type='password' label='Senha' change={(value) => setFormValue('senha', value)} information='Informe no mínimo 6 caracteres. Informe letras e números.' placeHolder='Informe uma nova senha aqui' />
            <Field type='password' label='Confirmação' change={(value) => setFormValue('confirmacao', value)} placeHolder='Confirme a nova senha aquui' />
        </Card>
    )
}

export default FormCadastrar