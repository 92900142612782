import styled from "styled-components"
import {isMobile} from 'react-device-detect'

interface IContainerCenterProps {
    top?: string
}

export const ContainerCenter = styled.div<IContainerCenterProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-left: ${isMobile ? 'calc(50% - 60px)' : 'calc(50% - 50px)'};
    width: 100px;
    position: absolute;
    margin-top: ${props => props.top || '0px'};
`