import React, { useState, useEffect } from 'react'
import latinize from 'latinize'
import Aguarde from '../../../components/Aguarde/Aguarde'
import CardFornecedor from '../../../components/CardFornecedor/CardFornecedor'
import { FornecedorPorLocalizacao } from '../../../models/FornecedorPorLocalizacao'
import {FornecedorService} from '../../../services/FornecedorService'
import Mensagem from '../../../components/Mensagem/Mensagem'

interface ITabFornecedoresProps {
  estado: string
  municipio: string
  filtro?: string
}

const TabFornecedores: React.FC<ITabFornecedoresProps> = ({
  estado,
  municipio,
  filtro
}) => {

  const [fornecedores, setFornecedores] = useState<FornecedorPorLocalizacao[]>([])
  const [carregando, setCarregando] = useState(true)

  useEffect(() => {
    const fornecedorService = new FornecedorService()
    fornecedorService
      .obterApenasFornecedoresPorLocalizacao(estado, municipio)
      .then(dados => {
        setCarregando(false)
        setFornecedores(dados || [])
      })
  }, [estado, municipio])

  const render = () => {
    const latfiltro = latinize(filtro || '').toLocaleLowerCase()
    const lista = filtro ? fornecedores.filter(f => latinize(f.nome).toLocaleLowerCase().indexOf(latfiltro) >= 0) : fornecedores
    if (!lista.length) {
      return <Mensagem texto='Nenhum fornecedor encontrado!' />
    }
    return lista.map((fornecedor) =>
      <CardFornecedor
        nome={fornecedor.nome}
        identificador={fornecedor.identificador}
        municipioIdentificador={fornecedor.municipioIdentificador}
        estadoIdentificador={fornecedor.estadoIdentificador}
        logo={fornecedor.logo}
        funcionamento={fornecedor.funcionamento}
        key={`fornecedor-${fornecedor.identificador}`} 
        observacao={fornecedor.observacao}
        clickable 
      />
    )
  }

  if (carregando) {
    return <Aguarde texto='Obtendo fornecedores...' />
  }

  return (
    <>
    {render()}
    </>
  )
}
  
export default TabFornecedores