import { RespostaBase } from '../models/RespostaBase'
import { Localizacao } from '../models/Localizacao'
import HttpService from '../services/HttpService'
import StorageService from './StorageService'

export class LocalizacaoService {

    private http = new HttpService()
    private storage = new StorageService()

    obterGeoLocalizacao = () => {
        const promise = new Promise<Localizacao | null | undefined>((resolve, reject) => {
            const geo = 'geolocation' in navigator ? navigator.geolocation : null
            if (!geo) resolve(null)
            geo?.getCurrentPosition(
                positionSuccess => {
                    const lat = positionSuccess?.coords?.latitude || false
                    const lng = positionSuccess?.coords?.longitude || false

                    if (lat === false || lng === false) {
                        this.storage.armazenarGeoLocalizacao(null);
                        resolve(null)
                        return
                    }

                    this.storage.armazenarGeoLocalizacao(`${lat}:${lng}`);

                    this.http.get<RespostaBase<Localizacao>>(`localizacao?lat=${lat}&lng=${lng}`)
                        .then(responseSuccess => {
                            if (responseSuccess.data.sucesso) {
                                resolve(responseSuccess.data.resultado)
                            } else {
                                resolve(null)
                            }
                        })
                        .catch(responseError => {
                            resolve(null)
                        })
                },
                positionError => {
                    this.storage.armazenarGeoLocalizacao(null);
                    resolve(null)
                })
        })
        return promise
    }

    obterApenasLocalizacao = (renovar: boolean) => {
        if (renovar) {
            this.storage.removerLocalizacao()
        }
        const promise: Promise<Localizacao | null> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterLocalizacao()
            if (dados) {
                resolve(dados)
                return
            }

            this.obterGeoLocalizacao().then(response => {
                if (response) {
                    this.storage.armazenarLocalizacao(response)
                    resolve(response)
                } else {
                    resolve(null)
                }
            }).catch(() => {
                resolve(null)
            })
        })
        return promise
    }

    salvarLocalizacaoSelecionada = (localizacao: Localizacao) => {
        this.storage.removerLocalizacao()
        this.storage.armazenarLocalizacao(localizacao)
    }

    limparLocalizacao = () => {
        this.storage.removerLocalizacao()
    }
}