import { EstrategiaPreco } from '../enums/EstrategiaPreco.enum'
import {ItemCardapio} from './ItemCardapio'
import {PrecoItemCardapio} from './PrecoItemCardapio'

export class SaborPedido {
    saborPedidoId: number = 0
    pedidoItemId: number = 0
    preco: number = 0
    dataCadastro: Date | null = null
    itemCardapio: ItemCardapio | null = null
    itemCardapioId: number = 0
    itemCardapioNome: string = ''
    itemCardapioDetalhes: string = ''
    precoItemCardapio: PrecoItemCardapio | null = null
    precoItemCardapioId: number = 0
    precoItemCardapioNome: string = ''
    precoItemCardapioEstrategiaPreco: EstrategiaPreco = EstrategiaPreco.PrecoDefinido
    precoItemCardapioAgrupadorMultisabores: string = ''
    indisponivel: boolean = false
}