import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Button} from './styles'
import {StaticText} from '../StaticText/StaticText'
import Spinner from '../Spinner/Spinner'

interface IPanelButtonProps {
    text?: string
    textColor?: string
    noBold?: boolean
    icon?: any
    iconSize?: any
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
    click?: () => void
    loading?: boolean
}

const PanelButton: React.FC<IPanelButtonProps> = ({
    text, textColor,
    noBold,
    icon,iconSize,
    marginTop, marginBottom, marginLeft, marginRight,
    click,
    loading
}) => {

    const renderIcon = () => {
        if (loading) {
            return (
                <Spinner size={iconSize || '1x'} color='var(--color-blue-4)' title={text} style={{marginRight:text?'5px':'0px'}} />
            )    
        }

        if (!icon) return null

        return (
            <FontAwesomeIcon icon={icon} size={iconSize || '1x'} color='var(--color-blue-3)' title={text} style={{marginRight:text?'5px':'0px'}}/>
        )
    }

    const onClick = () => {
        if (loading || !click) return

        click()
    }

    const color = loading ? 'var(--color-blue-4)' : textColor

    return (
        <Button onClick={onClick} marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight}>
            {renderIcon()}
            <StaticText textAlign='center' textColor={color || 'var(--color-blue-3)'} bold={noBold ? false : true}>{text}</StaticText>
        </Button>
    )
}
  
export default PanelButton