import React from 'react'
import { Container } from '../../Container/Container'
import {StaticText} from '../../StaticText/StaticText'
import CardPreco from '../../CardPreco/CardPreco'
import Detalhes from './Detalhes'
import { PrecoItemPorFornecedor } from '../../../models/PrecoItemPorFornecedor'
import { PedidoAdicional } from '../../../models/PedidoAdicional'
import { PedidoItem } from '../../../models/PedidoItem'

interface ICardAdicionalProps {
    nome: string
    detalhes?: string
    precos: PrecoItemPorFornecedor[]
    precosAdicionaisPedido?: PedidoAdicional[]
    fornecedorId: number
    clickAumentar?: (precoId: number) => void
    clickDiminuir?: (precoId: number) => void
}

const CardAdicional: React.FC<ICardAdicionalProps> = ({
    nome,
    detalhes,
    precos,
    precosAdicionaisPedido,
    fornecedorId,
    clickAumentar,
    clickDiminuir
}) => {

    const renderPrecos = () => {
        return precos.map(preco => {
            const pedidoItem = new PedidoItem()
            pedidoItem.quantidade = precosAdicionaisPedido?.find(pad => pad.precoItemCardapioId === preco.id)?.quantidade || 0
            return (
                <CardPreco
                    key={`preco-adicional-${preco.id}`}
                    preco={preco}
                    fornecedorId={fornecedorId}
                    item={pedidoItem}
                    aguardar={false}
                    clickAumentar={() => clickAumentar ? clickAumentar(preco.id) : null}
                    clickDiminuir={() => clickDiminuir ? clickDiminuir(preco.id) : null}
                    produtoMultisabores={false}
                    categoriaProduto=''
                />
            )
        })
    }

    return (
        <Container border='1px solid var(--color-gray-4)' padding='10px' borderRadius='5px' columnDirection marginBottom='5px'>
            <Container columnDirection marginBottom='6px'>
                <StaticText bold>{nome}</StaticText>
                <Detalhes texto={detalhes} />
            </Container>
            {renderPrecos()}
        </Container>
    )
}
  
export default CardAdicional