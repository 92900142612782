import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import {Container, Texto} from './styles'

interface IMensagemProps {
    texto: string
}

const Mensagem: React.FC<IMensagemProps> = ({
    texto
}) => {

    return (
        <Container>
            <FontAwesomeIcon icon={faExclamationTriangle} size='lg' color='var(--color-gray-1)'/>
            <Texto>{texto}</Texto>
        </Container>
    )
}

export default Mensagem