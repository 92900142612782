import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Container } from '../Container/Container'
import { StaticText } from '../StaticText/StaticText'
import {Input,TextArea,InputSearch} from './styles'
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'

interface IFieldProps {
  value?: any
  type: string
  label: string
  rows?: number
  placeHolder?: string
  focus?: () => void
  blur?: () => void
  change?: (value: any) => void
  information?: string
  paddingContainer?: string
  paddingInput?: string
  maxWidth?: string
  autoFocus?: boolean
  minWidth?: string
  marginRight?: string
  click?: () => void
  rowDirection?: boolean
  minWidthInput?: string
  flexSizeInput?: string
  center?: boolean
}

const Field: React.FC<IFieldProps> = ({
    type,
    label,
    rows,
    placeHolder,
    value,
    focus,
    blur,
    change,
    information,
    paddingContainer,
    paddingInput,
    maxWidth,
    autoFocus,
    minWidth,
    marginRight,
    click,
    rowDirection,
    minWidthInput,
    flexSizeInput,
    center
}) => {

  const labelRender = label ? <StaticText bold marginBottom='3px'>{label}</StaticText> : null
  const informationRender = information ? <StaticText marginTop='3px' textColor='#bbb' textSize='0.8em'>{information}</StaticText> : null

  if (type === 'statictext') {
    return (
      <Container columnDirection padding={paddingContainer || '10px'} maxWidth={maxWidth} minWidth={minWidth} marginRight={marginRight}>
        {labelRender}
        <StaticText border='1px solid var(--color-gray-4)' borderRadius='3px' minHeight='40px' padding='8px 10px' minWidth={minWidth}>
          {value}
        </StaticText>
      </Container>
    )
  }

  if (type === 'select') {
    return (
      <Container columnDirection padding={paddingContainer || '10px'} maxWidth={maxWidth} minWidth={minWidth} marginRight={marginRight}>
        {labelRender}
        <Container center clickable justifyContent='center' 
          border='1px solid var(--color-gray-4)' borderRadius='3px' padding='8px 10px' minHeight={'40px'} maxHeight={'40px'}
          onClick={click}
        >
          <StaticText flex='1'>{value}</StaticText>
          <Container justifyContent='flex-end'>
            <FontAwesomeIcon icon={faChevronDown} size='1x' color='var(--color-gray-1)'/>
          </Container>
        </Container>
      </Container>
    )
  }

  if (type === 'textarea') {
    return (
      <Container columnDirection padding={paddingContainer || '10px'} maxWidth={maxWidth}>
        {labelRender}
        <TextArea
          placeholder={placeHolder}
          rows={rows || 2}
          value={value}
          onFocus={focus}
          onBlur={blur}
          onChange={(event) => change ? change(event.target.value) : null}
          autoFocus={autoFocus}
        />
      </Container>
    )
  }

  if (type === 'search') {
    return (
      <Container columnDirection padding={paddingContainer || '10px'} border='2px solid var(--color-gray-4)' borderRadius='4px'>
        <Container columnDirection={!rowDirection} center={center} minWidth={minWidth} maxWidth={maxWidth}>
          {labelRender}
          <InputSearch
            type={'text'}
            placeholder={placeHolder}
            value={value}
            onFocus={focus}
            onBlur={blur}
            onChange={(event) => change ? change(event.target.value) : null}
            padding={paddingInput}
            autoFocus={autoFocus}
            minWidth={minWidthInput}
            flexSize={flexSizeInput}
          />
          <Container justifyContent='flex-end' flexSize='0' paddingLeft='10px' paddingRight='10px' minWidth='36px'>
            <FontAwesomeIcon icon={faSearch} size='1x' color='var(--color-gray-2)'/>
          </Container>
        </Container>
        {informationRender}
      </Container>
    )
  }

  return (
    <Container columnDirection={!rowDirection} center={center} padding={paddingContainer || '10px'} minWidth={minWidth} maxWidth={maxWidth}>
      {labelRender}
      <Input
        type={type}
        placeholder={placeHolder}
        value={value}
        onFocus={focus}
        onBlur={blur}
        onChange={(event) => change ? change(event.target.value) : null}
        padding={paddingInput}
        autoFocus={autoFocus}
        minWidth={minWidthInput}
        flexSize={flexSizeInput}
      />
      {informationRender}
    </Container>
  )
}
export default Field