import React, { useState } from 'react'
import Modal from '../../Modal/Modal'
import CardOpcionalGrupo from './CardOpcionalGrupo'
import {OpcaoItemPorFornecedor} from '../../../models/OpcaoItemPorFornecedor'
import { AlertService } from '../../../services/AlertService'

interface IModalOpcionaisGrupoProps {
    visible: boolean
    close: (opcionaisSelecionados: OpcaoItemPorFornecedor[]) => void
    grupoId?: number
    grupoNome?: string
    opcionaisGrupo: OpcaoItemPorFornecedor[]
    opcionaisPedido: OpcaoItemPorFornecedor[]
    escolher: boolean
    quantidadeMaxima: number
}

const ModalOpcionaisGrupo: React.FC<IModalOpcionaisGrupoProps> = ({
    visible,
    close,
    grupoId,
    grupoNome,
    opcionaisGrupo,
    opcionaisPedido,
    escolher,
    quantidadeMaxima
}) => {

    const [opcionaisSelecionados, setOpcionaisSelecionados] = useState<OpcaoItemPorFornecedor[]>(opcionaisPedido || []);

    const aoFechar = () => {
        close(opcionaisSelecionados || [])
    }

    const aoEscolher = (opcional: OpcaoItemPorFornecedor) => {
        const opcionalPedido = opcionaisSelecionados.find(s => s.id === opcional.id)
        if (!opcionalPedido) {
            const novaQuantidade = opcionaisSelecionados.filter(o => o.grupoId === grupoId)?.length + 1
            if (quantidadeMaxima > 0 && novaQuantidade > quantidadeMaxima) {
                const alert = new AlertService();
                if (quantidadeMaxima === 1) {
                    alert.info('Atenção!', `Escolha no máximo um opcional.`)
                } else {
                    alert.info('Atenção!', `Escolha no máximo ${quantidadeMaxima} opcionais.`)
                }
                return
            }

            const novaOpcao = {...opcional}
            const novosOpcionaisSelecionados = [...opcionaisSelecionados]
            novosOpcionaisSelecionados.push(novaOpcao)
            setOpcionaisSelecionados(novosOpcionaisSelecionados)
            return
        }

        const novosOpcionaisSelecionados = opcionaisSelecionados.filter(s => s.id !== opcional.id)
        setOpcionaisSelecionados(novosOpcionaisSelecionados)
    }

    const renderBody = () => {
        if (!opcionaisGrupo) return null

        return opcionaisGrupo
            .map(opcional => {
                const opcionalPedido = opcionaisSelecionados.filter(s => s.id === opcional.id).length > 0
                return (
                    <CardOpcionalGrupo 
                        key={`opcional-grupo-${opcional.id}`}
                        nome={opcional.nome}
                        aoEscolher={() => aoEscolher(opcional)}
                        selecionado={opcionalPedido}
                        escolher={escolher}
                    />
                )
            })
    }

    return (
        <Modal
            visible={visible}
            title={`${grupoNome || 'Opcionais'}`}
            onClose={aoFechar}
        >
            {renderBody()}
        </Modal>
    )
}
  
export default ModalOpcionaisGrupo