import {ToastService} from '../../../services/ToastService'
import {ClienteService} from '../../../services/ClienteService'
import {FormData} from '../components/FormCadastrar'
import { NovoCliente } from '../../../models/NovoCliente'
import StorageService from '../../../services/StorageService'

class CadastrarService {

  public formData?: FormData | null = null

  constructor(
      private toaster: ToastService,
      private clienteService: ClienteService,
      private storage: StorageService
  ) {
  }

  public setFormData = (formData: FormData | null) => {
    this.formData = formData
  }

  public salvar = (
      onInit: () => void,
      onFinally: () => void,
      onSuccess: () => void
  ) => {
      if (!this.formData?.nome) {
          this.toaster.info('Informe seu nome!')
          return
      }
  
      if (!this.formData?.email) {
          this.toaster.info('Informe seu e-mail!')
          return
      }
  
      if (!this.formData?.senha) {
          this.toaster.info('Informe a senha!')
          return
      }
  
      if (!this.formData?.confirmacao || this.formData.senha !== this.formData.confirmacao) {
          this.toaster.info('Confirme a senha!')
          return
      }
  
      onInit()
  
      const novoCliente = new NovoCliente()
      novoCliente.nome = this.formData.nome
      novoCliente.email = this.formData.email
      novoCliente.senha = this.formData.senha
      novoCliente.confirmacao = this.formData.confirmacao
  
      this.clienteService.salvarNovo(novoCliente)
        .then(response => {
          if (response.data.sucesso && response.data.resultado) {
            const clienteId = response.data.resultado
            if (clienteId) {
              this.storage.armazenarSessaoCliente(clienteId, 0)
              onSuccess()
              return
            }
          }
          if (response?.data?.mensagens?.length) {
              this.toaster.info(response.data.mensagens[0])
          }
        })
        .finally(() => {
          onFinally()
        })
  }
}

export default CadastrarService