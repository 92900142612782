import { ItemCardapio } from "./ItemCardapio"
import { OpcaoItemCardapio } from "./OpcaoItemCardapio"

export class PedidoItemOpcao {
    pedidoItemOpcaoId: number = 0
    pedidoItemId: number = 0
    dataCadastro?: Date | null = null
    opcaoItemCardapio?: OpcaoItemCardapio | null = null
    opcaoItemCardapioId: number = 0
    opcaoItemCardapioDetalhes: string = ''
    itemCardapioOpcao?: ItemCardapio | null = null
    itemCardapioIdOpcao: number = 0
    itemCardapioNomeOpcao: string = ''
    quantidade: number = 0
    indisponivel: boolean = false
    grupoOpcoesId?: number
}