import styled from 'styled-components'

interface IPanelButtonsProps {
    flexColumn?: boolean
    padding?: string
    alignContent?: string
    borderTop?: boolean
    borderBottom?: boolean
    marginTop?: string
    marginBottom?: string
}

export const PanelButtons = styled.div<IPanelButtonsProps>`
    display: flex;
    flex: 1;
    background-color: transparent;
    flex-direction: ${props => props.flexColumn ? 'column' : 'row'};
    align-items: ${props => props.alignContent || 'center'};
    align-content: ${props => props.alignContent || 'center'};
    justify-content: stretch;
    padding: ${props => props.padding || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    border-top: ${props => props.borderTop ? '1px solid var(--color-body)' : 'none'};
    border-bottom: ${props => props.borderBottom ? '1px solid var(--color-body)' : 'none'};
`