import React from 'react'
import Fixed from './Fixed'

const FixedTop: React.FC = ({
    children
}) => (
    <Fixed position='top'>
        {children}
    </Fixed>
)

export default FixedTop