import React from 'react'
import { Localizacao } from '../../../models/Localizacao'
import FixedBottom from '../../../components/Fixed/FixedBottom'
import LocalizacaoAtual from '../../../components/LocalizacaoAtual/LocalizacaoAtual'

interface IPageFooterProps {
    localizacaoDefinida: boolean
    localizacao?: Localizacao | null
    clickLocalizacao: (renovar: boolean, questionar: boolean) => void
}

const PageFooter: React.FC<IPageFooterProps> = ({
    localizacaoDefinida,
    localizacao,
    clickLocalizacao
}) => {

    if (!localizacaoDefinida) return null

    return (
        <FixedBottom>
            <LocalizacaoAtual
                municipio={localizacao?.municipio || ''}
                estado={localizacao?.uf || ''}
                click={() => clickLocalizacao(true, true)} 
            />
        </FixedBottom>
    )
}

export default PageFooter