import { useHistory } from 'react-router-dom'
import { Pedido } from '../../../models/Pedido'
import { TipoEntrega } from '../../../enums/TipoEntrega.enum'
import { PedidoPagamento } from '../../../models/PedidoPagamento'
import { FechamentoPedido } from '../../../models/FechamentoPedido'
import { LinkService } from '../../../services/LinkService'
import { PedidoFechadoParaResumo } from '../../../models/PedidoFechadoParaResumo'
import useFechaPedidoState from './useFechaPedidoState'
import useFechaPedidoService from './useFechaPedidoService'
import { MutableRefObject } from 'react'
import { Area } from '../../../enums/Area.enum'

const useFechaPedido = () => {

    const history = useHistory()
    const $ = useFechaPedidoState()
    const $service = useFechaPedidoService()

    const voltarParaCarrinho = () => {
        history.push(`/carrinho/${$.fornecedor}/em/${$.municipio}/${$.estado}`)
    }

    const obterPedidoEmAberto = (isMountedRef: MutableRefObject<boolean>, fornecedorId: number) => {
        const sessao = $service.storage.obterSessaoCliente()
        if (sessao?.clienteId || sessao?.sessaoClienteId) {
            $service.pedido.obterPedidoAberto(fornecedorId, sessao?.clienteId, sessao?.sessaoClienteId)
            .then(response => {
                const pedidoAberto = response.data.resultado
                if (!pedidoAberto || !pedidoAberto.itens.length) {
                    voltarParaCarrinho()
                    return
                }
                aplicarNovoPedido(isMountedRef, pedidoAberto)
            })
            .catch(response => {
                voltarParaCarrinho()
            })
        } else {
            voltarParaCarrinho()
        }
    }

    const aplicarEnderecoPedido = (isMountedRef: MutableRefObject<boolean>, pedidoAberto?: Pedido) => {
        if (isMountedRef.current) {
            $.setEnderecoLogradouro(pedidoAberto?.enderecoEntregaLogradouro || '')
            $.setEnderecoEstado(pedidoAberto?.enderecoEntregaUF || $.estado.toUpperCase() || '')
            $.setEnderecoMunicipio(pedidoAberto?.enderecoEntregaMunicipio || '')
            $.setEnderecoArea(pedidoAberto?.enderecoEntregaArea || Area.NaoInformado)
        }
    }

    const aplicarNovoPedido = (isMountedRef: MutableRefObject<boolean>, pedidoAberto?: Pedido) => {
        if (isMountedRef.current) {
            $.setPedido(pedidoAberto)
            $.setObservacao(pedidoAberto?.observacao || '')
            const tipoEntregaPedido = pedidoAberto?.tipoEntrega || TipoEntrega.DeliveryOuRetirarNoLocal
            const tipoEntregaFornecedor = $.dadosFornecedor?.tipoEntrega || TipoEntrega.DeliveryOuRetirarNoLocal
            const entrega = tipoEntregaPedido > 0 ? tipoEntregaPedido : tipoEntregaFornecedor
            $.setTipoEntrega(entrega)
            $.setNomeCliente(pedidoAberto?.nomeCliente || '')
            mapearFormasPagamento(isMountedRef, pedidoAberto?.pagamentos)
            aplicarEnderecoPedido(isMountedRef, pedidoAberto)
            if ($.etapa === 0 && (!pedidoAberto?.enderecoEntregaUF || !pedidoAberto?.enderecoEntregaMunicipio)) {
                aplicarTipoEntrega(isMountedRef, entrega)
            }
        }
    }

    const mapearFormasPagamento = (isMountedRef: MutableRefObject<boolean>, pagamentos?: PedidoPagamento[]) => {
        const novoPagamento = $.dadosFornecedor?.formasPagamentos?.map(fp => {
            let selected = false
            if (pagamentos && pagamentos.length > 0) {
            selected = pagamentos.some(p => p.formaPagamentoFornecedorId === fp.id)
            }
            return { value: fp.id, text: fp.nome, selected: selected }
        }) || []
        if (isMountedRef.current) {
            $.setPagamento(novoPagamento)
        }
    }

    const obterFechamentoPedido = (enviarTaxa: boolean) => {
        const fechamentoPedido = new FechamentoPedido()
        fechamentoPedido.pedidoId = $.pedido?.pedidoId || 0
        fechamentoPedido.tipoEntrega = $.tipoEntrega
        fechamentoPedido.enderecoLogradouro = $.enderecoLogradouro
        fechamentoPedido.enderecoEstado = $.enderecoEstado
        fechamentoPedido.enderecoMunicipio = $.enderecoMunicipio
        fechamentoPedido.pagamento = $.pagamento.filter(pg => pg.selected).map(pg => pg.value)
        fechamentoPedido.observacao = $.observacao
        fechamentoPedido.identificacao = $.nomeCliente
        fechamentoPedido.taxaEntrega = enviarTaxa && $.tipoEntrega === TipoEntrega.Delivery ? $.taxaEntrega : 0
        fechamentoPedido.area = $.enderecoArea
        return fechamentoPedido
    }

    const salvarFinalizacao = (isMountedRef: MutableRefObject<boolean>, enviarTaxa: boolean) => {
        const fechamentoPedido = obterFechamentoPedido(enviarTaxa)
        $service.pedido.salvarFechamento(fechamentoPedido)
            .then(response => {
                const novoPedido = response?.data?.resultado?.valor
                if (!novoPedido) {
                    return
                }
                aplicarNovoPedido(isMountedRef, novoPedido)
            })
    }

    const fechar = () => {
        newTeloEvent('fecha-pedido', 'inicio');
        const fechamentoPedido = obterFechamentoPedido(true)
        $service.pedido.fechar(fechamentoPedido)
            .then(response => {
                if (!response.data.sucesso) {
                    newTeloEvent('fecha-pedido', 'falha');
                    return
                }
                newTeloEvent('fecha-pedido', 'sucesso');

                const whatsapp = $.dadosFornecedor?.contatos?.find(x => x.batePapo) || null
                if (whatsapp) {
                    const linker = new LinkService()
                    linker.openWhatsApp(whatsapp.contato, response.data.resultado?.valor?.mensagem)
                }

                const pedidoFechadoParaResumo = new PedidoFechadoParaResumo()
                pedidoFechadoParaResumo.fornecedor = $.dadosFornecedor
                pedidoFechadoParaResumo.pedido = response.data.resultado?.valor?.pedido || $.pedido
                $service.storage.armazenarPedidoFechadoParaResumo(pedidoFechadoParaResumo)

                history.push(`/resumo`)
            })
    }

    const voltar = (isMountedRef: MutableRefObject<boolean>) => {
        newTeloEvent('fecha-pedido', 'voltar');

        salvarFinalizacao(isMountedRef, $.etapa > 0)

        if ($.etapa === 0) {
            voltarParaCarrinho()
        }

        if ($.etapa > 0) {
            if (isMountedRef.current) $.setEtapa($.etapa-1)
        }
    }

    const finalizar = (isMountedRef: MutableRefObject<boolean>) => {
        if ($.etapa === 0) {
            newTeloEvent('fecha-pedido', 'entrega > identificação');
            salvarFinalizacao(isMountedRef, true)
            if (isMountedRef.current) $.setEtapa(1)
            return 
        }

        if ($.etapa === 1) {
            newTeloEvent('fecha-pedido', 'identificação > pagamento');
            salvarFinalizacao(isMountedRef, true)
            if (isMountedRef.current) $.setEtapa(2)
            return 
        }

        if ($.etapa === 2) {
            fechar()
        }
    }

    const aplicarAreaAberta = (isMountedRef: MutableRefObject<boolean>, areaAberta: boolean) => {
        if (isMountedRef.current) {
            $.setAreaAberta(areaAberta)
        }
    }

    const aplicarTipoEntrega = (isMountedRef: MutableRefObject<boolean>, tipo: TipoEntrega | undefined) => {
        tipo = tipo || TipoEntrega.DeliveryOuRetirarNoLocal

        if (tipo === TipoEntrega.Delivery && (!$.enderecoEstado || !$.enderecoMunicipio)) {
            $service.localizador.obterGeoLocalizacao()
                .then(dados => {
                    if (isMountedRef.current) {
                        $.setEnderecoLogradouro($.enderecoLogradouro || dados?.logradouroComNumero || '')
                        $.setEnderecoEstado($.enderecoEstado || dados?.uf || '')
                        $.setEnderecoMunicipio($.enderecoMunicipio || dados?.municipio || '')
                    }
                })
                .catch(reason => {
                    if (isMountedRef.current) {
                        $.setEnderecoEstado($.enderecoEstado || $.estado || '')
                    }
                })
        }

        if (isMountedRef.current) {
            $.setTipoEntrega(tipo)
        }
    }

    const newTeloEvent = (name: string, details: string) => {
        try {
            (window as any).telo.newEvent(name, details);
        }
        catch
        {
        }
    }

    const $exec = {
        voltarParaCarrinho,
        obterFechamentoPedido,
        obterPedidoEmAberto,
        salvarFinalizacao,
        fechar,
        voltar,
        finalizar,
        aplicarTipoEntrega,
        aplicarAreaAberta,
        aplicarEnderecoPedido
    }

    return {
        $,
        $exec,
        $service
    }
}

export default useFechaPedido