import styled from 'styled-components'

export const TabMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: var(--color-gray-3);
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    flex: 1;
`