import { Municipio } from '../models/Municipio'
import {RespostaBase} from '../models/RespostaBase'
import HttpService from './HttpService'
import StorageService from './StorageService'

export class MunicipioService {

    private http: HttpService = new HttpService()
    private storage: StorageService = new StorageService()

    obterMunicipiosPorUf = (uf: string) => {
        return this.http.get<RespostaBase<Municipio[]>>(
            `MunicipiosPorUf?uf=${uf}`)
    }

    obterApenasMunicipiosPorUf = (uf: string) => {
        const promise: Promise<Municipio[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterMunicipiosPorUf(uf)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterMunicipiosPorUf(uf).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarMunicipiosPorUf(uf, resultado)
                    resolve(resultado)
                }
            })
        })
        return promise
    }

}