import React from 'react'
import { Container } from '../Container/Container'
import { StaticText } from '../StaticText/StaticText'
import {SelectedButton,Button} from './styles'

interface IRadioButtonProps {
  value: any
  selectedValue?: any
  text?: string
  click: (value: any) => void
}

const RadioButton: React.FC<IRadioButtonProps> = ({
    value,
    selectedValue,
    text,
    click
}) => {

  const button = selectedValue === value ? <Button><SelectedButton/></Button> : <Button />

  return (
      <Container center clickable onClick={() => click(value)}>
          {button}
          <StaticText marginLeft='4px'>{text}</StaticText>
      </Container>
  )
}
export default RadioButton