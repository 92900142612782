import React from 'react'
import NavMenu from '../NavMenu/NavMenu'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import SideBarCenter from '../SideBarCenter/SideBarCenter'
import SideBarRight from '../SideBarRight/SideBarRight'
import {StaticText} from '../StaticText/StaticText'
import BarButtonVoltar from '../BarButtonVoltar/BarButtonVoltar'

interface INavMenuLCRProps {
    clickVoltar: () => void
    textCenter?: string
}

const NavMenuLCRCustom: React.FC<INavMenuLCRProps> = ({
    children,
    clickVoltar,
    textCenter
}) => (
    <NavMenu>
        <SideBarLeft><BarButtonVoltar click={clickVoltar}/></SideBarLeft>
        <SideBarCenter><StaticText textColor='var(--color-blue-platelo)' bold>{textCenter}</StaticText></SideBarCenter>
        <SideBarRight>{children}</SideBarRight>
    </NavMenu>
)

export default NavMenuLCRCustom