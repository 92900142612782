import styled from 'styled-components'

export const Container = styled.div`
    padding: 50px;
    text-align: center;
`

export const Texto = styled.div`
    text-align: center;
    color: var(--color-gray-2);
    font-size: 0.9em;
    margin-top: 5px;
`