import React from 'react'
import FixedTop from '../../../components/Fixed/FixedTop'
import NavMenuLCR from '../../../components/NavMenuLCR/NavMenuLCR'
import { Pedido } from '../../../models/Pedido'
import Totalizador from './Totalizador'

const etapas = [ 'Entrega', 'Identificação', 'Pagamento' ]

interface IPageHeaderProps {
    etapa: number
    ampliarTela: boolean
    pedido?: Pedido
    voltar: () => void
    finalizar: () => void
}

const PageHeader: React.FC<IPageHeaderProps> = ({
    etapa,
    ampliarTela,
    pedido,
    voltar,
    finalizar
}) => {

    return (
        <FixedTop>
            <NavMenuLCR clickVoltar={voltar} clickAvancar={finalizar} textCenter={etapas[etapa]} textRight='Pedir' />
            <Totalizador etapa={etapa} ampliarTela={ampliarTela} pedido={pedido} />
        </FixedTop>
    )
}

export default PageHeader