import React from 'react'
import { isMobile } from 'react-device-detect'
import { DadosSobreFornecedor } from "../../../models/DadosSobreFornecedor"
import { IOptions } from '../../../components/CheckboxField/CheckboxField'
import { TipoEntrega } from '../../../enums/TipoEntrega.enum'
import EtapaEntrega from './EtapaEntrega'
import EtapaIdentificacao from './EtapaIdentificacao'
import EtapaPagamento from './EtapaPagamento'
import { Body } from '../../../components/Body/Body'
import { Area } from '../../../enums/Area.enum'

interface IPageBodyProps {
    etapa: number
    ampliarTela: boolean
    tipoEntrega: TipoEntrega
    enderecoLogradouro: string
    enderecoEstado: string
    enderecoMunicipio: string
    enderecoArea: Area
    nomeCliente: string
    pagamento: IOptions[]
    observacao: string
    dadosFornecedor?: DadosSobreFornecedor
    setAreaAberta: (value: boolean) => void
    aplicarTipoEntrega: (value: TipoEntrega) => void
    setEnderecoLogradouro: (value: string) => void
    setEnderecoMunicipio: (value: string) => void
    setEnderecoArea: (value: Area) => void
    setNomeCliente: (value: string) => void
    setPagamento: (value: IOptions[]) => void
    setObservacao: (value: string) => void
}

const PageBody: React.FC<IPageBodyProps> = ({
    etapa,
    ampliarTela,
    tipoEntrega,
    enderecoLogradouro,
    enderecoEstado,
    enderecoMunicipio,
    enderecoArea,
    nomeCliente,
    pagamento,
    observacao,
    dadosFornecedor,
    setAreaAberta,
    aplicarTipoEntrega,
    setEnderecoLogradouro,
    setEnderecoMunicipio,
    setNomeCliente,
    setPagamento,
    setObservacao,
    setEnderecoArea
}) => {

    if (etapa < 0 || etapa > 2) return null

    let bodyTop = isMobile ? 45 : 40
    if (etapa === 2 && !ampliarTela) {
        bodyTop += 70
    }

    let etapaRender = null

    if (etapa === 0) {
        etapaRender = (
            <EtapaEntrega
                dadosFornecedor={dadosFornecedor}
                tipoEntrega={tipoEntrega}
                aplicarTipoEntrega={(value) => aplicarTipoEntrega(value)}
                setAreaAberta={(value) => setAreaAberta(value)}
                setEnderecoLogradouro={(value) => setEnderecoLogradouro(value)}
                setEnderecoMunicipio={(value) => setEnderecoMunicipio(value)}
                setEnderecoArea={(value) => setEnderecoArea(value)}
                enderecoLogradouro={enderecoLogradouro}
                enderecoEstado={enderecoEstado}
                enderecoMunicipio={enderecoMunicipio}
                enderecoArea={enderecoArea}
            />
        )
    }

    if (etapa === 1) {
        etapaRender = (
            <EtapaIdentificacao
                nomeCliente={nomeCliente}
                setNomeCliente={(value) => setNomeCliente(value)}
            />
        )
    }

    if (etapa === 2) {
        etapaRender = (
            <EtapaPagamento
                pagamento={pagamento}
                setPagamento={(value) => setPagamento(value)}
                observacao={observacao}
                setObservacao={(value) => setObservacao(value)}
                setAreaAberta={(value) => setAreaAberta(value)}
            />
        )
    }

    return (
        <Body top={`${bodyTop}px`} bottom='100px'>
            {etapaRender}
        </Body>
    )
}

export default PageBody