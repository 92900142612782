import styled from "styled-components"

export const Image = styled.img`
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 0px 2px #eee;
        border-radius: 30px;
    }

    &:active {
        box-shadow: 0px 0px 0px 2px #bbb;
        border-radius: 30px;
    }
`