import { TipoEntrega } from "../enums/TipoEntrega.enum"
import { TipoOrdenacaoCardapio } from "../enums/TipoOrdenacaoCardapio.enum"
import { ContatoParaDadosSobreFornecedor } from "./ContatoParaDadosSobreFornecedor"
import { EnderecoParaDadosSobreFornecedor } from "./EnderecoParaDadosSobreFornecedor"
import { FormaPagamentoParaDadosSobreFornecedor } from "./FormaPagamentoParaDadosSobreFornecedor"
import { FuncionamentoParaDadosSobreFornecedor } from "./FuncionamentoParaDadosSobreFornecedor"
import { TaxaEntregaParaDadosSobreFornecedor } from "./TaxaEntregaParaDadosSobreFornecedor"

export class DadosSobreFornecedor {
    id: number = 0
    codigo: string = ''
    nome: string = ''
    logo: string = ''
    contatos: ContatoParaDadosSobreFornecedor[] = []
    funcionamento: FuncionamentoParaDadosSobreFornecedor[] = []
    enderecos: EnderecoParaDadosSobreFornecedor[] = []
    formasPagamentos: FormaPagamentoParaDadosSobreFornecedor[] = []
    taxasEntregas: TaxaEntregaParaDadosSobreFornecedor[] = []
    tipoEntrega: TipoEntrega = TipoEntrega.DeliveryOuRetirarNoLocal
    taxaEntrega: number = 0
    mensagemTaxaEntrega: string = ''
    tipoOrdenacaoCardapio: TipoOrdenacaoCardapio = TipoOrdenacaoCardapio.PorPrecoMenorParaMaior
    observacao: string = ''
}

export function linhasParaObservacao(fornecedor?: DadosSobreFornecedor, largura: number = 70) {
    return fornecedor?.observacao ? fornecedor.observacao.length / largura : 0
}