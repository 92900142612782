import styled from 'styled-components'

interface IButtonProps {
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
}

export const Button = styled.button<IButtonProps>`
    padding: 6px 10px;
    border: 2px solid var(--color-blue-5);
    border-radius: 5px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};

    &:hover {
        border: 2px solid var(--color-blue-4);
    }
`