import {isMobile} from 'react-device-detect'

const openLink = (link: string, target?: string) => {
    try {
        var a = document.createElement('a')
        a.setAttribute('href', link)
        if (target) a.setAttribute('target', target)
        a.click()
    } catch (error: any) {
        alert('Não estou conseguindo abrir o WhatsApp!')
        console.log(error)
    }
}

export class LinkService {

    openWhatsApp = (phone: string, text?: string) => {
        let link = ''
        if (isMobile) {
            link = `whatsapp://send?phone=55${phone}`
            if (text) {
                link += `&text=${text}`
            }
            return openLink(link)
        } else {
            link = `https://api.whatsapp.com/send?phone=55${phone}`
            if (text) {
                link += `&text=${text}`
            }
            return openLink(link, '_blank')    
        }
    }
}