import { FornecedorService } from '../../../services/FornecedorService'
import { PedidoService } from '../../../services/PedidoService'
import StorageService from '../../../services/StorageService'
import { LocalizacaoService } from '../../../services/LocalizacaoService'

const useFechaPedidoService = () => {

    const storage = new StorageService()
    const fornecedor = new FornecedorService()
    const pedido = new PedidoService()
    const localizador = new LocalizacaoService()

    return {
        storage,
        fornecedor,
        pedido,
        localizador
    }
}

export default useFechaPedidoService