import React, { useMemo, useState } from 'react'
import {StaticText} from '../StaticText/StaticText'
import {OpcaoItemPorFornecedor} from '../../models/OpcaoItemPorFornecedor'
import { PedidoItemOpcao } from '../../models/PedidoItemOpcao'
import Checkbox from '../Checkbox/Checkbox'
import Spinner from '../Spinner/Spinner'

interface ICardOpcaoProps {
    opcao: OpcaoItemPorFornecedor
    pageCarrinho?: boolean
    opcaoPedido?: PedidoItemOpcao | null
    personalizar?: (quantidade: number) => void
    estaACustomizando?: boolean
}

const CardOpcao: React.FC<ICardOpcaoProps> = ({
    opcao,
    pageCarrinho,
    opcaoPedido,
    personalizar,
    estaACustomizando
}) => {

    const [customizando, setCustomizando] = useState(estaACustomizando || false)

    //opcaoPedido está como dependência para forçar a chamada de setCustomizando
    useMemo(() => {
        setCustomizando(false)
    }, [opcaoPedido]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!opcao) return null

    const customizar = (quantidade: number) => {
        if (personalizar) {
            setCustomizando(true)
            personalizar(quantidade)
        }
    }

    if (customizando) {
        return (
            <StaticText textSize='0.9em' textColor='var(--color-gray-2)' key={`opcao-${opcao.id}`} marginTop='3px' marginBottom='3px'>
                <Spinner color='var(--color-blue-1)' style={{fontSize:'1.20em', marginRight:'4.5px'}} />
                {opcao.nome}
            </StaticText>
        )
    }
    
    if (pageCarrinho) {
        const selected = opcaoPedido ? opcaoPedido.quantidade > 0 : false
        return (
            <Checkbox
                selected={selected}
                text={opcao.nome} 
                textSize={'0.9em'}
                textColor='gray'
                marginTop='3px'
                marginBottom='3px'
                click={() => customizar(selected ? 0 : 1)}
            />
        )
    }

    return (
        <StaticText textSize='0.9em' textColor='var(--color-gray-2)' key={`opcao-${opcao.id}`}>
            {opcao.nome}
        </StaticText>
    )
}
  
export default CardOpcao