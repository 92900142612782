import React from 'react'
import {RoundBox} from '../RoundBox/RoundBox'
import {StaticText} from '../StaticText/StaticText'

interface ILogoFornecedorProps {
    logo?: string
    nomeFornecedor: string
}

const LogoFornecedor: React.FC<ILogoFornecedorProps> = ({
    logo,
    nomeFornecedor
}) => {

    if (logo) {
        return (
            <RoundBox size='50px'>
                <img src={`data:image/jpeg;base64,${logo}`} width='50px' height='50px' alt={nomeFornecedor} />
            </RoundBox>
        )
    }

    if (!nomeFornecedor) {
        return (
            <RoundBox size='50px' borderColor='var(--color-gray-4)' borderWidth='2px'>
            </RoundBox>
        )
    }

    const nome = nomeFornecedor
        .replace('&', ' ')
        .replace(' e ', ' ')
        .replace(' de ', ' ')
        .replace(' do ', ' ')
        .replace(' da ', ' ')
        .replace(' - ', ' ')
    const palavras = nome.split(' ')
    let sigla = '';
    if (palavras.length > 0) {
        sigla += palavras[0][0];
    }
    if (palavras.length > 1) {
        sigla += palavras[1][0];
    }
    if (sigla) {
        return (
            <RoundBox size='50px' borderColor='var(--color-gray-4)' borderWidth='2px'>
                <StaticText textSize='1.4em' bold={true} textColor='var(--color-gray-2)'>{sigla}</StaticText>
            </RoundBox>
        )
    }

    return null
}
  
export default LogoFornecedor