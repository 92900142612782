import React from 'react'
import NavMenu from '../NavMenu/NavMenu'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import SideBarCenter from '../SideBarCenter/SideBarCenter'
import {StaticText} from '../StaticText/StaticText'
import BarButtonVoltar from '../BarButtonVoltar/BarButtonVoltar'

interface INavMenuLCProps {
    clickVoltar: () => void
    textCenter?: string
}

const NavMenuLC: React.FC<INavMenuLCProps> = ({
    clickVoltar,
    textCenter
}) => (
    <NavMenu>
        <SideBarLeft><BarButtonVoltar click={clickVoltar}/></SideBarLeft>
        <SideBarCenter><StaticText textColor='var(--color-blue-platelo)' bold>{textCenter}</StaticText></SideBarCenter>
    </NavMenu>
)

export default NavMenuLC