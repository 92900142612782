import React from 'react'
import NavMenu from '../NavMenu/NavMenu'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import SideBarCenter from '../SideBarCenter/SideBarCenter'
import SideBarRight from '../SideBarRight/SideBarRight'
import {StaticText} from '../StaticText/StaticText'
import BarButtonVoltar from '../BarButtonVoltar/BarButtonVoltar'
import BarButtonAvancar from '../BarButtonAvancar/BarButtonAvancar'

interface INavMenuLCRProps {
    clickVoltar: () => void
    clickAvancar: () => void
    textRight?: string
    textCenter?: string
    loadingRight?: boolean
    noBorderRight?: boolean
    noPrimaryRight?: boolean
}

const NavMenuLCR: React.FC<INavMenuLCRProps> = ({
    clickVoltar,
    clickAvancar,
    textRight,
    textCenter,
    loadingRight,
    noBorderRight,
    noPrimaryRight
}) => (
    <NavMenu>
        <SideBarLeft><BarButtonVoltar click={clickVoltar}/></SideBarLeft>
        <SideBarCenter><StaticText textColor='var(--color-blue-platelo)' bold>{textCenter}</StaticText></SideBarCenter>
        <SideBarRight><BarButtonAvancar loading={loadingRight} click={clickAvancar} text={textRight || ''} noBorder={noBorderRight} noPrimary={noPrimaryRight} /></SideBarRight>
    </NavMenu>
)

export default NavMenuLCR