import React from 'react'
import { isMobile } from 'react-device-detect'
import { SideBar } from '../SideBar/SideBar'

const SideBarCenter: React.FC = ({
    children
}) => (
    <SideBar position='center' top={isMobile ? '10px' : '6px'}>
        {children}
    </SideBar>
)

export default SideBarCenter