import React, { useEffect, useState } from 'react'
import { useHistory, useParams  } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuLC from '../../components/NavMenuLC/NavMenuLC'
import BodyNav from '../../components/BodyNav/BodyNav'
import { FornecedorService } from '../../services/FornecedorService'
import CardFornecedor from '../../components/CardFornecedor/CardFornecedor'
import { FornecedorPorLocalizacao } from '../../models/FornecedorPorLocalizacao'

interface IFornecedoresPorCategoriaPorLocalizacaoParams {
  estado: string
  municipio: string
  categoria: string
}

const FornecedoresPorCategoriaPorLocalizacao: React.FC = () => {

  const history = useHistory()
  const { estado, municipio, categoria } = useParams<IFornecedoresPorCategoriaPorLocalizacaoParams>();
  const [fornecedores, setFornecedores] = useState<FornecedorPorLocalizacao[]>([])

  useEffect(() => {
    const fornecedorService = new FornecedorService()
    fornecedorService
      .obterApenasFornecedoresPorCategoriaLocalizacao(estado, municipio, categoria)
      .then(dados => {
        setFornecedores(dados || [])
      })
  }, [estado, municipio, categoria])

  const voltar = () => {
      history.push('/home?tab=categorias')
  }

  const body = () => {
    return fornecedores.map((fornecedor) =>
      <CardFornecedor
        nome={fornecedor.nome}
        identificador={fornecedor.identificador}
        municipioIdentificador={fornecedor.municipioIdentificador}
        estadoIdentificador={fornecedor.estadoIdentificador}
        funcionamento={fornecedor.funcionamento}
        logo={fornecedor.logo}
        key={`fornecedor-${fornecedor.identificador}`}
        linkTo={`${categoria}/de`} 
      />
    )
  }

  return (
    <Page>
      <FixedTop>
        <NavMenuLC clickVoltar={voltar} textCenter='Fornecedores'/>
      </FixedTop>
      <BodyNav bottom='60px'>
        {body()}
      </BodyNav>
    </Page>
  )
}
  
export default FornecedoresPorCategoriaPorLocalizacao