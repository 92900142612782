import React from 'react'
import {Container} from '../../Container/Container'
import {StaticText} from '../../StaticText/StaticText'
import {currencyFormat} from '../../../services/CurrencyService'
import { PedidoAdicional } from '../../../models/PedidoAdicional'

interface IAdicionaisProps {
    adicionaisPedido?: PedidoAdicional[]
}

const Adicionais: React.FC<IAdicionaisProps> = ({
    adicionaisPedido
}) => {

    if (!adicionaisPedido || adicionaisPedido.length === 0) return null

    const renderAdicionais = () => {
        return adicionaisPedido.map((adicionalPedido) => {
            const total = adicionalPedido.quantidade * adicionalPedido.preco
            const totalFormatado = currencyFormat(total, 'R$ ')
            const valores = `${totalFormatado}`
            const textAdicionalQuantidade = `+${adicionalPedido.quantidade} ${adicionalPedido.itemCardapioAdicionalNome} ${adicionalPedido.precoItemCardapioNome}`
            return (
                <Container key={`adicional-${adicionalPedido.itemCardapioAdicionalId}-${adicionalPedido.precoItemCardapioId}`}>
                    <StaticText textSize='0.9em' textColor='var(--color-gray-2)' displayBlock flex='1'>
                        {textAdicionalQuantidade}
                    </StaticText>
                    <StaticText textSize='0.9em' textColor='var(--color-gray-2)' displayBlock flex='1' textAlign='right'>
                        {valores}
                    </StaticText>
                </Container>
            )
        })
    }

    return (
        <Container borderTop='1px solid var(--color-gray-4)' paddingTop='5px' marginTop='10px' columnDirection>
            {renderAdicionais()}
        </Container>
    )
}
  
export default Adicionais