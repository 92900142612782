import React from 'react'
import CardFornecedorFixedBottom from '../../../components/CardFornecedor/CardFornecedorFixedBottom'
import { DadosSobreFornecedor } from "../../../models/DadosSobreFornecedor"

interface IPageFooterProps {
    ampliarTela: boolean
    municipioIdentificador: string
    estadoIdentificador: string
    dadosFornecedor?: DadosSobreFornecedor
}

const PageFooter: React.FC<IPageFooterProps> = ({
    ampliarTela,
    municipioIdentificador,
    estadoIdentificador,
    dadosFornecedor
}) => {

    if (ampliarTela) return null

    return (
        <CardFornecedorFixedBottom
            dadosFornecedor={dadosFornecedor}
            municipioIdentificador={municipioIdentificador}
            estadoIdentificador={estadoIdentificador}
        />
    )
}

export default PageFooter