import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Button} from './styles'
import {StaticText} from '../StaticText/StaticText'
import Spinner from '../Spinner/Spinner'

interface ICardButtonProps {
    text?: string
    textColor?: string
    noBold?: boolean
    icon?: any
    iconSize?: any
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
    click?: () => void
    loading?: boolean
    textSize?: string
    textAlign?: string
}

const CardButton: React.FC<ICardButtonProps> = ({
    text, textColor,
    noBold,
    icon,iconSize,
    marginTop, marginBottom, marginLeft, marginRight,
    click,
    loading,
    textSize,
    textAlign
}) => {

    const color = loading ? 'var(--color-gray-4)' : textColor

    const onClick = () => {
        if (loading || !click) return

        click()
    }

    const renderIcon = () => {
        if (loading) {
            return (
                <Spinner size={iconSize || '1x'} color='var(--color-gray-4)' title={text} style={{marginRight:text?'5px':'0px'}} />
            )    
        }

        if (!icon) return null

        return (
            <FontAwesomeIcon icon={icon} size={iconSize || '1x'} color='var(--color-gray-1)' title={text} style={{marginRight:text?'5px':'0px'}}/>
        )
    }

    let buttonAlign = textAlign
    if (textAlign === 'left') {
        buttonAlign = 'start'
    } else if (textAlign === 'right') {
        buttonAlign = 'end'
    }

    return (
        <Button onClick={onClick} marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight} align={buttonAlign}>
            {renderIcon()}
            <StaticText textAlign={textAlign || 'center'} textColor={color || 'var(--color-gray-1)'} bold={noBold ? false : true} textSize={textSize}>{text}</StaticText>
        </Button>
    )
}
  
export default CardButton