import React from 'react'
import TabMenu from '../../../components/TabMenu/TabMenu'
import TabMenuItem from '../../../components/TabMenuItem/TabMenuItem'

interface ITabMenuHomeProps {
    localizacaoDefinida: boolean
    activeMenu: string
    ativarTabFornecedores: () => void
    ativarTabCategorias: () => void
}

const TabMenuHome: React.FC<ITabMenuHomeProps> = ({
    localizacaoDefinida,
    activeMenu,
    ativarTabFornecedores,
    ativarTabCategorias
}) => {

    if (!localizacaoDefinida) return null

    return (
        <TabMenu>
          <TabMenuItem id='for' text='Fornecedores' active={activeMenu==='for'} click={(id) => ativarTabFornecedores()} />
          <TabMenuItem id='cat' text='Categorias' active={activeMenu==='cat'} click={(id) => ativarTabCategorias()} />
        </TabMenu>
    )
}

export default TabMenuHome