import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuLC from '../../components/NavMenuLC/NavMenuLC'
import BodyNav from '../../components/BodyNav/BodyNav'
import CardMeuPedido from '../../components/CardMeuPedido/CardMeuPedido'
import { Pedido } from '../../models/Pedido'
import { PedidoService } from '../../services/PedidoService'
import StorageService from '../../services/StorageService'
import Aguarde from '../../components/Aguarde/Aguarde'
import Mensagem from '../../components/Mensagem/Mensagem'
import { PanelButtons } from '../../components/PanelButtons/PanelButtons'
import PanelButton from '../../components/PanelButton/PanelButton'

const MeusPedidos: React.FC = () => {

  const history = useHistory()
  const [pedidos, setPedidos] = useState<Pedido[]>([])
  const [pagina, setPagina] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [quantidade, setQuantidade] = useState<number>(0)
  const [carregando, setCarregando] = useState(true)
  const [carregandoMaisPedidos, setCarregandoMaisPedidos] = useState(false)
  const [atualizacao, setAtualizacao] = useState(false)

  const storage = new StorageService()
  const clienteId = storage.obterSessaoCliente()?.clienteId

  const carregarPaginaPedidos = (pagina: number) => {
    if (!clienteId) {
      setCarregandoMaisPedidos(false)
      setCarregando(false)
      return
    }
    const pedidoService = new PedidoService()
    pedidoService.obterListaPedidosPorCliente(clienteId, pagina)
      .then(response => {
        setCarregandoMaisPedidos(false)
        setCarregando(false)
        if (response.data.sucesso) {
          const novoTotal = response.data.resultado?.total || 0
          const novos = response.data.resultado?.pedidos || []
          const novaLista = pagina === 1 ? [...novos] : [...pedidos, ...novos]
          setPedidos(novaLista)
          setQuantidade(novaLista.length)
          setTotal(novoTotal)
        }
      })
      .finally(() => {
        setCarregandoMaisPedidos(false)
        setCarregando(false)
      })
  }

  const carregarMaisPedidos = () => {
    setCarregandoMaisPedidos(true)
    const proximaPagina = pagina + 1
    setPagina(proximaPagina)
    carregarPaginaPedidos(proximaPagina)
  }  

  useEffect(() => {
    if (!clienteId) {
      history.push(`/entrar`)
      return
    }
    carregarPaginaPedidos(pagina)
  }, [clienteId, history, pagina]);

  const voltar = () => {
      history.push(`/menu`)
  }

  const renderPedidos = () => {
    if (carregando) {
      return <Aguarde texto='Obtendo seus pedidos...' />
    }
    
    if (!pedidos?.length) {
      return (
        <Mensagem texto='Nenhum pedido!' />
      )
    }

    return pedidos.filter(p => p.itens.length > 0).map(pedido => 
      <CardMeuPedido key={pedido.pedidoId} pedido={pedido}/>
    )
  }

  if (!atualizacao) {
    setAtualizacao(true)
    window.scrollTo(0, 0)
  }
  return (
    <Page>
      <FixedTop>
        <NavMenuLC clickVoltar={voltar} textCenter='Meus Pedidos'/>
      </FixedTop>
      <BodyNav>
        {renderPedidos()}
        {quantidade < total && !carregando ?
        <PanelButtons>
          <PanelButton text='Mais Pedidos' click={carregarMaisPedidos} loading={carregandoMaisPedidos} />
        </PanelButtons>
        : null}
      </BodyNav>
    </Page>
  )
}
  
export default MeusPedidos