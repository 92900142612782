import React from 'react'
import { TabMenuItemContainer } from './styles'
import {StaticText} from '../StaticText/StaticText'

interface ITabMenuItemProps {
    id: string
    text: string
    active: boolean
    click: (id: string) => void
}

const TabMenuItem: React.FC<ITabMenuItemProps> = ({
    id,
    text,
    active,
    click
}) => (
    <TabMenuItemContainer active={active} onClick={() => click(id)}>
        <StaticText textColor='var(--color-gray-1)' textSize='1.1em'>{text}</StaticText>
    </TabMenuItemContainer>
)

export default TabMenuItem