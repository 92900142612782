import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

export const NavMenuContainer = styled.div`
    display: block;
    background-color: var(--color-body);
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    min-height: ${isMobile ? 'var(--fixed-bar-mobile-height)' : 'var(--fixed-bar-height)'};
`