import styled from 'styled-components'

interface ICardProps {
    clickable?: boolean
    noBorder?: boolean
    backgroundColor?: string
    displayFlex?: boolean
    flexSize?: string
    flexColumn?: boolean
    borderTop?: boolean
    borderColor?: string
    borderRadius?: string
    borderRadiusTop?: string
    borderRadiusBottom?: string
    marginTop?: string
}

export const Card = styled.div<ICardProps>`
    display: ${props => props.displayFlex ? 'flex' : 'block'};
    flex: ${props => props.flexSize || 'auto'};
    background-color: ${props => props.backgroundColor || 'var(--color-white)'};
    border-bottom: ${props => props.noBorder ? 'none' : `4px solid ${props.borderColor || 'var(--color-body)'}`};
    border-top: ${props => props.borderTop ? '10px solid var(--color-body)' : 'none'};
    cursor: ${props => props.clickable ? 'pointer' : 'normal'};
    flex-direction: ${props => props.displayFlex && props.flexColumn ? 'column' : 'row'};
    border-radius: ${props => props.borderRadius || '0px'};
    border-top-left-radius: ${props => props.borderRadiusTop || props.borderRadius || '0px'};
    border-top-right-radius: ${props => props.borderRadiusTop || props.borderRadius || '0px'};
    border-bottom-left-radius: ${props => props.borderRadiusBottom || props.borderRadius || '0px'};
    border-bottom-right-radius: ${props => props.borderRadiusBottom || props.borderRadius || '0px'};
    margin-top: ${props => props.marginTop || 'none'};
    overflow: hidden;

    &:hover {
        background-color: ${props => props.clickable ? 'var(--color-gray-5)' : props.backgroundColor || 'var(--color-white)'};
    }
`