import React from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuLC from '../../components/NavMenuLC/NavMenuLC'
import BodyNav from '../../components/BodyNav/BodyNav'
import { CardButtons } from '../../components/CardButtons/CardButtons'
import CardButton from '../../components/CardButton/CardButton'
import StorageService from '../../services/StorageService'

const Menu: React.FC = () => {

  const history = useHistory()

  const storage = new StorageService()

  const irPara = (pagina: string) => {
      history.push(`/${pagina}`)
  }

  const voltar = () => {
      irPara('')
  }

  const logout = () => {
    storage.removerSessaoCliente()
    voltar()
  }

  window.scrollTo(0, 0)

  return (
    <Page>
      <FixedTop>
        <NavMenuLC clickVoltar={voltar} textCenter='Menu'/>
      </FixedTop>
      <BodyNav>
        <CardButtons flexColumn alignContent='stretch'>
          <CardButton text='Meus Pedidos' marginBottom='10px' click={() => irPara('meus-pedidos')} />
          <CardButton text='Meus Dados' marginBottom='10px' click={() => irPara('meus-dados')} />
          <CardButton text='Sair' click={logout} />
        </CardButtons>
      </BodyNav>
    </Page>
  )
}
  
export default Menu