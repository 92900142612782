import React from 'react'
import { NavMenuContainer } from './styles'

const NavMenu: React.FC = ({
    children
}) => (
    <NavMenuContainer>
        {children}
    </NavMenuContainer>
)

export default NavMenu