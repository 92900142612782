import React from 'react'
import Fixed from './Fixed'

const FixedBottom: React.FC = ({
    children
}) => (
    <Fixed position='bottom'>
        {children}
    </Fixed>
)

export default FixedBottom