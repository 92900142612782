import React, { useEffect, useMemo, useState } from 'react'
import Aguarde from '../../../../components/Aguarde/Aguarde'
import CardButton from '../../../../components/CardButton/CardButton'
import { CardButtons } from '../../../../components/CardButtons/CardButtons'
import { Estado } from '../../../../models/Estado'
import { CardapioService } from '../../../../services/CardapioService'
import { Municipio } from '../../../../models/Municipio'
import { Container, EstadoSelecionado, NomeEstadoSelecionado, BotaoLimparEstadoSelecionado, Pergunta } from './styles'
import { Localizacao } from '../../../../models/Localizacao'

interface IPainelLocalizacaoProps {
    aoDefinirLocalizacao: (localizacao: Localizacao) => void
}

const PainelLocalizacao: React.FC<IPainelLocalizacaoProps> = ({
    aoDefinirLocalizacao
}) => {

    const [estado, setEstado] = useState<Estado | null>()
    const [estados, setEstados] = useState<Estado[]>([])
    const [municipio] = useState<Municipio>()
    const [municipios, setMunicipios] = useState<Municipio[]>([])

    useEffect(() => {
        const cardapioService = new CardapioService()
        cardapioService.obterApenasEstadosComCardapiosDisponiveis()
            .then(dados => {
                setEstados(dados)
            })
    }, []);

    useMemo<Municipio[] | null | undefined>(() => {
        if (!estado) return null

        const cardapioService = new CardapioService()
        cardapioService.obterApenasMunicipiosComCardapiosDisponiveis(estado.estadoId)
            .then(dados => {
                setMunicipios(dados)
            })
            .catch(() => {
                setMunicipios([])
            })
    }, [estado])

    const definirLocalizacao = (estadoSelecionado?: Estado | null, municipioSelecionado?: Municipio | null) => {
        if (!estadoSelecionado || !municipioSelecionado) return null

        let localizacao = new Localizacao()
        localizacao.municipio = municipioSelecionado.nome
        localizacao.municipioIdentificador = municipioSelecionado.identificador
        localizacao.uf = estadoSelecionado.uf

        aoDefinirLocalizacao(localizacao)
    }

    if (!estado && !estados?.length) {
        return (
            <Aguarde texto='Obtendo estados onde o Platelo possui fornecedores parceiros...' />
        )
    }

    if (!estado && estados?.length) {
        return (
            <CardButtons flexColumn alignContent='stretch'>
                <Pergunta>Em qual estado você está?</Pergunta>
                {estados.map(uf =>
                    <CardButton key={`uf-${uf.estadoId}`} text={uf.nome} marginBottom='10px' click={() => setEstado(uf)} />
                )}
            </CardButtons>
        )
    }

    if (!municipio && !municipios?.length) {
        return (
            <Aguarde texto='Obtendo cidades onde o Platelo possui fornecedores parceiros...' />
        )
    }

    const renderMunicipios = () => {
        return municipios?.map(cid =>
            <CardButton key={`cid-${cid.municipioId}`} text={cid.nome} marginBottom='10px' click={() => definirLocalizacao(estado, cid)} />
        )
    }

    return (
        <Container>
            <EstadoSelecionado>
                <NomeEstadoSelecionado>{estado?.nome}</NomeEstadoSelecionado>
                <BotaoLimparEstadoSelecionado onClick={() => setEstado(null)}>Mudar Estado</BotaoLimparEstadoSelecionado>
            </EstadoSelecionado>
            <CardButtons flexColumn alignContent='stretch'>
                <Pergunta>Em qual cidade você está?</Pergunta>
                {renderMunicipios()}
            </CardButtons>
        </Container>
    )
}

export default PainelLocalizacao