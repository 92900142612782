import { TipoItem } from "../enums/TipoItem.enum"
import { PrecoItemPorFornecedor } from "./PrecoItemPorFornecedor"

export class SaborPorFornecedor {
    id: number = 0
    nome: string = ''
    detalhes: string = ''
    categoriaId: number = 0
    categoria: string = ''
    categoriaPlural: string = ''
    categoriaPrefixo: number = 0
    categoriaPrefixoPedido: boolean = false
    preco: PrecoItemPorFornecedor | null = null
    tipoItem: TipoItem = TipoItem.Produto
    ativo: boolean = true
    categoriaAceitaAdicionais: boolean = false
    observacoes: string = ''
    opcaoQuantidadeMinima: number = 0
    opcaoQuantidadeMaxima: number = 0
    fornecedorId: number = 0
    saboresQuantidadeMinima: number = 0
    saboresQuantidadeMaxima: number = 0
}