import React from 'react'
import { isMobile } from 'react-device-detect'
import { SideBar } from '..//SideBar/SideBar'

const SideBarLeft: React.FC = ({
    children
}) => (
    <SideBar position='left' top={isMobile ? '8px' : '5px'}>
        {children}
    </SideBar>
)

export default SideBarLeft