import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`

export const EstadoSelecionado = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: #fff;
    margin-bottom: 3px;
    align-items: center;
`

export const NomeEstadoSelecionado = styled.div`
    flex: 1;
    font-weight: bold;
`

export const BotaoLimparEstadoSelecionado = styled.button`
    border: 1px solid #000;
    border-radius: 3px;
    background-color: transparent;
    font-size: 0.7em;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
`

export const Pergunta = styled.div`
    text-align: center;
    margin-bottom: 10px;
`