export function base64_to_utf8(base64?: string | null) {
    let utf8Value = ''
    try {
        utf8Value = base64 ? window.atob(base64) : ''
    } catch {
        utf8Value = base64 || ''
    }
    const escapedValue = utf8Value ? escape(utf8Value) : ''
    const decodedValue = escapedValue ? decodeURIComponent(escapedValue) : ''
    return decodedValue;
}

export function utf8_to_base64(utf8Value?: string) {
    const encodedVaue = utf8Value ? encodeURIComponent(utf8Value) : ''
    const unescapedValue = encodedVaue ? unescape(encodedVaue) : ''
    const base64 = unescapedValue ? window.btoa(unescapedValue) : ''
    return base64
}