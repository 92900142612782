import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

interface ISpinnerProps {
    size?: SizeProp
    color?: string
    title?: string
    style?: any
}

const Spinner: React.FC<ISpinnerProps> = ({
    size,
    color,
    title,
    style
}) => {

    return (
        <FontAwesomeIcon
            icon={faSpinner}
            pulse
            size={size || '1x'}
            color={color || 'var(--color-gray-2)'}
            title={title}
            style={style}
        />
    )
}

export default Spinner