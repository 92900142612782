import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ItemPorFornecedor } from '../../models/ItemPorFornecedor'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import FixedBottom from '../../components/Fixed/FixedBottom'
import NavMenuCardapio from '../../components/NavMenuCardapio/NavMenuCardapio'
import BodyNav from '../../components/BodyNav/BodyNav'
import CardProduto from '../../components/CardProduto/CardProduto'
import { CardapioService } from '../../services/CardapioService'
import { DadosSobreFornecedor, linhasParaObservacao } from '../../models/DadosSobreFornecedor'
import CardFornecedorBottom from '../../components/CardFornecedor/CardFornecedorBottom'
import { Pedido } from '../../models/Pedido'
import Aguarde from '../../components/Aguarde/Aguarde'
import latinize from 'latinize'
import { CardapioServicePage } from '../../services/CardapioServicePage'
import { GrupoOpcoes } from '../../models/GrupoOpcoes'

type CardapioParams = {
  fornecedor: string
  municipio: string
  estado: string
  categoria: string
}

const CardapioProdutos: React.FC = () => {

  const history = useHistory()
  const { fornecedor, municipio, estado, categoria } = useParams<CardapioParams>()
  const [produtos, setProdutos] = useState<ItemPorFornecedor[]>([])
  const [dadosFornecedor, setDadosFornecedor] = useState<DadosSobreFornecedor>()
  const [estadoIdentificador, setEstadoIdentificador] = useState<string>(estado)
  const [municipioIdentificador, setMunicipioIdentificador] = useState<string>(municipio)
  const [pedido, setPedido] = useState<Pedido>()
  const [atualizacao, setAtualizacao] = useState(false)
  const [carregandoCardapio, setCarregandoCardapio] = useState(true)
  const [carregandoFornecedor, setCarregandoFornecedor] = useState(true)
  const [carregandoPedido, setCarregandoPedido] = useState(false)
  const [pesquisaTexto, setPesquisaTexto] = useState('')
  const [gruposOpcoes, setGruposOpcoes] = useState<GrupoOpcoes[]>([])

  const servicePage = new CardapioServicePage(
    (value) => setCarregandoFornecedor(value),
    (value) => setCarregandoPedido(value),
    (value) => setDadosFornecedor(value),
    (value) => setPedido(value),
    (value) => setEstadoIdentificador(value),
    (value) => setMunicipioIdentificador(value),
    (value) => setGruposOpcoes(value)
  )

  //Encontrar uma forma de resolver esse worning:
  //Line 58:6:  React Hook useEffect has a missing dependency: 'servicePage'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
  //Talvez criar um Hook para CardapioServicePage em vez de uma simples classe
  useEffect(() => {
    const cardapioService = new CardapioService()
    cardapioService
      .obterApenasProdutosPorCategoriaDeFornecedor(fornecedor, categoria)
      .then(dados => {
        setCarregandoCardapio(false)
        setProdutos(dados || [])
      })

    servicePage.obterFornecedor(fornecedor, estado, municipio)
  }, [fornecedor, municipio, estado, categoria])

  const voltar = () => {
      history.push(`/cardapio/${fornecedor}/em/${municipioIdentificador}/${estadoIdentificador}`)
  }

  const irParaCarrinho = () => {
    history.push(`/carrinho/${fornecedor}/em/${municipioIdentificador}/${estadoIdentificador}`)
  } 

  const body = () => {
    if (carregandoCardapio) {
      return <Aguarde texto='Obtendo cardápio...' />
    }

    const latfiltro = latinize(pesquisaTexto || '').toLocaleLowerCase()
    const lista = pesquisaTexto ? produtos.filter(o => latinize(o.nome).toLocaleLowerCase().indexOf(latfiltro) >= 0) : produtos
    
    return lista.map((produto) => {
      const itens = pedido?.itens?.filter(i => i.itemCardapioId === produto.id) || []
      return (
        <CardProduto key={`produto-${produto.id}`}
          fornecedorId={produto.fornecedorId}
          produtoId={produto.id}
          produtoNome={produto.nome}
          produtoDetalhes={produto.detalhes}
          produtoObservacoes={produto.observacoes}
          precos={produto.precos}
          opcoes={produto.opcoes}
          categoriaNome={produto.categoria}
          aoAlterarPedidoAberto={(novoPedido) => setPedido(novoPedido)}
          itens={itens}
          multisabores={produto.saboresQuantidadeMinima > 0}
          quantidadeMaximaSabores={produto.saboresQuantidadeMaxima}
          gruposOpcoesFornecedor={gruposOpcoes}
          gruposOpcoes={produto.gruposOpcoes || []}
        />
      )
    })
  }

  if (!atualizacao) {
    setAtualizacao(true)
    window.scrollTo(0, 0)
  }

  return (
    <Page>
      <FixedTop>
        <NavMenuCardapio
          carregandoPedido={carregandoPedido}
          pedido={pedido}
          pesquisaTexto={pesquisaTexto}
          setPesquisaTexto={(texto) => setPesquisaTexto(texto)}
          clickVoltar={voltar}
          clickAvancar={irParaCarrinho}
        />
      </FixedTop>
      <BodyNav extraBottomLines={linhasParaObservacao(dadosFornecedor)}>
        {body()}
      </BodyNav>
      {carregandoFornecedor ? null :
      <FixedBottom>
        <CardFornecedorBottom
          dadosFornecedor={dadosFornecedor}
          municipioIdentificador={municipioIdentificador}
          estadoIdentificador={estadoIdentificador}
        />
      </FixedBottom>
      }
    </Page>
  )
}
  
export default CardapioProdutos