import React, { useMemo, useState } from 'react'
import NavMenuLCRCustom from '../NavMenuLCRCustom/NavMenuLCRCustom'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import BarButtonPesquisar from '../BarButtonPesquisar/BarButtonPesquisar'
import BarButton from '..//BarButton/BarButton'
import {Pedido} from '../../models/Pedido'

interface INavMenuCardapioProps {
    clickVoltar: () => void
    clickAvancar: () => void
    carregandoPedido: boolean
    pedido?: Pedido
    pesquisaTexto?: string
    setPesquisaTexto: (texto: string) => void
}

const NavMenuCardapio: React.FC<INavMenuCardapioProps> = ({
    clickVoltar,
    clickAvancar,
    carregandoPedido,
    pedido,
    pesquisaTexto,
    setPesquisaTexto
}) => {

    const [pesquisa, setPesquisa] = useState(false)

    const nroItens = useMemo(() => pedido?.numeroItens || 0, [pedido])

    const pesquisar = (pesq: boolean) => {
        if (!pesq) setPesquisaTexto('')
        setPesquisa(pesq)
    }

    const barButtonPesquisa = <BarButtonPesquisar 
        click={() => pesquisar(!pesquisa)} 
        pesquisar={pesquisa}
        pesquisaTexto={pesquisaTexto}
        aoPesquisar={(texto) => setPesquisaTexto(texto)}
    />
    const barButtonCarrinho = nroItens && !carregandoPedido 
        ? <BarButton click={clickAvancar} iconLeft={faShoppingCart} badgeRight={nroItens.toString()} noBorder /> 
        : null

    return (
        <NavMenuLCRCustom clickVoltar={clickVoltar} textCenter='Cardápio'>
            {barButtonCarrinho}
            {barButtonPesquisa}
        </NavMenuLCRCustom>
    )
}

export default NavMenuCardapio