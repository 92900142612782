import React from 'react'
import { Container } from '../Container/Container'
import RadioButton from '../RadioButton/RadioButton'
import { StaticText } from '../StaticText/StaticText'

interface IOptions {
    value: any
    text: string    
}

interface IRadioButtonFieldProps {
  label: string
  options: IOptions[]
  selectedValue?: any
  click?: (value: any) => void
  rowDirection?: boolean
}

const RadioButtonField: React.FC<IRadioButtonFieldProps> = ({
    label,
    options,
    selectedValue,
    click,
    rowDirection
}) => {

  const onClick = (newValue: any) => {
    if (click) {
        click(newValue)
    }
  }
  
  const renderOptions = () => {
      if (!options || options.length === 0) return null

      return options.map(option => {
        return (
          <RadioButton 
            key={`rb-${option.value}`}
            value={option.value}
            selectedValue={selectedValue}
            text={option.text} 
            click={(value) => onClick(value)}
          />
        )
      })
  }

  return (
    <Container columnDirection padding='10px'>
        <StaticText bold marginBottom='3px'>{label}</StaticText>
        {rowDirection ?
          <Container border='1px solid var(--color-gray-4)' padding='8px 10px' borderRadius='3px'>
            {renderOptions()}
          </Container>
        :
          <Container columnDirection border='1px solid var(--color-gray-4)' padding='8px 10px' borderRadius='3px'>
              {renderOptions()}
          </Container>
        }
    </Container>
  )
}
export default RadioButtonField