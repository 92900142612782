import HttpService from './HttpService'
import { RespostaBase } from '../models/RespostaBase'
import { OpcaoPorLocalizacao } from '../models/OpcaoPorLocalizacao'
import { ItemPorFornecedor } from '../models/ItemPorFornecedor'
import { OpcaoPorFornecedor } from '../models/OpcaoPorFornecedor'
import { ItemAdicionalPorFornecedor } from '../models/ItemAdicionalPorFornecedor'
import { SaborPorFornecedor } from '../models/SaborPorFornecedor'
import StorageService from './StorageService'
import { Estado } from '../models/Estado'
import { Municipio } from '../models/Municipio'

export class CardapioService {

    private http: HttpService = new HttpService()
    private storage: StorageService = new StorageService()

    obterOpcoesPorLocalizacao = (estado: string, municipio: string) => {
        return this.http.get<RespostaBase<OpcaoPorLocalizacao[]>>(
            `OpcoesPorLocalizacao?estado=${estado}&municipio=${municipio}`)
    }
    obterApenasOpcoesPorLocalizacao = (estado: string, municipio: string) => {
        const promise: Promise<OpcaoPorLocalizacao[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterOpcoesPorLocalizacao(estado, municipio)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterOpcoesPorLocalizacao(estado, municipio).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarOpcoesPorLocalizacao(estado, municipio, resultado)
                    resolve(resultado)
                } else {
                    resolve([])
                }
            }).catch(() => {
                resolve([])
            })
        })
        return promise
    }

    obterOpcoesPorFornecedor = (fornecedor: string) => {
        return this.http.get<RespostaBase<OpcaoPorFornecedor[]>>(
            `OpcoesPorFornecedor?fornecedor=${fornecedor}`)
    }
    obterApenasOpcoesPorFornecedor = (fornecedor: string) => {
        const promise: Promise<OpcaoPorFornecedor[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterOpcoesPorFornecedor(fornecedor)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterOpcoesPorFornecedor(fornecedor).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarOpcoesPorFornecedor(fornecedor, resultado)
                    resolve(resultado)
                } else {
                    resolve([])
                }
            }).catch(() => {
                resolve([])
            })
        })
        return promise
    }

    obterProdutosPorCategoriaDeFornecedor = (fornecedor: string, categoria: string) => {
        return this.http.get<RespostaBase<ItemPorFornecedor[]>>(
            `ItensPorIdentificadoresDeFornecedorECategoria?fornecedor=${fornecedor}&categoria=${categoria}`)
    }
    obterApenasProdutosPorCategoriaDeFornecedor = (fornecedor: string, categoria: string) => {
        const promise: Promise<ItemPorFornecedor[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterProdutosPorCategoriaDeFornecedor(fornecedor, categoria)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterProdutosPorCategoriaDeFornecedor(fornecedor, categoria).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarProdutosPorCategoriaDeFornecedor(fornecedor, categoria, resultado)
                    resolve(resultado)
                } else {
                    resolve([])
                }
            }).catch(() => {
                resolve([])
            })
        })
        return promise
    }

    obterItensAdicionaisPorFornecedor = (fornecedorId: number) => {
        return this.http.get<RespostaBase<ItemAdicionalPorFornecedor[]>>(
            `ItensAdicionaisPorFornecedor/${fornecedorId}`)
    }
    obterApenasItensAdicionaisPorFornecedor = (fornecedorId: number) => {
        const promise: Promise<ItemAdicionalPorFornecedor[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterItensAdicionaisPorFornecedor(fornecedorId)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterItensAdicionaisPorFornecedor(fornecedorId).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarItensAdicionaisPorFornecedor(fornecedorId, resultado)
                    resolve(resultado)
                } else {
                    resolve([])
                }
            }).catch(() => {
                resolve([])
            })
        })
        return promise
    }

    obterEstadosComCardapiosDisponiveis = () => {
        return this.http.get<RespostaBase<Estado[]>>('EstadosComCardapiosDisponiveis')
    }
    obterApenasEstadosComCardapiosDisponiveis = () => {
        const promise: Promise<Estado[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterEstadosComCardapiosDisponiveis()
            if (dados) {
                resolve(dados)
                return
            }

            this.obterEstadosComCardapiosDisponiveis().then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarEstadosComCardapiosDisponiveis(resultado)
                    resolve(resultado)
                } else {
                    resolve([])
                }
            }).catch(() => {
                resolve([])
            })
        })
        return promise
    }

    obterMunicipiosComCardapiosDisponiveis = (estadoId: number) => {
        const filtro = {
            estadoId: estadoId
        }
        return this.http.post<RespostaBase<Municipio[]>, {estadoId:number}>('MunicipiosComCardapiosDisponiveis', filtro)
    }
    obterApenasMunicipiosComCardapiosDisponiveis = (estadoId: number) => {
        const promise: Promise<Municipio[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterMunicipiosComCardapiosDisponiveis(estadoId)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterMunicipiosComCardapiosDisponiveis(estadoId).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarMunicipiosComCardapiosDisponiveis(estadoId, resultado)
                    resolve(resultado)
                } else {
                    resolve([])
                }
            }).catch(() => {
                resolve([])
            })
        })
        return promise
    }

    obterSaboresPorFornecedorEAgrupador = (fornecedorId: number, agrupador: string) => {
        return this.http.get<RespostaBase<SaborPorFornecedor[]>>(
            `SaboresPorFornecedorEAgrupador?fornecedor=${fornecedorId}&agrupador=${agrupador}`)
    }
}