import styled from 'styled-components'
import {mediaScreen} from '../../styles'

export const PageContainer = styled.div`
    display: block;
    height: 100vh;
    background-color: var(--color-body);
`
export const PageCenter = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--color-body);
    margin-left: auto;
    margin-right: auto;

    ${mediaScreen};
`