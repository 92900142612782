import { ItemPedidoAberto } from '../models/ItemPedidoAberto'
import {RespostaBase} from '../models/RespostaBase'
import {Pedido} from '../models/Pedido'
import {ValorBaseNullable} from '../models/ValorBaseNullable'
import HttpService from './HttpService'
import StorageService from './StorageService'
import { Personalizacao } from '../models/Personalizacao'
import { ValidacaoFechamento } from '../models/ValidacaoFechamento'
import { AdicionalPedidoAberto } from '../models/AdicionalPedidoAberto'
import { FechamentoPedido } from '../models/FechamentoPedido'
import { RetornoFechamentoPedidoFinal } from '../models/RetornoFechamentoPedidoFinal'
import { ListaPedidosPorCliente } from '../models/ListaPedidosPorCliente'
import { ValorBase } from '../models/ValorBase'
import { SaboresPedidoAberto } from '../models/SaboresPedidoAberto'
import { OpcionaisPedidoAberto } from '../models/OpcionaisPedidoAberto'

export class PedidoService {

    private http: HttpService = new HttpService()
    private storage: StorageService = new StorageService()

    adicionarItem = (item: ItemPedidoAberto) => {
        return this.http.post<RespostaBase<ValorBaseNullable<Pedido>>, ItemPedidoAberto>(`PedidoAberto/Item`, item)
    }

    removerItem = (item: ItemPedidoAberto) => {
        return this.http.put<RespostaBase<ValorBaseNullable<Pedido>>, ItemPedidoAberto>(`PedidoAberto/Item`, item)
    }

    obterPedidoAberto = (fornecedorId: number, clienteId?: string | null, sessaoClienteId?: number | null) => {
        let parametros = `fornecedor=${fornecedorId}`
        if (clienteId) {
            parametros += `&cliente=${clienteId}`
        }
        if (sessaoClienteId && sessaoClienteId > 0) {
            parametros += `&sessao=${sessaoClienteId}`
        }
        return this.http.get<RespostaBase<Pedido>>(`PedidoAberto?${parametros}`)
    }

    personalizar = (personalizacao: Personalizacao) => {
        return this.http.post<RespostaBase<ValorBaseNullable<Pedido>>, Personalizacao>(`PedidoAberto/Personalizacao`, personalizacao)
    }

    pedidoAdicional = (adicional: AdicionalPedidoAberto) => {
        return this.http.post<RespostaBase<ValorBaseNullable<Pedido>>, AdicionalPedidoAberto>(`PedidoAberto/Adicional`, adicional)
    }

    sabores = (sabores: SaboresPedidoAberto) => {
        return this.http.post<RespostaBase<ValorBaseNullable<Pedido>>, SaboresPedidoAberto>(`PedidoAberto/Sabores`, sabores)
    }

    opcionais = (opcionais: OpcionaisPedidoAberto) => {
        return this.http.post<RespostaBase<ValorBaseNullable<Pedido>>, OpcionaisPedidoAberto>(`PedidoAberto/Opcionais`, opcionais)
    }

    podeFinalizar = (pedidoId: number) => {
        return this.http.get<RespostaBase<ValorBaseNullable<ValidacaoFechamento>>>(`PedidoAberto/${pedidoId}/PodeFinalizar`)
    }

    salvarFechamento = (fechamento: FechamentoPedido) => {
        return this.http.post<RespostaBase<ValorBaseNullable<Pedido>>, FechamentoPedido>(`PedidoAberto/Fechamento`, fechamento)
    }

    fechar = (fechamento: FechamentoPedido) => {
        return this.http.post<RespostaBase<ValorBaseNullable<RetornoFechamentoPedidoFinal>>, FechamentoPedido>(`PedidoAberto/FechamentoFinal`, fechamento)
    }

    obterListaPedidosPorCliente = (cliente: string, pagina: number) => {
        return this.http.get<RespostaBase<ListaPedidosPorCliente>>(`Pedidos/porcliente?cliente=${cliente}&pagina=${pagina}`)
    }

    pedirNovamente = (cliente: string, pedido: number) => {
        return this.http.get<RespostaBase<ValorBase<number>>>(`Pedidos/CopiaEmAberto?cliente=${cliente}&pedido=${pedido}`)
    }

}