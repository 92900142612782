import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuLCR from '../../components/NavMenuLCR/NavMenuLCR'
import BodyNav from '../../components/BodyNav/BodyNav'
import {Card} from '../../components/Card/Card'
import Field from '../../components/Field/Field'
import { ToastService } from '../../services/ToastService'
import { ClienteService } from '../../services/ClienteService'
import { Login } from '../../models/Login'
import StorageService from '../../services/StorageService'
import {PanelButtons} from '../../components/PanelButtons/PanelButtons'
import PanelButton from '../../components/PanelButton/PanelButton'
import { isMobile } from 'react-device-detect'

const Entrar: React.FC = () => {

  const history = useHistory()
  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [entrando, setEntrando] = useState(false)

  const toaster = new ToastService()
  const clienteService = new ClienteService()
  const storage = new StorageService()

  const voltar = () => {
      history.push(`/`)
  }

  const irParaCadastrar = () => {
      history.push(`/cadastrar`)
  }

  const irParaRecuperarSenha = () => {
      history.push(`/recuperar-senha`)
  }

  const entrar = () => {
    if (!email) {
      toaster.info('Informe o e-mail cadastrado!')
      return
    }

    if (!senha) {
      toaster.info('Informe a senha cadastrada!')
      return
    }

    setEntrando(true)

    const login = new Login()
    login.email = email
    login.senha = senha
    clienteService.autenticarLogin(login)
      .then(response => {
        const clienteId = response.data.resultado?.valor
        if (clienteId) {
          storage.armazenarSessaoCliente(clienteId, 0)
          setTimeout(() => history.push('/'), 500)
        } else {
          toaster.info('E-mail ou senha inválidos!')
        }
      })
      .finally(() => {
        setEntrando(false)
      })
  }

  window.scrollTo(0, 0)

  return (
    <Page>
      <FixedTop>
        <NavMenuLCR clickVoltar={voltar} clickAvancar={entrar} textCenter='Entrar' textRight='Entrar' loadingRight={entrando} />
      </FixedTop>
      <BodyNav>
        <Card>
            <Field type='email' label='E-mail' change={(value) => setEmail(value)} />
            <Field type='password' label='Senha' change={(value) => setSenha(value)} />
        </Card>
        <PanelButtons marginTop='4px'>
            <PanelButton text='Esqueci a senha' click={irParaRecuperarSenha} marginRight='1px' marginLeft={isMobile ? '2px' : '0px'} />
            <PanelButton text='Não tenho cadastro' click={irParaCadastrar} marginLeft='1px' marginRight={isMobile ? '2px' : '0px'} />
        </PanelButtons>
      </BodyNav>
    </Page>
  )
}
  
export default Entrar