import React from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import BarButton from '../../../components/BarButton/BarButton'

interface IBarButtonMenuProps {
    click?: () => void
}

const BarButtonMenu: React.FC<IBarButtonMenuProps> = ({
    click
}) => (
    <BarButton click={click} paddingLeft='5px' paddingRight='5px' marginLeft='5px' title='Menu' iconLeft={faBars} noBorder />
)

export default BarButtonMenu