export const mediaScreen = `
    width: 30%;
    
    @media screen and (max-width: 1700px) {
        width: 35%;
    }

    @media screen and (max-width: 1500px) {
        width: 40%;
    }

    @media screen and (max-width: 1300px) {
        width: 50%;
    }

    @media screen and (max-width: 1100px) {
        width: 60%;
    }

    @media screen and (max-width: 900px) {
        width: 80%;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
    }
`

export const mediaScreenLeft = `
    left: 35%;
    
    @media screen and (max-width: 1700px) {
        left: 32.5%;
    }

    @media screen and (max-width: 1500px) {
        left: 30%;
    }

    @media screen and (max-width: 1300px) {
        left: 25%;
    }

    @media screen and (max-width: 1100px) {
        left: 20%;
    }

    @media screen and (max-width: 900px) {
        left: 10%;
    }

    @media screen and (max-width: 700px) {
        left: 0%;
    }
`