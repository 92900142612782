import React from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom'
import Home from './pages/Home/Home'
import CardapioCategorias from './pages/CardapioCategorias/CardapioCategorias'
import FornecedoresPorCategoriaPorLocalizacao from './pages/FornecedoresPorCategoriaPorLocalizacao/FornecedoresPorCategoriaPorLocalizacao'
import CardapioProdutos from './pages/CardapioProdutos/CardapioProdutos'
import Carrinho from './pages/Carrinho/Carrinho'
import FechaPedido from './pages/FechaPedido/FechaPedido'
import Entrar from './pages/Entrar/Entrar'
import Cadastrar from './pages/Cadastrar/Cadastrar'
import RecuperarSenha from './pages/RecuperarSenha/RecuperarSenha'
import Menu from './pages/Menu/Menu'
import MeusDados from './pages/MeusDados/MeusDados'
import MeusPedidos from './pages/MeusPedidos/MeusPedidos'
import ResumoPedidoFechado from './pages/ResumoPedidoFechado/ResumoPedidoFechado'

const AppRoutes: React.FC = () => (
    <HashRouter>
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/home' exact component={Home} />
            <Route path='/entrar' exact component={Entrar} />
            <Route path='/cadastrar' exact component={Cadastrar} />
            <Route path='/recuperar-senha' exact component={RecuperarSenha} />
            <Route path='/menu' exact component={Menu} />
            <Route path='/meus-dados' exact component={MeusDados} />
            <Route path='/meus-pedidos' exact component={MeusPedidos} />
            <Route path='/resumo' exact component={ResumoPedidoFechado} />
            <Route path='/cardapio/:fornecedor/em/:municipio/:estado' exact component={CardapioCategorias} />
            <Route path='/carrinho/:fornecedor/em/:municipio/:estado' exact component={Carrinho} />
            <Route path='/fechar-pedido/:fornecedor/em/:municipio/:estado' exact component={FechaPedido} />
            <Route path='/:categoria/em/:municipio/:estado' exact component={FornecedoresPorCategoriaPorLocalizacao} />
            <Route path='/:categoria/de/:fornecedor/em/:municipio/:estado' exact component={CardapioProdutos} />
        </Switch>
    </HashRouter>
)

export default AppRoutes