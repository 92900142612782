import React from 'react'
import {Container, Texto} from './styles'
import Spinner from '../Spinner/Spinner'

interface IAguardeProps {
    texto?: string
}

const Aguarde: React.FC<IAguardeProps> = ({
    texto
}) => {

    return (
        <Container>
            <Spinner size='lg' />
            <Texto>{texto || 'Aguarde...'}</Texto>
        </Container>
    )
}

export default Aguarde