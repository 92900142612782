import { DadosSobreFornecedor } from '../models/DadosSobreFornecedor'
import { GrupoOpcoes } from '../models/GrupoOpcoes'
import { Pedido } from '../models/Pedido'
import {FornecedorService} from './FornecedorService'
import {PedidoService} from './PedidoService'
import StorageService from './StorageService'

export class CardapioServicePage {

    private fornecedorService = new FornecedorService()
    private pedidoService = new PedidoService()
    private storage = new StorageService()

    constructor(
        private setCarregandoFornecedor: (value: boolean) => void,
        private setCarregandoPedido: (value: boolean) => void,
        private setDadosFornecedor: (value: DadosSobreFornecedor) => void,
        private setPedido: (value?: Pedido) => void,
        private setEstadoIdentificador: (value: string) => void,
        private setMunicipioIdentificador: (value: string) => void,
        private setGruposOpcionais: (value: GrupoOpcoes[]) => void
    ) {
    }

    obterFornecedor = (fornecedor: string, estadoIdentificador?: string, municipioIdentificador?: string) => {
        this.fornecedorService
            .obterApenasDadosSobreFornecedor(fornecedor)
            .then(dados => {
                this.setCarregandoFornecedor(false)
                this.setDadosFornecedor(dados)
                if (!estadoIdentificador) {
                    this.setEstadoIdentificador(dados?.enderecos[0].estadoIdentificador || '')
                }
                if (!municipioIdentificador) {
                    this.setMunicipioIdentificador(dados?.enderecos[0].municipioIdentificador || '')
                }

                this.obterGruposOpcionais(dados.id)
                this.obterPedidoEmAberto(dados.id)
            })
    }

    obterGruposOpcionais = (fornecedorId: number) => {
        this.fornecedorService
            .obterApenasGruposOpcionaisPorFornecedor(fornecedorId)
            .then(dados => {
                this.setGruposOpcionais(dados)
            })
    }

    obterPedidoEmAberto = (fornecedorId: number) => {
        const sessao = this.storage.obterSessaoCliente()
        if (sessao?.clienteId || sessao?.sessaoClienteId) {
            this.setCarregandoPedido(true)
            this.pedidoService.obterPedidoAberto(fornecedorId, sessao?.clienteId, sessao?.sessaoClienteId)
                .then(response => {
                    this.setCarregandoPedido(false)
                    this.setPedido(response.data.resultado)
                })
                .finally(() => {
                    this.setCarregandoPedido(false)
                })
        } else {
            this.setCarregandoPedido(false)
        }
    }

}