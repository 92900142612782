import Swal from 'sweetalert2'

export class ToastService {
    private toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    success = (text: string) => {
        this.toast.fire({
            icon: 'success',
            title: text
        })
    }

    info = (text: string) => {
        this.toast.fire({
          icon: 'info',
          title: text
        })
    }

    error = (text: string) => {
        this.toast.fire({
          icon: 'error',
          title: text
        })
    }
}