import { useState } from 'react'
import {useQuery} from '../../../hooks/useQuery'
import { Localizacao } from '../../../models/Localizacao'

const useHomeState = () => {

    const query = useQuery()

    const tabCategorias = query.get('tab') === 'categorias'

    const [activeMenu, setActiveMenu] = useState(tabCategorias ? 'cat' : 'for')
    const [localizacao, setLocalizacao] = useState<Localizacao | null | undefined>()
    const [obtendoLocalizacao, setObtendoLocalizacao] = useState(true)
    const [pesquisaFornecedores, setPesquisaFornecedores]= useState(false)
    const [pesquisaCategorias, setPesquisaCategorias]= useState(false)
    const [pesquisaFornecedoresTexto, setPesquisaFornecedoresTexto]= useState('')
    const [pesquisaCategoriasTexto, setPesquisaCategoriasTexto]= useState('')

    return {
        tabCategorias,
        activeMenu, setActiveMenu,
        localizacao, setLocalizacao,
        obtendoLocalizacao, setObtendoLocalizacao,
        pesquisaFornecedores, setPesquisaFornecedores,
        pesquisaCategorias, setPesquisaCategorias,
        pesquisaFornecedoresTexto, setPesquisaFornecedoresTexto,
        pesquisaCategoriasTexto, setPesquisaCategoriasTexto
    }
}

export default useHomeState