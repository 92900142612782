import React from 'react'
import { FuncionamentoParaDadosSobreFornecedor } from '../../models/FuncionamentoParaDadosSobreFornecedor'
import {StaticText} from '../StaticText/StaticText'

interface ITextoFuncionamentoFornecedorProps {
    funcionamento?: FuncionamentoParaDadosSobreFornecedor | null
}

const TextoFuncionamentoFornecedor: React.FC<ITextoFuncionamentoFornecedorProps> = ({
    funcionamento
}) => {

    if (!funcionamento) return null

    return (
        <StaticText textColor={funcionamento.cor} textSize='0.7em'>
            {funcionamento.descricao}
        </StaticText>
    )
}
  
export default TextoFuncionamentoFornecedor