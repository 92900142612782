import React from 'react'
import { isMobile } from 'react-device-detect'
import { Body } from '../Body/Body'

interface IBodyNavTabProps {
    bottom?: string
}

const BodyNavTab: React.FC<IBodyNavTabProps> = ({
    children,
    bottom
}) => (
    <Body top={isMobile ? '95px' : '90px'} bottom={bottom}>
        {children}
    </Body>
)

export default BodyNavTab