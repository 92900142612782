import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import FixedBottom from '../../components/Fixed/FixedBottom'
import BodyNav from '../../components/BodyNav/BodyNav'
import CardCategoria from '../../components/CardCategoria/CardCategoria'
import { CardapioService } from '../../services/CardapioService'
import { OpcaoPorFornecedor } from '../../models/OpcaoPorFornecedor'
import { DadosSobreFornecedor, linhasParaObservacao } from '../../models/DadosSobreFornecedor'
import CardFornecedorBottom from '../../components/CardFornecedor/CardFornecedorBottom'
import { Pedido } from '../../models/Pedido'
import {CardapioServicePage} from '../../services/CardapioServicePage'
import Aguarde from '../../components/Aguarde/Aguarde'
import latinize from 'latinize'
import NavMenuCardapio from '../../components/NavMenuCardapio/NavMenuCardapio'

type CardapioParams = {
  fornecedor: string
  municipio: string
  estado: string
}

const CardapioCategorias: React.FC = () => {

  const history = useHistory()
  const {fornecedor,municipio,estado} = useParams<CardapioParams>()
  const [opcoes, setOpcoes] = useState<OpcaoPorFornecedor[]>([])
  const [dadosFornecedor, setDadosFornecedor] = useState<DadosSobreFornecedor>()
  const [estadoIdentificador, setEstadoIdentificador] = useState<string>(estado)
  const [municipioIdentificador, setMunicipioIdentificador] = useState<string>(municipio)
  const [pedido, setPedido] = useState<Pedido>()
  const [carregandoCardapio, setCarregandoCardapio] = useState(true)
  const [carregandoFornecedor, setCarregandoFornecedor] = useState(true)
  const [carregandoPedido, setCarregandoPedido] = useState(false)
  const [atualizacao, setAtualizacao] = useState(false)
  const [pesquisaTexto, setPesquisaTexto] = useState('')

  const servicePage = new CardapioServicePage(
    (value) => setCarregandoFornecedor(value),
    (value) => setCarregandoPedido(value),
    (value) => setDadosFornecedor(value),
    (value) => setPedido(value),
    (value) => setEstadoIdentificador(value),
    (value) => setMunicipioIdentificador(value),
    (value) => {}
  )

  //Encontrar uma forma de resolver esse worning:
  //Line 58:6:  React Hook useEffect has a missing dependency: 'servicePage'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
  //Talvez criar um Hook para CardapioServicePage em vez de uma simples classe
  useEffect(() => {
    const cardapioService = new CardapioService()
    cardapioService
      .obterApenasOpcoesPorFornecedor(fornecedor)
      .then(dados => {
        setCarregandoCardapio(false)
        setOpcoes(dados || [])
      })
    
      servicePage.obterFornecedor(fornecedor, estado, municipio)
  }, [fornecedor, municipio, estado])

  const voltar = () => {
      history.push('/home?tab=fornecedores')
  }

  const irParaCarrinho = () => {
    history.push(`/carrinho/${fornecedor}/em/${municipio}/${estado}`)
  }

  const body = () => {
    if (carregandoCardapio) {
      return <Aguarde texto='Obtendo cardápio...' />
    }
    
    const latfiltro = latinize(pesquisaTexto || '').toLocaleLowerCase()
    const lista = pesquisaTexto ? opcoes.filter(o => latinize(o.categoria).toLocaleLowerCase().indexOf(latfiltro) >= 0) : opcoes

    return lista.map((opcao) =>
      <CardCategoria key={`categoria-${opcao.identificador}`}
        opcaoIdentificador={opcao.identificador}
        opcaoCategoria={opcao.categoria}
        opcaoOpcoes={opcao.opcoes}
        municipioIdentificador={municipio}
        estadoIdentificador={estado}
        fornecedorIdentificador={fornecedor}
      />
    )
  }

  if (!atualizacao) {
    setAtualizacao(true)
    window.scrollTo(0, 0)
  }

  return (
    <Page>
      <FixedTop>
        <NavMenuCardapio
          carregandoPedido={carregandoPedido}
          pedido={pedido}
          pesquisaTexto={pesquisaTexto}
          setPesquisaTexto={(texto) => setPesquisaTexto(texto)}
          clickVoltar={voltar}
          clickAvancar={irParaCarrinho}
        />
      </FixedTop>
      <BodyNav extraBottomLines={linhasParaObservacao(dadosFornecedor)}>
        {body()}
      </BodyNav>
      {carregandoFornecedor ? null : 
      <FixedBottom>
        <CardFornecedorBottom
          dadosFornecedor={dadosFornecedor}
          municipioIdentificador={municipioIdentificador}
          estadoIdentificador={estadoIdentificador}
        />
      </FixedBottom>
      }
    </Page>
  )
}
  
export default CardapioCategorias