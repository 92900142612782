import React, {useState} from 'react'
import { PrecoItemPorFornecedor } from '../../models/PrecoItemPorFornecedor'
import { OpcaoItemPorFornecedor } from '../../models/OpcaoItemPorFornecedor'
import { ItemPedidoAberto } from '../../models/ItemPedidoAberto'
import {Card} from '../Card/Card'
import {Container} from '../Container/Container'
import CardButton from '../CardButton/CardButton'
import Precos from './components/Precos'
import Opcoes from './components/Opcoes'
import Adicionais from './components/Adicionais'
import Detalhes from './components/Detalhes'
import Observacoes from './components/Observacoes'
import CategoriaNome from './components/CategoriaNome'
import ProdutoNome from './components/ProdutoNome'
import ModalAdicionais from './components/ModalAdicionais'
import ModalSabores from './components/ModalSabores'
import {PedidoService} from '../../services/PedidoService'
import { TipoOrigem } from '../../enums/TipoOrigem.enum'
import { Pedido } from '../../models/Pedido'
import StorageService from '../../services/StorageService'
import { PedidoItem } from '../../models/PedidoItem'
import { Personalizacao } from '../../models/Personalizacao'
import {ToastService} from '../../services/ToastService'
import { StaticText } from '../StaticText/StaticText'
import { ItemAdicionalPorFornecedor } from '../../models/ItemAdicionalPorFornecedor'
import { PedidoAdicional } from '../../models/PedidoAdicional'
import {AdicionalPedidoAberto} from '../../models/AdicionalPedidoAberto'
import {SaboresPedidoAberto} from '../../models/SaboresPedidoAberto'
import { CardapioService } from '../../services/CardapioService'
import { SaborPorFornecedor } from '../../models/SaborPorFornecedor'
import { SaborPedido } from '../../models/SaborPedido'
import { GrupoOpcoes } from '../../models/GrupoOpcoes'
import ModalOpcionaisGrupo from './components/ModalOpcionaisGrupo'
import { OpcionaisPedidoAberto } from '../../models/OpcionaisPedidoAberto'
import { PedidoItemOpcao } from '../../models/PedidoItemOpcao'
import { GrupoOpcoesItemCardapio } from '../../models/GrupoOpcoesItemCardapio'

interface ICardProdutoProps {
    fornecedorId: number
    produtoId: number
    produtoNome: string
    produtoDetalhes?: string
    produtoObservacoes?: string
    categoriaNome: string
    precos?: PrecoItemPorFornecedor[]
    opcoes?: OpcaoItemPorFornecedor[]
    noBorder?: boolean
    aoAlterarPedidoAberto?: (pedido?: Pedido) => void
    itens?: PedidoItem[]
    pageCarrinho?: boolean
    mensagensValidacao?: string[]
    adicionaisFornecedor?: ItemAdicionalPorFornecedor[]
    multisabores: boolean
    quantidadeMaximaSabores: number
    gruposOpcoesFornecedor?: GrupoOpcoes[]
    gruposOpcoes?: GrupoOpcoesItemCardapio[]
}

const CardProduto: React.FC<ICardProdutoProps> = ({
    fornecedorId,
    produtoId,
    produtoNome,
    produtoDetalhes,
    produtoObservacoes,
    categoriaNome,
    precos,
    opcoes,
    noBorder,
    aoAlterarPedidoAberto,
    itens,
    pageCarrinho,
    mensagensValidacao,
    adicionaisFornecedor,
    multisabores,
    quantidadeMaximaSabores,
    gruposOpcoesFornecedor,
    gruposOpcoes
}) => {

    const [ modalAdicionais, setModalAdicionais ] = useState(false)
    const [ modalSabores, setModalSabores ] = useState(false)
    const [ modalSaboresPrecoId, setModalSaboresPrecoId ] = useState(0)
    const [ modalOpcionaisGrupo, setModalOpcionaisGrupo ] = useState(false)
    const [ contador, setContador ] = useState(0)
    const [ aumentandoPrecoId, setAumentandoPrecoId ] = useState(0)
    const [ diminuindoPrecoId, setDiminundoPrecoId ] = useState(0)
    const [ sabores, setSabores ] = useState<SaborPorFornecedor[]>([])
    const [ opcionaisGrupoModal, setOpcionaisGrupoModal ] = useState<OpcaoItemPorFornecedor[]>([])
    const [ grupoOpcaoModal, setGrupoOpcaoModal ] = useState<GrupoOpcoes>()

    const pedidoService = new PedidoService()
    const storage = new StorageService()
    const toast = new ToastService()
    const cardapioService = new CardapioService()

    const categoriaId = itens?.length ? itens[0].categoriaId : 0
    const adicionaisFornecedorCategoria = adicionaisFornecedor?.filter(af => af.adicionais.some(a => a.categoriaId === categoriaId)) || []

    const criarItemPedidoAberto = (precoId: number) => {
        const sessaoCliente = storage.obterSessaoCliente()
        const itemPedidoAberto = new ItemPedidoAberto()
        itemPedidoAberto.pedidoItemId = pageCarrinho ? itens?.find(i => i.itemCardapioId === produtoId)?.pedidoItemId : null
        itemPedidoAberto.fornecedorId = fornecedorId
        itemPedidoAberto.itemCardapioId = produtoId
        itemPedidoAberto.precoId = precoId
        itemPedidoAberto.clienteId = sessaoCliente?.clienteId
        itemPedidoAberto.sessaoClienteId = sessaoCliente?.sessaoClienteId
        itemPedidoAberto.tipoOrigem = TipoOrigem.Site
        itemPedidoAberto.quantidade = 1
        return itemPedidoAberto
    }

    const clickAumentarProduto = (precoId: number) => {
        setAumentandoPrecoId(precoId)
        const itemPedidoAberto = criarItemPedidoAberto(precoId)
        pedidoService.adicionarItem(itemPedidoAberto)
            .then(response => {
                const pedido = response?.data?.resultado?.valor
                if (!pedido) {
                    if (response?.data?.mensagens?.length) {
                        toast.info(response?.data?.mensagens[0] || 'Ops! Algo deu errado. Tente novamente!')
                    }
                    return;
                }
                if (!itemPedidoAberto.clienteId) {
                    storage.armazenarSessaoClienteId(pedido.sessaoClienteId)
                }
                if (aoAlterarPedidoAberto) {
                    aoAlterarPedidoAberto(pedido)
                }
            })
            .finally(() => {
                setAumentandoPrecoId(0)
            })
    }

    const clickDiminuirProduto = (precoId: number) => {
        setDiminundoPrecoId(precoId)
        const itemPedidoAberto = criarItemPedidoAberto(precoId)
        pedidoService.removerItem(itemPedidoAberto)
            .then(response => {
                const pedido = response?.data?.resultado?.valor
                if (!pedido) {
                    if (response?.data?.mensagens?.length) {
                        toast.info(response?.data?.mensagens[0] || 'Ops! Algo deu errado. Tente novamente!')
                    }
                    return;
                }
                if (!itemPedidoAberto.clienteId) {
                    storage.armazenarSessaoClienteId(pedido.sessaoClienteId)
                }
                if (aoAlterarPedidoAberto) {
                    aoAlterarPedidoAberto(pedido)
                }
            })
            .finally(() => {
                setDiminundoPrecoId(0)
            })
    }

    const clickPersonalizar = (opcaoId: number, quantidade: number) => {
        const sessaoCliente = storage.obterSessaoCliente()
        const personalizacao = new Personalizacao()
        personalizacao.pedidoItemId = pageCarrinho ? itens?.find(i => i.itemCardapioId === produtoId)?.pedidoItemId || 0 : 0
        personalizacao.opcaoId = opcaoId
        personalizacao.quantidade = quantidade
        personalizacao.itemCardapioId = produtoId
        personalizacao.clienteId = sessaoCliente?.clienteId

        pedidoService.personalizar(personalizacao)
            .then(response => {
                const pedido = response?.data?.resultado?.valor
                const mensagem = response?.data?.mensagens?.length ? response.data.mensagens[0] : null
                if (mensagem) {
                    toast.info(mensagem)
                }
                if (!pedido) {
                    setContador(contador+1)
                    return;
                }
                if (!personalizacao.clienteId) {
                    storage.armazenarSessaoClienteId(pedido.sessaoClienteId)
                }
                if (aoAlterarPedidoAberto) {
                    aoAlterarPedidoAberto(pedido)
                }
            })
    }

    const clickAdicionais = (novoAdicionaisPedido: PedidoAdicional[]) => {
        const sessaoCliente = storage.obterSessaoCliente()
        const adicionalPedidoAberto = new AdicionalPedidoAberto();
        adicionalPedidoAberto.pedidoItemId = pageCarrinho ? itens?.find(i => i.itemCardapioId === produtoId)?.pedidoItemId || 0 : 0
        adicionalPedidoAberto.adicionais = novoAdicionaisPedido
        adicionalPedidoAberto.clienteId = sessaoCliente?.clienteId
        pedidoService.pedidoAdicional(adicionalPedidoAberto)
            .then(response => {
                if (!response.data.sucesso) {
                    if (response.data.mensagens.length) {
                        toast.info(response.data.mensagens[0])
                    }
                }
                const pedido = response?.data?.resultado?.valor
                if (!pedido) {
                    return;
                }
                setModalAdicionais(false) 
                if (!adicionalPedidoAberto.clienteId) {
                    storage.armazenarSessaoClienteId(pedido.sessaoClienteId)        
                }
                if (aoAlterarPedidoAberto) {
                    aoAlterarPedidoAberto(pedido)
                }
            })
            .finally(() => {
                setModalAdicionais(false)   
            })
    }

    const clickVerEscolherSabores = (precoId: number, agrupador: string) => {
        cardapioService.obterSaboresPorFornecedorEAgrupador(fornecedorId, agrupador)
            .then(response => {
                setSabores(response.data.resultado || [])
                setModalSaboresPrecoId(precoId)
                setModalSabores(true)
            })
    }

    const clickVerEscolherOpcoesGrupo = (grupo: GrupoOpcoes) => {
        const opcionaisGrupo = opcoes?.filter(opcao => !opcao.excluido && opcao.grupoId === grupo.grupoOpcoesId) || []
        setOpcionaisGrupoModal(opcionaisGrupo)
        setGrupoOpcaoModal(grupo)
        setModalOpcionaisGrupo(true)
    }

    const salvarSaboresPedido = (saboresSelecionados: SaborPorFornecedor[]) => {
        if (!pageCarrinho) {
            setModalSabores(false) 
            return
        }
        const sessaoCliente = storage.obterSessaoCliente()
        const saboresPedidoAberto = new SaboresPedidoAberto();
        saboresPedidoAberto.pedidoItemId = pageCarrinho ? itens?.find(i => i.itemCardapioId === produtoId)?.pedidoItemId || 0 : 0
        saboresPedidoAberto.clienteId = sessaoCliente?.clienteId
        saboresPedidoAberto.sabores = saboresSelecionados.map(saborSelecionado => {
            const saborPedido = new SaborPedido()
            saborPedido.itemCardapioId = saborSelecionado.id
            saborPedido.precoItemCardapioId = saborSelecionado.preco?.id || 0
            return saborPedido
        })
        pedidoService.sabores(saboresPedidoAberto)
            .then(response => {
                if (!response.data.sucesso) {
                    if (response.data.mensagens?.length) {
                        toast.info(response.data.mensagens[0])
                    }
                }
                const pedido = response?.data?.resultado?.valor
                if (!pedido) {
                    return;
                }
                if (!saboresPedidoAberto.clienteId) {
                    storage.armazenarSessaoClienteId(pedido.sessaoClienteId)        
                }
                if (aoAlterarPedidoAberto) {
                    aoAlterarPedidoAberto(pedido)
                }
                setModalSabores(false) 
            })
            .finally(() => {
                setModalSabores(false)   
            })
    }

    const salvarOpcionaisPedido = (opcionaisSelecionados: OpcaoItemPorFornecedor[]) => {
        if (!pageCarrinho) {
            setModalOpcionaisGrupo(false) 
            return
        }
        const sessaoCliente = storage.obterSessaoCliente()
        const opcionaisPedidoAberto = new OpcionaisPedidoAberto();
        opcionaisPedidoAberto.pedidoItemId = pageCarrinho ? itens?.find(i => i.itemCardapioId === produtoId)?.pedidoItemId || 0 : 0
        opcionaisPedidoAberto.clienteId = sessaoCliente?.clienteId
        opcionaisPedidoAberto.opcionais = opcionaisSelecionados.map(opcionalSelecionado => {
            const opcionalPedido = new PedidoItemOpcao()
            opcionalPedido.grupoOpcoesId = opcionalSelecionado.grupoId
            opcionalPedido.opcaoItemCardapioId = opcionalSelecionado.id
            opcionalPedido.itemCardapioIdOpcao = opcionalSelecionado.itemCardapioIdOpcao
            opcionalPedido.quantidade = 1
            return opcionalPedido
        })
        pedidoService.opcionais(opcionaisPedidoAberto)
            .then(response => {
                if (!response.data.sucesso) {
                    if (response.data.mensagens?.length) {
                        toast.info(response.data.mensagens[0])
                    }
                }
                const pedido = response?.data?.resultado?.valor
                if (!pedido) {
                    return;
                }
                if (!opcionaisPedidoAberto.clienteId) {
                    storage.armazenarSessaoClienteId(pedido.sessaoClienteId)        
                }
                if (aoAlterarPedidoAberto) {
                    aoAlterarPedidoAberto(pedido)
                }
                setModalOpcionaisGrupo(false) 
            })
            .finally(() => {
                setModalOpcionaisGrupo(false)   
            })
    }

    if (modalAdicionais) {
        const adicionaisPedido = pageCarrinho && itens?.length ? itens[0].adicionais : []
        return (
            <ModalAdicionais 
                visible={modalAdicionais}
                fornecedorId={fornecedorId} 
                adicionaisFornecedor={adicionaisFornecedorCategoria}
                adicionaisPedido={adicionaisPedido}
                close={(novoAdicionaisPedido) => clickAdicionais(novoAdicionaisPedido)} 
            />
        )
    }

    if (modalSabores) {
        const saboresPedido = (pageCarrinho && itens?.length ? itens[0].sabores : []).map(saborPedido => {
            const sabor = new SaborPorFornecedor()
            sabor.id = saborPedido.itemCardapioId
            sabor.nome = saborPedido.itemCardapioNome
            sabor.detalhes = saborPedido.itemCardapioDetalhes
            sabor.preco = new PrecoItemPorFornecedor()
            sabor.preco.id = saborPedido.precoItemCardapioId
            sabor.preco.descricao = saborPedido.precoItemCardapioNome
            sabor.preco.preco = saborPedido.preco
            sabor.preco.estrategiaPreco = saborPedido.precoItemCardapioEstrategiaPreco
            sabor.preco.agrupadorMultisabores = saborPedido.precoItemCardapioAgrupadorMultisabores
            return sabor
        })
        const ocultarPrecoSabores = precos?.find(p => p.id === modalSaboresPrecoId)?.ocultarPrecoSabores || false
        return (
            <ModalSabores 
                visible={modalSabores}
                categoriaNome={categoriaNome} 
                produtoId={produtoId}
                saboresDisponiveis={sabores}
                saboresPedido={saboresPedido}
                close={(saboresSelecionados) => salvarSaboresPedido(saboresSelecionados)} 
                escolher={pageCarrinho === true}
                quantidadeMaxima={quantidadeMaximaSabores}
                ocultarPrecoSabores={ocultarPrecoSabores}
            />
        )
    }

    if (modalOpcionaisGrupo) {
        const opcionaisPedido = (pageCarrinho && itens?.length ? itens[0].opcoes : []).map(opcionalPedido => {
            const opcional = new OpcaoItemPorFornecedor()
            opcional.grupoId = opcionalPedido.grupoOpcoesId
            opcional.id = opcionalPedido.opcaoItemCardapioId
            opcional.itemCardapioIdOpcao = opcionalPedido.itemCardapioIdOpcao
            opcional.nome = opcionalPedido.itemCardapioNomeOpcao
            opcional.quantidadeMinima = opcionalPedido.opcaoItemCardapio?.quantidadeMinima || 0
            opcional.quantidadeInicial = opcionalPedido.opcaoItemCardapio?.quantidadeInicial || 0
            opcional.quantidadeMaxima = opcionalPedido.opcaoItemCardapio?.quantidadeMaxima || 0
            return opcional
        })
        const quantidadeMaxima = gruposOpcoes?.find(g => g.grupoOpcoesId === grupoOpcaoModal?.grupoOpcoesId)?.quantidadeMaxima || 0
        return (
            <ModalOpcionaisGrupo
                visible={modalOpcionaisGrupo}
                opcionaisGrupo={opcionaisGrupoModal}
                opcionaisPedido={opcionaisPedido || []}
                grupoId={grupoOpcaoModal?.grupoOpcoesId}
                grupoNome={grupoOpcaoModal?.nome}                
                close={(opcionaisSelecionados) => salvarOpcionaisPedido(opcionaisSelecionados)} 
                escolher={pageCarrinho === true}
                quantidadeMaxima={quantidadeMaxima}
            />
        )
    }

    const renderMensagens = () => {
        if (!mensagensValidacao?.length) return null

        return (
            <Container columnDirection paddingBottom='8px' marginBottom='5px' borderBottom='1px solid var(--color-gray-4)'>
                {mensagensValidacao.map((mensagem, mensagemIndex) => 
                    <StaticText key={`mensagem-${mensagemIndex}`} backgroundColor='#f8dea5' 
                        textColor='#000' textSize='0.8em' bold borderRadius='4px' padding='5px'
                        marginBottom='1px'
                    >
                        {mensagem}
                    </StaticText>
                )}
            </Container>
        )
    }

    const opcoesPedido = pageCarrinho && itens?.length ? itens[0].opcoes : null
    const borderContainer = mensagensValidacao?.length ? '1px solid red' : 'none'

    const adicionaisPedido = pageCarrinho && itens?.length ? itens[0].adicionais : []
    const adicionais = pageCarrinho && adicionaisFornecedorCategoria?.length ? ( <Adicionais adicionaisPedido={adicionaisPedido} /> ) : null
    const botaoAdicionais = pageCarrinho && adicionaisFornecedorCategoria?.length ? (
        <Container paddingTop='10px' marginTop='5px' borderTop='1px solid var(--color-gray-4)'>
            <CardButton text='Adicionais' click={() => setModalAdicionais(true)} />
        </Container>
    ) : null
    
    return (
        <Card noBorder={noBorder}>
            <Container padding='10px' columnDirection border={borderContainer}>
                <Container columnDirection>
                    {renderMensagens()}
                    <CategoriaNome texto={categoriaNome} />
                    <ProdutoNome texto={produtoNome} />
                    <Detalhes texto={produtoDetalhes} />
                    <Observacoes texto={produtoObservacoes} />
                    <Container columnDirection marginTop='5px'>
                        <Opcoes
                            contador={contador}
                            opcoes={opcoes}
                            opcoesPedido={opcoesPedido}
                            pageCarrinho={pageCarrinho}
                            personalizar={(opcaoId, quantidade) => clickPersonalizar(opcaoId, quantidade)}
                            gruposOpcoesFornecedor={gruposOpcoesFornecedor}
                            clickVerOpcoesGrupo={(grupo) => clickVerEscolherOpcoesGrupo(grupo)}
                            clickEscolherOpcoesGrupo={(grupo) => clickVerEscolherOpcoesGrupo(grupo)}
                        />
                        <Precos 
                            precos={precos}
                            itens={itens}
                            fornecedorId={fornecedorId} 
                            clickAumentar={(precoId) => clickAumentarProduto(precoId)}
                            clickDiminuir={(precoId) => clickDiminuirProduto(precoId)}
                            estaAumentandoPrecoId={aumentandoPrecoId}
                            estaDiminuindoPrecoId={diminuindoPrecoId}
                            produtoMultisabores={multisabores}
                            categoriaProduto={categoriaNome}
                            clickVerSabores={(precoId, agrupador) => clickVerEscolherSabores(precoId, agrupador)}
                            clickEscolherSabores={(precoId, agrupador) => clickVerEscolherSabores(precoId, agrupador)}
                            pageCarrinho={pageCarrinho}
                        />
                    </Container>
                </Container>
                {botaoAdicionais}
                {adicionais}
            </Container>
        </Card>
    )
}
  
export default CardProduto