import React from 'react'
import {Container} from '../../Container/Container'
import {StaticText} from '../../StaticText/StaticText'
import {OpcaoItemPorFornecedor} from '../../../models/OpcaoItemPorFornecedor'
import CardOpcao from '../../CardOpcao/CardOpcao'
import { PedidoItemOpcao } from '../../../models/PedidoItemOpcao'
import { GrupoOpcoes } from '../../../models/GrupoOpcoes'
import CardLink from '../../CardLink/CardLink'

interface IOpcoesProps {
    opcoes?: OpcaoItemPorFornecedor[]
    opcoesPedido?: PedidoItemOpcao[] | null
    pageCarrinho?: boolean
    personalizar?: (opcaoId: number, quantidade: number) => void
    contador?: number
    gruposOpcoesFornecedor?: GrupoOpcoes[]
    clickVerOpcoesGrupo?: (grupo: GrupoOpcoes) => void
    clickEscolherOpcoesGrupo?: (grupo: GrupoOpcoes) => void
}

const Opcoes: React.FC<IOpcoesProps> = ({
    opcoes,
    opcoesPedido,
    pageCarrinho,
    personalizar,
    contador,
    gruposOpcoesFornecedor,
    clickVerOpcoesGrupo,
    clickEscolherOpcoesGrupo
}) => {

    if (!opcoes || opcoes.length === 0) return null

    const opcoesValidas = opcoes.filter(o => !o.excluido)

    if (!opcoesValidas || opcoesValidas.length === 0) return null

    const renderOpcoes = () => {
        return opcoesValidas.filter(opcao => !opcao.grupoId).map((opcao) => {
            const opcaoPedido = opcoesPedido?.length
                ? opcoesPedido.find(op => op.opcaoItemCardapioId === opcao.id)
                : null
            return (
                <CardOpcao
                    key={`opcao-${opcao.id}-${contador}`}
                    opcao={opcao}
                    opcaoPedido={opcaoPedido}
                    pageCarrinho={pageCarrinho}
                    personalizar={(quantidade) => personalizar ? personalizar(opcao.id, quantidade) : null}
                />
            )
        })
    }

    const renderOpcoesPedidasPorGrupo = (grupoId?: number) => {
        return opcoesValidas.filter(opcao => opcao.grupoId === grupoId).map((opcao) => {
            const opcaoPedido = opcoesPedido?.length
                ? opcoesPedido.find(op => op.opcaoItemCardapioId === opcao.id)
                : null
            if (!opcaoPedido) return null
            return (
                <CardOpcao
                    key={`opcao-${opcao.id}-${contador}`}
                    opcao={opcao}
                    opcaoPedido={opcaoPedido}
                    pageCarrinho={false}
                    personalizar={(quantidade) => null}
                />
            )
        })
    }

    const renderGruposOpcoes = () => {
        if (!gruposOpcoesFornecedor || gruposOpcoesFornecedor.length === 0) return null

        const gruposValidos = gruposOpcoesFornecedor.filter(grupo => 
            opcoesValidas.filter(opcao => opcao.grupoId === grupo.grupoOpcoesId).length > 0);

        return gruposValidos.map(grupo => {

            if (pageCarrinho) {
                return (
                    <Container columnDirection key={`grupo-${grupo.grupoOpcoesId}`}>
                        <CardLink text={`Escolher ${grupo.nome}`} textSize={'0.8em'} textAlign='left' 
                            click={() => clickEscolherOpcoesGrupo ? clickEscolherOpcoesGrupo(grupo) : null}
                        />
                        <Container columnDirection paddingLeft='5px'>
                            {renderOpcoesPedidasPorGrupo(grupo.grupoOpcoesId)}
                        </Container>
                    </Container>
                )
            }

            return (
                <Container columnDirection key={`grupo-${grupo.grupoOpcoesId}`}>
                    <CardLink text={`Ver ${grupo.nome}`} textSize={'0.8em'} textAlign='left'
                        click={() => clickVerOpcoesGrupo ? clickVerOpcoesGrupo(grupo) : null}
                    />
                </Container>
            )
        })
    }

    return (
        <Container borderTop='1px solid var(--color-gray-4)' paddingTop='5px' paddingBottom='5px' columnDirection>
            <StaticText textSize='0.9em' textColor='#000' marginBottom='3px' bold>
                Opcionais:
            </StaticText>
            <Container columnDirection paddingLeft={pageCarrinho ? '0px' : '0px'}>
                {renderOpcoes()}
            </Container>
            {renderGruposOpcoes()}
        </Container>
    )
}
  
export default Opcoes