import React from 'react'
import {Button} from './styles'

interface ICardLinkProps {
    text?: string
    textColor?: string
    textSize?: string
    textAlign?: string
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
    click?: () => void
    loading?: boolean
}

const CardLink: React.FC<ICardLinkProps> = ({
    text, textColor, textSize, textAlign,
    marginTop, marginBottom, marginLeft, marginRight,
    click,
    loading
}) => {

    const onClick = () => {
        if (loading || !click) return

        click()
    }

    return (
        <Button onClick={onClick} 
            marginTop={marginTop} marginBottom={marginBottom} marginLeft={marginLeft} marginRight={marginRight}
            textColor={textColor} textAlign={textAlign} textSize={textSize}
        >
            {text}
        </Button>
    )
}
  
export default CardLink