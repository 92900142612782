import styled from 'styled-components'
import {mediaScreen} from '../../styles'

export const FixedContainerTop = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--color-body);
    position: fixed;
    top: 0px;
    align-items: stretch;

    ${mediaScreen}
`

export const FixedContainerBottom = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--color-body);
    position: fixed;
    bottom: 0px;
    align-items: stretch;
    
    ${mediaScreen}
`