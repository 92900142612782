import {TipoEntrega} from '../enums/TipoEntrega.enum'
import {Area} from '../enums/Area.enum'

export class FechamentoPedido {
    pedidoId: number = 0
    tipoEntrega: TipoEntrega = TipoEntrega.DeliveryOuRetirarNoLocal
    taxaEntrega: number = 0
    endereco: string = ''
    enderecoLogradouro: string = ''
    enderecoEstado: string = ''
    enderecoMunicipio: string = ''
    pagamento: number[] = []
    observacao: string = ''
    identificacao: string = ''
    area: Area = Area.NaoInformado
}