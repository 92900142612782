import React from 'react'
import { Container } from '../../Container/Container'
import {StaticText} from '../../StaticText/StaticText'
import Detalhes from './Detalhes'
import { PrecoItemPorFornecedor } from '../../../models/PrecoItemPorFornecedor'
import Checkbox from '../../Checkbox/Checkbox'
import { currencyFormat } from '../../../services/CurrencyService'
import CategoriaNome from './CategoriaNome'
import {isMobile} from 'react-device-detect'

interface ICardSaborProps {
    nome: string
    detalhes?: string
    preco?: PrecoItemPorFornecedor | null
    categoriaNome?: string
    escolher?: boolean
    aoEscolher: () => void
    selecionado?: boolean
    ocultarPrecoSabores?: boolean
}

const CardSabor: React.FC<ICardSaborProps> = ({
    nome,
    detalhes,
    preco,
    categoriaNome,
    escolher,
    aoEscolher,
    selecionado,
    ocultarPrecoSabores
}) => {

    const checkBoxRender = escolher ? (
        <Container columnDirection flexSize={isMobile ? '0.17' : '0.09'}>
            <Checkbox size={27} click={() => aoEscolher ? aoEscolher() : null} selected={selecionado} />
        </Container>
    ) : null

    const precoRender = !ocultarPrecoSabores ? (
        <Container flexSize={isMobile ? '0.25' : '0.20'} justifyContent='end' marginLeft={isMobile ? '5px' : '0px'}>
            <StaticText textSize='1em' bold textColor='var(--color-green)' textAlign='right'>
                {currencyFormat(preco?.preco || 0, 'R$ ')}
            </StaticText>
        </Container>
    ) : null

    return (
        <Container borderBottom='1px solid var(--color-gray-4)' padding='10px' center>
            {checkBoxRender}
            <Container columnDirection flexSize={'1'} clickable={escolher} onClick={() => escolher ? aoEscolher() : null}>
                <CategoriaNome texto={categoriaNome + ' ' + preco?.descricao} />
                <StaticText bold>{nome}</StaticText>
                <Detalhes texto={detalhes} />
            </Container>
            {precoRender}
        </Container>
    )
}
  
export default CardSabor