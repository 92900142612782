import React from 'react'
import { isMobile } from 'react-device-detect'
import NavMenu from '../../../components/NavMenu/NavMenu'
import PlateloSideBar from '../../../components/PlateloSideBar/PlateloSideBar'
import {SideBar} from '../../../components/SideBar/SideBar'
import BarButtonEntrar from './BarButtonEntrar'
import BarButtonMenu from './BarButtonMenu'
import BarButtonPesquisar from '../../../components/BarButtonPesquisar/BarButtonPesquisar'

interface IHomeNavMenuProps {
    entrar: boolean
    menu: boolean
    pesquisar: boolean
    ocultarPesquisar?: boolean
    pesquisaTexto?: string
    clickEntrar: () => void
    clickMenu: () => void
    clickPesquisar: (pesquisar: boolean) => void
    aoPesquisar?: (texto: string) => void
}

const HomeNavMenu: React.FC<IHomeNavMenuProps> = ({
    entrar,
    menu,
    pesquisar,
    ocultarPesquisar,
    pesquisaTexto,
    clickEntrar,
    clickMenu,
    clickPesquisar,
    aoPesquisar
}) => {

  const barButtonEntrar = entrar && !pesquisar ? <BarButtonEntrar click={clickEntrar} /> : null
  const barButtonPesquisar = ocultarPesquisar ? null :
    <BarButtonPesquisar 
      click={(pesquisar) => clickPesquisar(pesquisar)} 
      pesquisar={pesquisar} 
      pesquisaTexto={pesquisaTexto} 
      aoPesquisar={(texto) => aoPesquisar ? aoPesquisar(texto) : null}
      maxWidth='120px'
    />
  const barButtonMenu = menu && !pesquisar ? <BarButtonMenu click={clickMenu} /> : null

  return (
      <NavMenu>
        <PlateloSideBar />
        <SideBar position='right' top={isMobile ? '8px' : '5px'}>
          {barButtonPesquisar}
          {barButtonEntrar}
          {barButtonMenu}
        </SideBar>
      </NavMenu>
  )
}

export default HomeNavMenu