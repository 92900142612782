import styled from 'styled-components'

interface ICheckBoxProps {
    size?: string
}

export const SelectedButton = styled.div<ICheckBoxProps>`
    border: 2px solid #fff;
    width: ${props => props.size || '14px'};
    height: ${props => props.size || '14px'};
    border-radius: 5px;
    background-color: var(--color-blue-1);
`

export const Button = styled.div<ICheckBoxProps>`
    border: 2px solid var(--color-blue-5);
    width: ${props => props.size || '18px'};
    height: ${props => props.size || '18px'};
    border-radius: 5px;
`