import latinize from 'latinize';
import React, { useState } from 'react'
import { Container } from '../../../components/Container/Container'
import Modal from '../../../components/Modal/Modal'
import { StaticText } from '../../../components/StaticText/StaticText'
import { Municipio } from '../../../models/Municipio'
import {MunicipioService} from '../../../services/MunicipioService'

interface IModalMunicipiosProps {
    visible: boolean
    close: () => void
    estado: string
    select?: (municipio: Municipio) => void
}

const ModalMunicipios: React.FC<IModalMunicipiosProps> = ({
    visible,
    close,
    estado,
    select
}) => {

    const [municipios, setMunicipios] = useState<Municipio[]>([]);
    const [pesquisaTexto, setPesquisaTexto] = useState<string>('');

    React.useEffect(() => {
        const municipioService = new MunicipioService()
        municipioService.obterApenasMunicipiosPorUf(estado)
            .then((response) => {
                setMunicipios(response || [])
            })
    }, [])

    const aoFechar = () => {
        close()
    }

    const aoSelecionarMunicipio = (municipio: Municipio) => {
        if (!select) return
        select(municipio)
        close()
    }

    const renderBody = () => {
        if (!municipios || municipios.length <= 0) return null

        const latfiltro = latinize(pesquisaTexto || '').toLocaleLowerCase()
        const lista = pesquisaTexto ? municipios.filter(o => latinize(o.nome).toLocaleLowerCase().indexOf(latfiltro) >= 0) : municipios

        return lista.map(municipio => {
            return (
                <Container key={`muncipio-${municipio.municipioId}`} borderBottom='1px solid var(--color-gray-4)' padding='10px' clickable onClick={() => aoSelecionarMunicipio(municipio)}>
                    <StaticText>{municipio.nome}</StaticText>
                </Container>
            )
        })
    }

    return (
        <Modal
            visible={visible}
            title={'Municípios/Distritos'}
            onClose={aoFechar}
            onFilter={(value) => setPesquisaTexto(value)}
        >
            {renderBody()}
        </Modal>
    )
}
  
export default ModalMunicipios