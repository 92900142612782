import React from 'react'
import {StaticText} from '../../StaticText/StaticText'

interface IObservacoesProps {
    texto?: string
}

const Observacoes: React.FC<IObservacoesProps> = ({
    texto
}) => {

    if (!texto) return null

    return (
        <StaticText textSize='0.8em' textColor='var(--color-gray-2)'>
            {texto}
        </StaticText>
    )
}
  
export default Observacoes