import React from 'react'
import {Container} from '../Container/Container'
import {StaticText} from '../StaticText/StaticText'
import PainelQuantidade from '../PainelQuantidade/PainelQuantidade'
import {PrecoItemPorFornecedor} from '../../models/PrecoItemPorFornecedor'
import {currencyFormat} from '../../services/CurrencyService'
import { PedidoItem } from '../../models/PedidoItem'
import CardLink from '../CardLink/CardLink'
import { EstrategiaPreco } from '../../enums/EstrategiaPreco.enum'

interface ICardPrecoProps {
    preco: PrecoItemPorFornecedor
    fornecedorId: number
    clickAumentar?: () => void
    clickDiminuir?: () => void
    item?: PedidoItem
    aguardar?: boolean
    estaAumentando?: boolean
    estaDiminuindo?: boolean
    produtoMultisabores: boolean
    categoriaProduto: string
    clickVerSabores?: () => void
    clickEscolherSabores?: () => void
    pageCarrinho?: boolean
}

const CardPreco: React.FC<ICardPrecoProps> = ({
    preco,
    clickAumentar,
    clickDiminuir,
    item,
    aguardar,
    estaAumentando,
    estaDiminuindo,
    produtoMultisabores,
    categoriaProduto,
    clickVerSabores,
    clickEscolherSabores,
    pageCarrinho
}) => {

    if (!preco) return null
    
    const quantidade = item?.quantidade || 0

    const renderContainerPreco = () => {
        if (produtoMultisabores) {
            let precoDescricao = preco.descricao || ''
            if (preco.estrategiaPreco === EstrategiaPreco.PrecoDefinido && preco.preco > 0) {
                precoDescricao += (precoDescricao?' - ':'') + currencyFormat(preco.preco, 'R$ ')
            }

            if (pageCarrinho) {
                if (preco.estrategiaPreco !== EstrategiaPreco.PrecoDefinido && item?.preco) {
                    precoDescricao += (precoDescricao?' - ':'') + currencyFormat(item?.preco, 'R$ ')
                }

                return (
                    <Container columnDirection>
                        <CardLink text={`Escolher sabores de ${categoriaProduto} ${precoDescricao}`} textSize={'0.8em'} textAlign='left' click={clickEscolherSabores} />
                    </Container>
                )    
            }

            return (
                <Container columnDirection>
                    <CardLink text={`Ver sabores de ${categoriaProduto} ${precoDescricao}`} textSize={'0.8em'} textAlign='left' click={clickVerSabores} />
                </Container>
            )
        }

        const precoDescricao = preco.descricao ? (
            <StaticText textSize='0.9em' textColor='black'>
                {preco.descricao}
            </StaticText>
        ) : null
        return (
            <Container columnDirection>
                {precoDescricao}
                <StaticText textSize='1.1em' bold textColor='var(--color-green)'>
                    {currencyFormat(preco.preco, 'R$ ')}
                </StaticText>
            </Container>
        )
    }

    const renderSaboresSelecionados = () => {
        if (!pageCarrinho || !item || !item.sabores || item.sabores?.length <= 0) return null

        const saboresSelecionados = item?.sabores.sort((sa, sb) => {
            if (sa.itemCardapioNome > sb.itemCardapioNome) return +1
            if (sa.itemCardapioNome < sb.itemCardapioNome) return -1
            return 0
        }).map(sabor => {
            return (
                <StaticText textSize='0.9em' key={`sabor-${sabor.saborPedidoId}`} textColor='gray'>
                    {sabor.itemCardapioNome}
                </StaticText>
            )
        })

        return (
            <Container columnDirection borderTop='1px solid var(--color-gray-4)' paddingTop='5px' marginTop='5px'>
                <StaticText textSize='0.9em' bold>Sabores escolhidos:</StaticText>
                {saboresSelecionados}
            </Container>
        )
    }

    return (
        <Container borderTop='1px solid var(--color-gray-4)' paddingTop='5px' paddingBottom='5px' key={`preco-${preco.id}`} columnDirection>
            <Container center>
                {renderContainerPreco()}
                <PainelQuantidade
                    quantidade={quantidade}
                    aguardar={aguardar}
                    clickAumentar={clickAumentar}
                    clickDiminuir={clickDiminuir} 
                    estaAumentando={estaAumentando}
                    estaDiminuindo={estaDiminuindo}
                />
            </Container>
            {renderSaboresSelecionados()}
        </Container>
    )
}
  
export default CardPreco