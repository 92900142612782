import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import FixedBottom from '../../components/Fixed/FixedBottom'
import NavMenuLCRCustom from '../../components/NavMenuLCRCustom/NavMenuLCRCustom'
import {Body} from '../../components/Body/Body'
import CardProduto from '../../components/CardProduto/CardProduto'
import CardFornecedorBottom from '../../components/CardFornecedor/CardFornecedorBottom'
import CardTotalizador from '../../components/CardTotalizador/CardTotalizador'
import { DadosSobreFornecedor, linhasParaObservacao } from '../../models/DadosSobreFornecedor'
import { Pedido } from '../../models/Pedido'
import { PedidoService } from '../../services/PedidoService'
import {AlertService} from '../../services/AlertService'
import { PrecoItemPorFornecedor } from '../../models/PrecoItemPorFornecedor'
import BarButtonAvancar from '../../components/BarButtonAvancar/BarButtonAvancar'
import { OpcaoItemPorFornecedor } from '../../models/OpcaoItemPorFornecedor'
import { ValidacaoFechamento } from '../../models/ValidacaoFechamento'
import { CardapioService } from '../../services/CardapioService'
import { ItemAdicionalPorFornecedor } from '../../models/ItemAdicionalPorFornecedor'
import Aguarde from '../../components/Aguarde/Aguarde'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { CardapioServicePage } from '../../services/CardapioServicePage'
import { EstrategiaPreco } from '../../enums/EstrategiaPreco.enum'
import { GrupoOpcoes } from '../../models/GrupoOpcoes'

type CardapioParams = {
  fornecedor: string
  municipio: string
  estado: string
}

const Carrinho: React.FC = () => {

  const history = useHistory()
  const { fornecedor, municipio, estado } = useParams<CardapioParams>()
  const [dadosFornecedor, setDadosFornecedor] = useState<DadosSobreFornecedor>()
  const [estadoIdentificador, setEstadoIdentificador] = useState<string>(estado)
  const [municipioIdentificador, setMunicipioIdentificador] = useState<string>(municipio)
  const [pedido, setPedido] = useState<Pedido>()
  const [adicionaisFornecedor, setAdicionaisFornecedor] = useState<ItemAdicionalPorFornecedor[]>()
  const [atualizacao, setAtualizacao] = useState(false)
  const [validacaoFechamento, setValidacaoFechamento] = useState<ValidacaoFechamento | null>()
  const [carregandoFornecedor, setCarregandoFornecedor] = useState(true)
  const [carregandoPedido, setCarregandoPedido] = useState(true)
  const { width } = useWindowDimensions();
  const [gruposOpcoes, setGruposOpcoes] = useState<GrupoOpcoes[]>([])

  const servicePage = new CardapioServicePage(
    (value) => setCarregandoFornecedor(value),
    (value) => setCarregandoPedido(value),
    (value) => {
      obterAdicionais(value.id)
      setDadosFornecedor(value)
    },
    (value) => setPedido(value),
    (value) => setEstadoIdentificador(value),
    (value) => setMunicipioIdentificador(value),
    (value) => setGruposOpcoes(value)
  )

  //Encontrar uma forma de resolver esse worning:
  //Line 58:6:  React Hook useEffect has a missing dependency: 'servicePage'. Either include it or remove the dependency array  react-hooks/exhaustive-deps
  //Talvez criar um Hook para CardapioServicePage em vez de uma simples classe  
  useEffect(() => {
    servicePage.obterFornecedor(fornecedor, estado, municipio)
  }, [fornecedor, estado, municipio])

  const obterAdicionais = (fornecedorId: number) => {
    const cardapioService = new CardapioService()
    cardapioService
      .obterApenasItensAdicionaisPorFornecedor(fornecedorId)
      .then(dados => {
        setAdicionaisFornecedor(dados)
      })
  }
  
  const voltar = () => {
      history.push(`/cardapio/${fornecedor}/em/${municipio}/${estado}`)
  }

  const podeFinalizar = (alertar: boolean, callback?: (resp: boolean) => void) => {
    const pedidoService = new PedidoService()
    pedidoService.podeFinalizar(pedido?.pedidoId || 0)
        .then(response => {
          const validacao = response?.data?.resultado?.valor || null
          setValidacaoFechamento(validacao)
          const mensagem = validacao?.mensagens[0] || null
          if (mensagem && alertar) {
            const alert = new AlertService();
            alert.info('Atenção!', mensagem)
          }
          const temMensagemValidacao = validacao?.mensagens?.length || 0
          if (mensagem || temMensagemValidacao) {
            if (callback) callback(false)
            return
          }
          if (callback) callback(true)
        })
  }

  const irParaFinalizacao = () => {
    podeFinalizar(true, resp => {
      if (resp) {
        history.push(`/fechar-pedido/${fornecedor}/em/${municipio}/${estado}`)
      }
    })
  }

  const pedidoAlterado = (novoPedido?: Pedido) => {
    setAtualizacao(true)
    setPedido(novoPedido)
    if (validacaoFechamento) {
      podeFinalizar(false)
    }
  }

  const body = () => {
    if (carregandoPedido) {
      return <Aguarde texto='Obtendo pedido...' />
    }

    const fornecedorId = dadosFornecedor?.id || 0
    const itens = pedido?.itens || []
    return itens.map((item) => {

      const preco = new PrecoItemPorFornecedor()
      preco.id = item.precoItemCardapioId || 0
      preco.descricao = item.precoItemCardapioDescricao
      preco.preco = item.preco || 0
      preco.ativo = item.precoItemCardapio?.ativo || true
      preco.estrategiaPreco = item.precoItemCardapio?.estrategiaPreco || EstrategiaPreco.PrecoDefinido
      preco.agrupadorMultisabores = item.precoItemCardapio?.agrupadorMultisabores || ''
      preco.ocultarPrecoSabores = item.precoItemCardapio?.ocultarPrecoSabores || false

      const opcoes: OpcaoItemPorFornecedor[] = []
      for (const opcao of item.itemCardapio?.opcoes.filter(o => !o.excluido) || []) {
        const opcaoItemFornecedor = new OpcaoItemPorFornecedor()
        opcaoItemFornecedor.id = opcao.opcaoItemCardapioId
        opcaoItemFornecedor.itemCardapioIdOpcao = opcao.itemCardapioIdOpcao
        opcaoItemFornecedor.nome = opcao.itemCardapioIdOpcaoNome
        opcaoItemFornecedor.detalhes = opcao.detalhes
        opcaoItemFornecedor.quantidadeInicial = opcao.quantidadeInicial
        opcaoItemFornecedor.quantidadeMaxima = opcao.quantidadeMaxima
        opcaoItemFornecedor.quantidadeMinima = opcao.quantidadeMinima
        opcaoItemFornecedor.grupoId = opcao.grupoOpcoesId
        opcoes.push(opcaoItemFornecedor)
      }

      const mensagensValidacao = validacaoFechamento?.itensInvalidos?.filter(iv => iv.id === item.pedidoItemId)?.map(iv => iv.mensagem) || []

      return (
        <CardProduto key={`pedido-item-${item.pedidoItemId}`}
          fornecedorId={fornecedorId}
          produtoId={item.itemCardapioId}
          produtoNome={item.itemCardapioNome}
          produtoDetalhes={item.itemCardapioDetalhes}
          produtoObservacoes={item.itemCardapio?.observacaoCarrinho}
          precos={[preco]}
          opcoes={opcoes}
          categoriaNome={item.itemCardapio?.categoria?.descricao || ''}
          aoAlterarPedidoAberto={(novoPedido) => pedidoAlterado(novoPedido)}
          itens={[item]}
          pageCarrinho
          mensagensValidacao={mensagensValidacao}
          adicionaisFornecedor={adicionaisFornecedor}
          multisabores={!!item.itemCardapio && item.itemCardapio.saboresQuantidadeMinima > 0}
          quantidadeMaximaSabores={item.itemCardapio?.saboresQuantidadeMaxima || 0}
          gruposOpcoesFornecedor={gruposOpcoes}
          gruposOpcoes={item.itemCardapio?.gruposOpcoes || []}
        />
      )
    })
  }

  const nroItens = pedido?.numeroItens || 0
  const barButtonPedir = nroItens && !carregandoPedido ? <BarButtonAvancar click={irParaFinalizacao} text={'Pedir'}/> : null
 
  let topBody = isMobile ? 122 : 117
  const taxaColuna = isMobile ? 65 : (width > 527 ? 90 : 85)
  if (dadosFornecedor?.mensagemTaxaEntrega) {
    const taxaLinhas = Math.ceil(dadosFornecedor?.mensagemTaxaEntrega.length / taxaColuna)
    const linhas = taxaLinhas > 1 ? taxaLinhas : 1
    topBody += isMobile ? (25 * linhas) : (20 * linhas)
  }
  const topBodyPx = `${topBody}px`

  const cardTotalizadorRender = carregandoPedido ? null : (
    <CardTotalizador
        numeroItens={nroItens}
        numeroItensSemPreco={pedido?.numeroItensSemPreco || 0}
        subTotal={pedido?.subTotal || 0}
        taxa={dadosFornecedor?.mensagemTaxaEntrega || ''}
        taxaColuna={taxaColuna}        
    />
  )

  const cardFornecedorRender = carregandoFornecedor ? null : (
    <FixedBottom>
      <CardFornecedorBottom
        dadosFornecedor={dadosFornecedor}
        municipioIdentificador={municipioIdentificador}
        estadoIdentificador={estadoIdentificador}
      />
    </FixedBottom>
  )

  if (!atualizacao) {
    setAtualizacao(true)
    window.scrollTo(0, 0)
  }

  const extraBottomLines = linhasParaObservacao(dadosFornecedor)
  let bottomValue = (100 + (extraBottomLines * 16)) + 'px'

  return (
    <Page>
      <FixedTop>
        <NavMenuLCRCustom clickVoltar={voltar} textCenter='Carrinho'>
          {barButtonPedir}
        </NavMenuLCRCustom>
        {cardTotalizadorRender}
      </FixedTop>
      <Body top={topBodyPx} bottom={bottomValue}>
        {body()}
      </Body>
      {cardFornecedorRender}
    </Page>
  )
}
  
export default Carrinho