import React from 'react'
import { Container } from '../Container/Container'
import { StaticText } from '../StaticText/StaticText'
import {SelectedButton,Button} from './styles'

interface ICheckboxProps {
  selected?: boolean
  text?: string
  click: () => void
  textSize?: string
  textColor?: string
  marginTop?: string
  marginBottom?: string
  flexSize?: string
  size?: number
}

const Checkbox: React.FC<ICheckboxProps> = ({
    selected,
    text,
    click,
    textSize,
    textColor,
    marginTop,
    marginBottom,
    flexSize,
    size
}) => {

  size = size || 18
  const buttonSize = `${size}px`
  const selectedButtonSize = `${size-4}px`

  const button = selected ? <Button size={buttonSize}><SelectedButton size={selectedButtonSize}/></Button> : <Button size={buttonSize} />

  return (
      <Container center clickable onClick={click} marginTop={marginTop} marginBottom={marginBottom} flexSize={flexSize}>
          {button}
          <StaticText marginLeft='4px' textSize={textSize} textColor={textColor}>{text}</StaticText>
      </Container>
  )
}
export default Checkbox