import {DadosSobreFornecedor} from '../models/DadosSobreFornecedor'
import { FornecedorPorLocalizacao } from '../models/FornecedorPorLocalizacao'
import { ItemAdicionalPorFornecedor } from '../models/ItemAdicionalPorFornecedor'
import { ItemPorFornecedor } from '../models/ItemPorFornecedor'
import { Localizacao } from '../models/Localizacao'
import { OpcaoPorFornecedor } from '../models/OpcaoPorFornecedor'
import { OpcaoPorLocalizacao } from '../models/OpcaoPorLocalizacao'
import { SessaoClienteLocal } from '../models/SessaoClienteLocal'
import { PedidoFechadoParaResumo } from '../models/PedidoFechadoParaResumo'
import { Estado } from '../models/Estado'
import { Municipio } from '../models/Municipio'
import { base64_to_utf8, utf8_to_base64 } from '../utils/PlateloBase64'
import { GrupoOpcoes } from '../models/GrupoOpcoes'

const keyValidade = (keyBase: string) => `${keyBase}-validade`
const validadeGeral = 5
const validadePedidoFechadoParaResumo = 3
const keySessaoCliente = () => `platelo--sessao-cliente`
const keyFornecedor = (identificador: string) => `platelo--fornecedor-${identificador}`
const keyFornecedoresPorLocalizacao = (estado: string, municipio: string) => `platelo--fornecedores-localizacao-${estado}-${municipio}`
const keyOpcoesPorLocalizacao = (estado: string, municipio: string) => `platelo--opcoes-localizacao-${estado}-${municipio}`
const keyFornecedoresPorCategoriaLocalizacao = (estado: string, municipio: string, categoria: string) =>
    `platelo--fornecedores-categoria-localizacao-${categoria}-${estado}-${municipio}`
const keyOpcoesPorFornecedor = (identificador: string) => `platelo--opcoes-fornecedor-${identificador}`    
const keyProdutosPorCategoriaDeFornecedor = (fornecedor: string, categoria: string) => `platelo--produtos-categoria-${categoria}-fornecedor-${fornecedor}`
const keyItensAdicionaisPorFornecedor = (fornecedorId: number) => `platelo--adicionais-fornecedor-${fornecedorId}`
const keyLocalizacao = () => `platelo--localizacao-atual`
const keyGeoLocalizacao = () => `platelo--geo-localizacao-atual`
const keyPedidoFechadoParaResumo = () => `platelo--pedido-fechado-resumo`
const keyEstadosComCardapiosDisponiveis = () => `platelo--estados-disponiveis`
const keyMunicipiosComCardapiosDisponiveis = (estadoId: number) => `platelo--municipios-disponiveis-em-${estadoId}`
const keyMunicipiosPorUf = (uf: string) => `platelo--municipios-por-uf-${uf}`
const keyGruposOpcionaisPorFornecedor = (fornecedorId: number) => `grupos-opcionais-de-${fornecedorId}`

class StorageService {

    obterDeStorage = <T>(kbase: string, considerarValidade: boolean = true) : T | null => {
        try {
            const kval = keyValidade(kbase)
            if (considerarValidade) {
                const validade = +(sessionStorage.getItem(kval) || '0')
                if (!validade) {
                    this.removerDoStorage(kbase)
                    return null
                }
                const novaValidade = validade - 1
                if (novaValidade <= 0) {
                    this.removerDoStorage(kbase)
                } else {
                    sessionStorage.setItem(kval, novaValidade.toString())
                }
            }
            //const json = sessionStorage.getItem(kbase)
            const jsonBase64 = sessionStorage.getItem(kbase)
            const json = base64_to_utf8(jsonBase64)
            const dados = json ? JSON.parse(json) : null
            return dados as T
        } catch {
            return null
        }
    }

    armazenarNoStorage = (kbase: string, dados: any, validade: number = 0) => {
        try {
            const kval = keyValidade(kbase)
            sessionStorage.setItem(kval, validade.toString())
            const json = JSON.stringify(dados)
            const jsonBase64 = utf8_to_base64(json);
            //sessionStorage.setItem(kbase, json)
            sessionStorage.setItem(kbase, jsonBase64)
        } catch {
        }
    }

    removerDoStorage = (kbase: string) => {
        const kval = keyValidade(kbase)
        sessionStorage.removeItem(kval)
        sessionStorage.removeItem(kbase)
    }

    obterFornecedor = (identificador: string) : DadosSobreFornecedor | null =>
        this.obterDeStorage(keyFornecedor(identificador))
    armazenarFornecedor = (identificador: string, dados: DadosSobreFornecedor) => 
        this.armazenarNoStorage(keyFornecedor(identificador), dados, validadeGeral)

    obterFornecedoresPorLocalizacao = (estado: string, municipio: string) : FornecedorPorLocalizacao[] | null =>
        this.obterDeStorage(keyFornecedoresPorLocalizacao(estado, municipio))
    armazenarFornecedoresPorLocalizacao = (estado: string, municipio: string, dados: FornecedorPorLocalizacao[]) => 
        this.armazenarNoStorage(keyFornecedoresPorLocalizacao(estado, municipio), dados, validadeGeral)

    obterOpcoesPorLocalizacao = (estado: string, municipio: string) : OpcaoPorLocalizacao[] | null =>
        this.obterDeStorage(keyOpcoesPorLocalizacao(estado, municipio))
    armazenarOpcoesPorLocalizacao = (estado: string, municipio: string, dados: OpcaoPorLocalizacao[]) => 
        this.armazenarNoStorage(keyOpcoesPorLocalizacao(estado, municipio), dados, validadeGeral)

    obterFornecedoresPorCategoriaLocalizacao = (estado: string, municipio: string, categoria: string) : FornecedorPorLocalizacao[] | null =>
        this.obterDeStorage(keyFornecedoresPorCategoriaLocalizacao(estado, municipio, categoria))
    armazenarFornecedoresPorCategoriaLocalizacao = (estado: string, municipio: string, categoria: string, dados: FornecedorPorLocalizacao[]) => 
        this.armazenarNoStorage(keyFornecedoresPorCategoriaLocalizacao(estado, municipio, categoria), dados, validadeGeral)

    obterOpcoesPorFornecedor = (identificador: string) : OpcaoPorFornecedor[] | null =>
        this.obterDeStorage(keyOpcoesPorFornecedor(identificador))
    armazenarOpcoesPorFornecedor = (identificador: string, dados: OpcaoPorFornecedor[]) => 
        this.armazenarNoStorage(keyOpcoesPorFornecedor(identificador), dados, validadeGeral)

    obterProdutosPorCategoriaDeFornecedor = (fornecedor: string, categoria: string) : ItemPorFornecedor[] | null =>
        this.obterDeStorage(keyProdutosPorCategoriaDeFornecedor(fornecedor, categoria))
    armazenarProdutosPorCategoriaDeFornecedor = (fornecedor: string, categoria: string, dados: ItemPorFornecedor[]) => 
        this.armazenarNoStorage(keyProdutosPorCategoriaDeFornecedor(fornecedor, categoria), dados, validadeGeral)

    obterItensAdicionaisPorFornecedor = (fornecedorId: number) : ItemAdicionalPorFornecedor[] | null =>
        this.obterDeStorage(keyItensAdicionaisPorFornecedor(fornecedorId))
    armazenarItensAdicionaisPorFornecedor = (fornecedorId: number, dados: ItemAdicionalPorFornecedor[]) => 
        this.armazenarNoStorage(keyItensAdicionaisPorFornecedor(fornecedorId), dados, validadeGeral)
        
    obterSessaoCliente = () : SessaoClienteLocal | null =>
        this.obterDeStorage(keySessaoCliente(), false)
    armazenarSessaoCliente = (clienteId?: string | null, sessaoClienteId?: number | null) => {
        if (!clienteId) {
            clienteId = this.obterSessaoCliente()?.clienteId;
        }
        this.armazenarNoStorage(keySessaoCliente(), {clienteId:clienteId, sessaoClienteId:sessaoClienteId})
    }
    armazenarSessaoClienteId = (sessaoClienteId?: number | null) => {
        this.armazenarSessaoCliente(null, sessaoClienteId)
    }
    removerSessaoCliente = () => 
        this.removerDoStorage(keySessaoCliente())

    obterLocalizacao = () : Localizacao | null =>
        this.obterDeStorage(keyLocalizacao())
    armazenarLocalizacao = (dados: Localizacao) => 
        this.armazenarNoStorage(keyLocalizacao(), dados, validadeGeral)    
    removerLocalizacao = () =>
        this.removerDoStorage(keyLocalizacao())

    armazenarGeoLocalizacao = (dados: any) => 
        this.armazenarNoStorage(keyGeoLocalizacao(), dados, validadeGeral)    

    armazenarPedidoFechadoParaResumo = (pedidoFechado: PedidoFechadoParaResumo) =>
        this.armazenarNoStorage(keyPedidoFechadoParaResumo(), pedidoFechado, validadePedidoFechadoParaResumo)
    obterPedidoFechadoParaResumo = () : PedidoFechadoParaResumo | null =>
        this.obterDeStorage(keyPedidoFechadoParaResumo())

    obterEstadosComCardapiosDisponiveis = () : Estado[] | null =>
        this.obterDeStorage(keyEstadosComCardapiosDisponiveis())
    armazenarEstadosComCardapiosDisponiveis = (dados: Estado[]) => 
        this.armazenarNoStorage(keyEstadosComCardapiosDisponiveis(), dados, validadeGeral)

    obterMunicipiosComCardapiosDisponiveis = (estadoId: number) : Municipio[] | null =>
        this.obterDeStorage(keyMunicipiosComCardapiosDisponiveis(estadoId))
    armazenarMunicipiosComCardapiosDisponiveis = (estadoId: number, dados: Municipio[]) => 
        this.armazenarNoStorage(keyMunicipiosComCardapiosDisponiveis(estadoId), dados, validadeGeral)

    obterMunicipiosPorUf = (uf: string) : Municipio[] | null =>
        this.obterDeStorage(keyMunicipiosPorUf(uf))
    armazenarMunicipiosPorUf = (uf: string, dados: Municipio[]) => 
        this.armazenarNoStorage(keyMunicipiosPorUf(uf), dados, validadeGeral)

    obterGruposOpcionaisPorFornecedor = (fornecedorId: number) : GrupoOpcoes[] | null =>
        this.obterDeStorage(keyGruposOpcionaisPorFornecedor(fornecedorId))
    armazenarGruposOpcionaisPorFornecedor = (fornecedorId: number, dados: GrupoOpcoes[]) => 
        this.armazenarNoStorage(keyGruposOpcionaisPorFornecedor(fornecedorId), dados, validadeGeral)
}

export default StorageService