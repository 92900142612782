import styled from 'styled-components'
import { mediaScreen, mediaScreenLeft } from '../../styles'

export const Container = styled.div`
    display: block;
    background-color: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1000;
`

interface IHeaderProps {
    minHeight?: string | null
}

export const Header = styled.div<IHeaderProps>`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    width: calc(100% - 20px);
    height: ${props => props.minHeight || '50px'};
    position: fixed;
    left: 30%;
    top: 5px;
    background-color: #fff;
    z-index: 1002;
    padding: 10px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    ${mediaScreen};
    ${mediaScreenLeft};
`

export const HeaderTitle = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    align-content: center;
    padding-bottom: 5px;
`

export const HeaderBody = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    align-content: center;
    border-top: 1px solid var(--color-gray-4);    
    padding-top: 5px;
`

interface IBodyProps {
    withFooter?: boolean
    top?: string | null
}

export const Body = styled.div<IBodyProps>`
    display: block;
    position: fixed;
    left: 30%;
    top: ${props => props.top || '55px'};
    width: calc(100% - 20px);
    height: ${props => props.withFooter ? 'calc(100% - 110px)' : 'calc(100% - 60px)'};
    background-color: #fff;
    z-index: 1001;
    overflow: hidden;
    border-bottom-left-radius: ${props => props.withFooter ? 'none' : '5px'};
    border-bottom-right-radius: ${props => props.withFooter ? 'none' : '5px'};

    ${mediaScreen};
    ${mediaScreenLeft};
`

export const BodyContent = styled.div<IBodyProps>`
    padding: 7px 10px;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(0,0,0,0.1);
    width: calc(100% - 20px);
    position: fixed;
    left: 30%;
    bottom: 5px;
    background-color: #fff;
    z-index: 1002;
    padding: 10px 10px;
    height: 50px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    ${mediaScreen};
    ${mediaScreenLeft};
`

export const ContainerFilter = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
`