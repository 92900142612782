import React from 'react'
import { isMobile } from 'react-device-detect'
import { Body } from '../Body/Body'

interface IBodyNavTabProps {
    bottom?: string
    extraBottomLines?: number
}

const BodyNav: React.FC<IBodyNavTabProps> = ({
    children,
    bottom,
    extraBottomLines
}) => {

    let bottomValue = bottom
    if (!bottomValue) {
        bottomValue = 85 + ((extraBottomLines||0) * 16) + 'px'
    }

    return (
        <Body top={isMobile ? '45px' : '40px'} bottom={bottomValue}>
            {children}
        </Body>
    )
}

export default BodyNav