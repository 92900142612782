import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuLCR from '../../components/NavMenuLCR/NavMenuLCR'
import BodyNav from '../../components/BodyNav/BodyNav'
import {Card} from '../../components/Card/Card'
import StorageService from '../../services/StorageService'
import { ClienteService } from '../../services/ClienteService'
import { Cliente } from '../../models/Cliente'
import Field from '../../components/Field/Field'
import { ToastService } from '../../services/ToastService'
import Aguarde from '../../components/Aguarde/Aguarde'
import NavMenuLC from '../../components/NavMenuLC/NavMenuLC'

const MeusDados: React.FC = () => {

  const history = useHistory()
  const [nome, setNome] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [carregando, setCarregando] = useState(true)
  const [salvando, setSalvando] = useState(false)

  const storage = new StorageService()
  const toast = new ToastService()

  const clienteId = storage.obterSessaoCliente()?.clienteId

  useEffect(() => {
    if (!clienteId) {
      history.push(`/entrar`)
      return
    }

    const clienteService = new ClienteService()
    clienteService.obterPorCodigo(clienteId)
      .then(response => {
        const cliente = response.data.resultado
        setNome(cliente?.nome || '')
        setEmail(cliente?.email || '')
        setCarregando(false)
      })
  }, [clienteId, history])

  const voltar = () => {
      history.push(`/menu`)
  }

  const salvar = () => {
    if (!clienteId) return
    setSalvando(true)
    const cliente = new Cliente()
    cliente.nome = nome
    cliente.email = email
    const clienteService = new ClienteService()
    clienteService.salvarPorCodigo(clienteId, cliente)
      .then(response => {
        if (response.data.sucesso) {
          toast.success('Dados atualizados com sucesso!')
          return
        }

        if (response?.data?.mensagens?.length) {
          toast.success(response.data.mensagens[0])
        }
      })
      .finally(() => {
        setSalvando(false)
      })
  }

  window.scrollTo(0, 0)

  const renderBody = () => {
    if (carregando) {
      return <Aguarde texto='Obtendo seus dados...' />
    }

    return (
      <Card>
          <Field
            type='text'
            label='Nome'
            value={nome}
            change={(value) => setNome(value)}
          />
          <Field
            type='email'
            label='E-mail'
            value={email}
            change={(value) => setEmail(value)}
          />
      </Card>
    )
  }

  const renderNavMenu = () => {
    if (carregando) {
      return (
        <NavMenuLC clickVoltar={voltar} textCenter='Meus Dados' />
      )
    }

    return (
      <NavMenuLCR clickVoltar={voltar} clickAvancar={salvar} textCenter='Meus Dados' textRight='Salvar' loadingRight={salvando} />
    )
  }

  return (
    <Page>
      <FixedTop>
        {renderNavMenu()}
      </FixedTop>
      <BodyNav>
        {renderBody()}
      </BodyNav>
    </Page>
  )
}
  
export default MeusDados