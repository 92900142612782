import React, {useEffect, useMemo} from 'react'
import Page from '../../components/Page/Page'
import PageHeader from './components/PageHeader'
import PageBody from './components/PageBody'
import PageFooter from './components/PageFooter'
import useFechaPedido from './hooks/useFechaPedido'
import useIsMountedRef from '../../hooks/useIsMountedRef'
import { isMobile } from 'react-device-detect'

const FechaPedido: React.FC = () => {
  
  const isMountedRef = useIsMountedRef()
  const {$, $exec, $service} = useFechaPedido()

  useEffect(() => {
    $service.fornecedor
      .obterApenasDadosSobreFornecedor($.fornecedor)
      .then(dados => {
        if (isMountedRef.current) {
          $.setDadosFornecedor(dados)
          if (!$.estadoIdentificador) {
            $.setEstadoIdentificador(dados?.enderecos[0].estadoIdentificador || '')
          }
          if (!$.municipioIdentificador) {
            $.setMunicipioIdentificador(dados?.enderecos[0].municipioIdentificador || '')
          }
          if (dados.taxasEntregas && dados.taxasEntregas.length > 0) {
            $.setTaxaEntrega(dados.taxasEntregas[0].valor)
          }
        }
      })
  }, [$.fornecedor, isMountedRef])

  useMemo(() => {
    if ($.dadosFornecedor?.id) {
      $exec.obterPedidoEmAberto(isMountedRef, $.dadosFornecedor.id)
    }
  }, [$.dadosFornecedor, isMountedRef])

  const ampliarTela = useMemo(() => {
    return !$.areaAberta && window.innerHeight < 600
  }, [$.areaAberta])

  if (!$.atualizacao) {
    if (isMountedRef.current) {
      $.setAtualizacao(true)
    }
    window.scrollTo(0, 0)
  }

  return (
    <Page>
      <PageHeader
        etapa={$.etapa}
        ampliarTela={ampliarTela}
        pedido={$.pedido}
        voltar={() => $exec.voltar(isMountedRef)}
        finalizar={() => $exec.finalizar(isMountedRef)}
      />
      <PageBody
        etapa={$.etapa}
        ampliarTela={ampliarTela}
        tipoEntrega={$.tipoEntrega}
        enderecoLogradouro={$.enderecoLogradouro}
        enderecoEstado={$.enderecoEstado || $.estado?.toUpperCase() || ''}
        enderecoMunicipio={$.enderecoMunicipio}
        enderecoArea={$.enderecoArea}
        nomeCliente={$.nomeCliente}
        pagamento={$.pagamento}
        observacao={$.observacao}
        dadosFornecedor={$.dadosFornecedor}
        setAreaAberta={(value) => $exec.aplicarAreaAberta(isMountedRef, value)}
        aplicarTipoEntrega={(value) => $exec.aplicarTipoEntrega(isMountedRef, value)}
        setEnderecoLogradouro={(value) => $.setEnderecoLogradouro(value)}
        setEnderecoMunicipio={(value) => $.setEnderecoMunicipio(value)}
        setEnderecoArea={(value) => $.setEnderecoArea(value)}
        setNomeCliente={(value) => $.setNomeCliente(value)}
        setPagamento={(value) => $.setPagamento(value)}
        setObservacao={(value) => $.setObservacao(value)}
      />
      <PageFooter
        ampliarTela={ampliarTela || (isMobile && $.etapa === 0)}
        municipioIdentificador={$.municipioIdentificador}
        estadoIdentificador={$.estadoIdentificador}
        dadosFornecedor={$.dadosFornecedor}
      />
    </Page>
  )
}
  
export default FechaPedido