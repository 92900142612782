import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuLCR from '../../components/NavMenuLCR/NavMenuLCR'
import NavMenuLC from '../../components/NavMenuLC/NavMenuLC'
import BodyNav from '../../components/BodyNav/BodyNav'
import {Card} from '../../components/Card/Card'
import Field from '../../components/Field/Field'
import { ClienteService } from '../../services/ClienteService'
import { ToastService } from '../../services/ToastService'
import StorageService from '../../services/StorageService'
import Mensagem from '../../components/Mensagem/Mensagem'

const RecuperarSenha: React.FC = () => {

  const history = useHistory()
  const [email, setEmail] = useState('')
  const [recuperando, setRecuperando] = useState(false)
  const [mensagem, setMensagem] = useState('')

  const toaster = new ToastService()
  const clienteService = new ClienteService()
  const storage = new StorageService()

  const clienteId = storage.obterSessaoCliente()?.clienteId

  useEffect(() => {
    if (clienteId) {
      history.push(`/`)
      return
    }
  }, [clienteId, history])

  const recuperar = () => {
    if (!email) {
      toaster.info('Informe seu e-mail!')
      return
    }

    setRecuperando(true)

    clienteService.obterLinkNovaSenha(email)
      .then(response => {
        if (response.data.sucesso && response.data.resultado?.valor) {
          if (response.data.mensagens.length) {
            setMensagem(response.data.mensagens[0])
            return
          } else {
            voltar()
          }
        } else if (response?.data?.mensagens?.length) {
          toaster.info(response.data.mensagens[0])
        }
      })
      .finally(() => {
        setRecuperando(false)
      })
  }

  const voltar = () => {
      history.push(`/entrar`)
  }

  const navMenuRender = mensagem ? (
    <NavMenuLC clickVoltar={voltar} textCenter='Recuperar Senha' />
  ) : (
    <NavMenuLCR clickVoltar={voltar} clickAvancar={recuperar} textCenter='Recuperar Senha' textRight='Recuperar' loadingRight={recuperando} />
  )

  const bodyRender = mensagem ? (
    <Mensagem texto={mensagem} />
  ) : (
    <Field type='email' label='E-mail' value={email} change={(value) => setEmail(value)} placeHolder='Informe o e-mail cadastrado e acione o botão Recuperar' />
  )

  window.scrollTo(0, 0)

  return (
    <Page>
      <FixedTop>
        {navMenuRender}
      </FixedTop>
      <BodyNav>
        <Card>
          {bodyRender}
        </Card>
      </BodyNav>
    </Page>
  )
}
  
export default RecuperarSenha