import { EstrategiaPreco } from "../enums/EstrategiaPreco.enum"

export class PrecoItemPorFornecedor {
    id: number = 0
    descricao: string = ''
    preco: number = 0
    ativo: boolean = true
    estrategiaPreco: EstrategiaPreco = EstrategiaPreco.PrecoDefinido
    agrupadorMultisabores: string = ''
    ocultarPrecoSabores: boolean = false
}