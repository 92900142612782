import React from 'react'
import {StaticText} from '../../StaticText/StaticText'

interface IProdutoNomeProps {
    texto?: string
}

const ProdutoNome: React.FC<IProdutoNomeProps> = ({
    texto
}) => {

    if (!texto) return null

    return (
        <StaticText textSize='1.3em' bold>
            {texto}
        </StaticText>
    )
}
  
export default ProdutoNome