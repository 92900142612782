import React from 'react'
import { Localizacao } from '../../../models/Localizacao'
import BodyNav from '../../../components/BodyNav/BodyNav'
import Aguarde from '../../../components/Aguarde/Aguarde'
import BodyNavTab from '../../../components/BodyNavTab/BodyNavTab'
import PainelLocalizacao from './PainelLocalizacao/PainelLocalizacao'
import TabCategorias from './TabCategorias'
import TabFornecedores from './TabFornecedores'

interface IPageBodyProps {
    obtendoLocalizacao: boolean
    localizacao?: Localizacao | null
    definirLocalizacao: (local: Localizacao) => void
    activeMenu: string
    tabCategorias: boolean
    pesquisaCategoriasTexto: string
    pesquisaFornecedoresTexto: string
}

const PageBody: React.FC<IPageBodyProps> = ({
    obtendoLocalizacao,
    localizacao,
    definirLocalizacao,
    activeMenu,
    tabCategorias,
    pesquisaCategoriasTexto,
    pesquisaFornecedoresTexto
}) => {

    if (obtendoLocalizacao) {
        return (
          <BodyNav>
            <Aguarde texto='Obtendo sua localização...' />
          </BodyNav>
        )
    }
      
    if (!localizacao) {
        return (
          <BodyNav>
            <PainelLocalizacao aoDefinirLocalizacao={(local) => definirLocalizacao(local)} />
          </BodyNav>
        )
    }

    if (activeMenu === 'cat' || tabCategorias) {
        return (
          <BodyNavTab bottom='65px'>
            <TabCategorias estado={localizacao.uf.toLowerCase()} municipio={localizacao.municipioIdentificador} filtro={pesquisaCategoriasTexto}  />
          </BodyNavTab>
        )
    }
  
    return (
        <BodyNavTab bottom='65px'>
          <TabFornecedores estado={localizacao.uf.toLowerCase()} municipio={localizacao.municipioIdentificador} filtro={pesquisaFornecedoresTexto} />
        </BodyNavTab>
    )

}

export default PageBody