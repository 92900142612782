import React from 'react'
import {Card} from '../../../components/Card/Card'
import Field from '../../../components/Field/Field'

interface IEtapaIdentificacaoProps {
    nomeCliente: string
    setNomeCliente: (value: string) => void
}

const EtapaIdentificacao: React.FC<IEtapaIdentificacaoProps> = ({
    nomeCliente,
    setNomeCliente
}) => {

    return (
        <Card>
          <Field
            type='text'
            label='Como deseja ser identificado(a)'
            value={nomeCliente}
            change={(value) => setNomeCliente(value)}
          />
        </Card>
    )
}

export default EtapaIdentificacao