import styled from 'styled-components'

export const SelectedButton = styled.div`
    border: 1px solid #fff;
    width: 14px;
    height: 14px;
    border-radius: 30px;
    background-color: var(--color-blue-1);
`

export const Button = styled.div`
    border: 2px solid var(--color-blue-5);
    width: 18px;
    height: 18px;
    border-radius: 30px;
`