import React from 'react'
import NavMenuLCR from '../../../components/NavMenuLCR/NavMenuLCR'

interface INavMenuCadastrarProps {
    clickVoltar: () => void
    clickAvancar: () => void
    salvando: boolean
}

const NavMenuCadastrar: React.FC<INavMenuCadastrarProps> = ({
    clickVoltar,
    clickAvancar,
    salvando
}) => (
    <NavMenuLCR clickVoltar={clickVoltar} clickAvancar={clickAvancar} textCenter='Cadastrar' textRight='Salvar' loadingRight={salvando} />
)

export default NavMenuCadastrar