import axios, { AxiosResponse } from 'axios'

export const urlBase = process.env.REACT_APP_BASE_URL || ''

const getUrl = (resource: string) => {
    if (resource.indexOf(urlBase) >= 0) return resource
    let url = urlBase
    if (url.endsWith('/')) {
        if (resource[0] === '/') {
            resource = resource.substring(1)
        }
    } else if (resource[0] !== '/') {
        resource = '/' + resource
    }
    return url + resource
}

class HttpService {
    async get<TResult>(url: string, config?: any) {
        const promise: Promise<AxiosResponse<TResult>> = new Promise(async (resolve, reject) => {
            try {
                const response = axios.get<TResult>(getUrl(url), {
                    headers: {
                        'ClientToken': ''
                    }
                })
                resolve(response)
            } catch (error) {
                if (error.response) {
                    resolve(error.response)
                } else {
                    reject(error)
                }
            } finally {
            }
        })
        return promise
    }

    async post<TResult, TData>(url: string, data: TData, config?: any) {
        const promise: Promise<AxiosResponse<TResult>> = new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post<TResult>(getUrl(url), data, {
                    headers: {
                        'ClientToken': ''
                    }
                })
                resolve(response)
            } catch (error) {
                if (error.response) {
                    resolve(error.response)
                } else {
                    reject(error)
                }
            } finally {
            }
        })
        return promise
    }

    async put<TResult, TData>(url: string, data: TData, config?: any) {
        const promise: Promise<AxiosResponse<TResult>> = new Promise(async (resolve, reject) => {
            try {
                const response = await axios.put<TResult>(getUrl(url), data, {
                    headers: {
                        'ClientToken': ''
                    }
                })
                resolve(response)
            } catch (error) {
                if (error.response) {
                    resolve(error.response)
                } else {
                    reject(error)
                }
            } finally {
            }
        })
        return promise
    }

    async delete<TResult>(url: string, config?: any) {
        const promise: Promise<AxiosResponse<TResult>> = new Promise(async (resolve, reject) => {
            try {
                const response = await axios.delete<TResult>(getUrl(url), {
                    headers: {
                        'ClientToken': ''
                    }
                })
                resolve(response)
            } catch (error) {
                if (error.response) {
                    resolve(error.response)
                } else {
                    reject(error)
                }
            } finally {
            }
        })
        return promise
    }
}

export default HttpService