import React, { useEffect, useState } from 'react'
import Page from '../../components/Page/Page'
import FixedTop from '../../components/Fixed/FixedTop'
import NavMenuCadastrar from './components/NavMenuCadastrar'
import BodyNav from '../../components/BodyNav/BodyNav'
import { useHistory } from 'react-router-dom'
import { ToastService } from '../../services/ToastService'
import { ClienteService } from '../../services/ClienteService'
import StorageService from '../../services/StorageService'
import FormCadastrar from './components/FormCadastrar'
import CadastrarService from './services/CadastrarService'

const Cadastrar: React.FC = () => {

  const history = useHistory()
  const [salvando, setSalvando] = useState(false)

  const toaster = new ToastService()
  const clienteService = new ClienteService()
  const storage = new StorageService()
  const cad = new CadastrarService(toaster, clienteService, storage)

  const clienteId = storage.obterSessaoCliente()?.clienteId

  useEffect(() => {
    if (clienteId) {
      history.push(`/`)
      return
    }
  }, [clienteId, history])

  const voltar = () => {
      history.push(`/entrar`)
  }

  const salvar = () => {
    cad.salvar(
      () => setSalvando(true),
      () => setSalvando(false),
      () => setTimeout(() => history.push('/'), 500)
    )
  }

  window.scrollTo(0, 0)

  return (
    <Page>
      <FixedTop>
        <NavMenuCadastrar clickVoltar={voltar} clickAvancar={salvar} salvando={salvando} />
      </FixedTop>
      <BodyNav>
        <FormCadastrar change={(form) => cad.setFormData(form)} />
      </BodyNav>
    </Page>
  )
}
  
export default Cadastrar