import React from 'react'
import FixedTop from '../../../components/Fixed/FixedTop'
import StorageService from '../../../services/StorageService'
import HomeNavMenu from './HomeNavMenu'
import TabMenuHome from './TabMenuHome'

interface IPageHeaderProps {
    activeMenu: string
    localizacaoDefinida: boolean
    clickEntrar: () => void
    clickMenu: () => void
    pesquisaFornecedoresTexto: string
    pesquisaCategoriasTexto: string
    pesquisar: (pesq: boolean) => void
    pesquisaFornecedores: boolean
    pesquisaCategorias: boolean
    aoPesquisar: (texto: string) => void
    ativarTabCategorias: () => void
    ativarTabFornecedores: () => void
}

const PageHeader: React.FC<IPageHeaderProps> = ({
    activeMenu,
    localizacaoDefinida,
    clickEntrar,
    clickMenu,
    pesquisaFornecedoresTexto,
    pesquisaCategoriasTexto,
    pesquisar,
    pesquisaFornecedores,
    pesquisaCategorias,
    aoPesquisar,
    ativarTabCategorias,
    ativarTabFornecedores
}) => {

    const storage = new StorageService()

    const sessaoCliente = storage.obterSessaoCliente()
    const clienteId = sessaoCliente?.clienteId ? true : false  

    return (
        <FixedTop>
            <HomeNavMenu 
                clickPesquisar={(pesq) => pesquisar(pesq)}
                clickEntrar={clickEntrar}
                clickMenu={clickMenu}
                pesquisar={(activeMenu === 'for' && pesquisaFornecedores) || (activeMenu === 'cat' && pesquisaCategorias)}
                ocultarPesquisar={!localizacaoDefinida}
                pesquisaTexto={activeMenu === 'for' ? pesquisaFornecedoresTexto : pesquisaCategoriasTexto}
                entrar={localizacaoDefinida && !clienteId}
                menu={localizacaoDefinida && clienteId}
                aoPesquisar={(texto) => aoPesquisar(texto)}
            />
            <TabMenuHome 
                localizacaoDefinida={localizacaoDefinida}
                activeMenu={activeMenu}
                ativarTabCategorias={ativarTabCategorias}
                ativarTabFornecedores={ativarTabFornecedores}
            />
        </FixedTop>
    )
}

export default PageHeader