import styled from 'styled-components'

interface IBodyProps {
    top?: string
    bottom?: string
}

export const Body = styled.div<IBodyProps>`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: ${props => props.top || "var(--fixed-bar-height)"};
    margin-bottom: ${props => props.bottom || "0px"};
    background-color: var(--color-body);
`