import styled from 'styled-components'

interface IButtonProps {
    marginTop?: string
    marginBottom?: string
    marginLeft?: string
    marginRight?: string
    align?: string
}

export const Button = styled.button<IButtonProps>`
    padding: 10px;
    border-width: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: ${props => props.align || 'center'};
    align-items: ${props => props.align || 'center'};
    justify-content: ${props => props.align || 'center'};
    background-color: var(--color-gray-4);
    border-radius: 4px;
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};

    &:hover {
        background-color: var(--color-gray-3);
    }
`