import styled from 'styled-components'

interface IContainerProps {
    columnDirection?: boolean
    marginLeft?: string
    marginRight?: string
    marginTop?: string
    marginBottom?: string
    padding?: string
    paddingLeft?: string
    paddingRight?: string
    paddingTop?: string
    paddingBottom?: string
    borderTop?: string
    borderBottom?: string
    borderLeft?: string
    borderRight?: string
    border?: string
    borderRadius?: string
    flexSize?: string
    center?: boolean
    clickable?: boolean
    maxWidth?: string
    borderRadiusTop?: string
    borderRadiusBottom?: string
    justifyContent?: string
    backgroundColor?: string
    minHeight?: string
    minWidth?: string
    maxHeight?: string
}

export const Container = styled.div<IContainerProps>`
    max-width: ${props => props.maxWidth || 'auto'};
    flex: ${props => props.flexSize || '1'};
    display: flex;
    flex-direction: ${props => props.columnDirection ? 'column' : 'row'};
    margin-left: ${props => props.marginLeft || 'none'};
    margin-right: ${props => props.marginRight || 'none'};
    margin-top: ${props => props.marginTop || 'none'};
    margin-bottom: ${props => props.marginBottom || 'none'};
    padding: ${props => props.padding || 'none'};
    padding-left: ${props => props.padding || props.paddingLeft || 'none'};
    padding-right: ${props => props.padding || props.paddingRight || 'none'};
    padding-top: ${props => props.padding || props.paddingTop || 'none'};
    padding-bottom: ${props => props.padding || props.paddingBottom || 'none'};
    border-top: ${props => props.borderTop || props.border || 'none'};
    border-bottom: ${props => props.borderBottom || props.border || 'none'};
    border-left: ${props => props.borderLeft || props.border || 'none'};
    border-right: ${props => props.borderRight || props.border || 'none'};
    border-radius: ${props => props.borderRadius || '0px'};
    border-top-left-radius: ${props => props.borderRadiusTop || props.borderRadius || '0px'};
    border-top-right-radius: ${props => props.borderRadiusTop || props.borderRadius || '0px'};
    border-bottom-left-radius: ${props => props.borderRadiusBottom || props.borderRadius || '0px'};
    border-bottom-right-radius: ${props => props.borderRadiusBottom || props.borderRadius || '0px'};
    align-items: ${props => props.center ? 'center' : 'normal'};
    align-content: ${props => props.center ? props.justifyContent || 'center' : 'normal'};
    justify-content: ${props => props.justifyContent || 'normal'};
    cursor: ${props => props.clickable ? 'pointer' : 'normal'};
    background-color: ${props => props.backgroundColor || 'transparent'};
    min-height: ${props => props.minHeight || 'auto'};
    height: ${props => props.minHeight || 'auto'};
    max-height: ${props => props.maxHeight || 'auto'};
    min-width: ${props => props.minWidth || 'auto'};
`