import { DadosSobreFornecedor } from '../models/DadosSobreFornecedor'
import {FornecedorPorLocalizacao} from '../models/FornecedorPorLocalizacao'
import {GrupoOpcoes} from '../models/GrupoOpcoes'
import {RespostaBase} from '../models/RespostaBase'
import HttpService from './HttpService'
import StorageService from './StorageService'

export class FornecedorService {

    private http: HttpService = new HttpService()
    private storage: StorageService = new StorageService()

    obterFornecedoresPorLocalizacao = (estado: string, municipio: string) => {
        return this.http.get<RespostaBase<FornecedorPorLocalizacao[]>>(
            `FornecedoresPorLocalizacao?estado=${estado}&municipio=${municipio}`)
    }

    obterApenasFornecedoresPorLocalizacao = (estado: string, municipio: string) => {
        const promise: Promise<FornecedorPorLocalizacao[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterFornecedoresPorLocalizacao(estado, municipio)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterFornecedoresPorLocalizacao(estado, municipio).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarFornecedoresPorLocalizacao(estado, municipio, resultado)
                    resolve(resultado)
                }
            })
        })
        return promise
    }

    obterFornecedoresPorCategoriaLocalizacao = (estado: string, municipio: string, categoria: string) => {
        return this.http.get<RespostaBase<FornecedorPorLocalizacao[]>>(
            `FornecedoresPorCategoriaIdentificadorELocalizacao?estado=${estado}&municipio=${municipio}&categoria=${categoria}`)
    }

    obterApenasFornecedoresPorCategoriaLocalizacao = (estado: string, municipio: string, categoria: string) => {
        const promise: Promise<FornecedorPorLocalizacao[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterFornecedoresPorCategoriaLocalizacao(estado, municipio, categoria)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterFornecedoresPorCategoriaLocalizacao(estado, municipio, categoria).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarFornecedoresPorCategoriaLocalizacao(estado, municipio, categoria, resultado)
                    resolve(resultado)
                }
            })
        })
        return promise
    }

    obterDadosSobreFornecedor = (fornecedor: string) => {
        return this.http.get<RespostaBase<DadosSobreFornecedor>>(
            `DadosSobreFornecedorIdentificador?fornecedor=${fornecedor}`)
    }

    obterApenasDadosSobreFornecedor = (fornecedor: string) => {
        const promise: Promise<DadosSobreFornecedor> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterFornecedor(fornecedor)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterDadosSobreFornecedor(fornecedor).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarFornecedor(fornecedor, resultado)
                    resolve(resultado)
                }
            })
        })
        return promise
    }

    obterGruposOpcionaisPorFornecedor = (fornecedorId: number) => {
        return this.http.get<RespostaBase<GrupoOpcoes[]>>(
            `GruposOpcoesPorFornecedor/${fornecedorId}`)
    }

    obterApenasGruposOpcionaisPorFornecedor = (fornecedorId: number) => {
        const promise: Promise<GrupoOpcoes[]> = new Promise(async (resolve, reject) => {
            const dados = this.storage.obterGruposOpcionaisPorFornecedor(fornecedorId)
            if (dados) {
                resolve(dados)
                return
            }

            this.obterGruposOpcionaisPorFornecedor(fornecedorId).then(response => {
                const resultado = response.data.resultado
                if (resultado) {
                    this.storage.armazenarGruposOpcionaisPorFornecedor(fornecedorId, resultado)
                    resolve(resultado)
                }
            })
        })
        return promise
    }

}