import styled from 'styled-components'

interface ITabMenuItemContainerProps {
    active: boolean
}

export const TabMenuItemContainer = styled.div<ITabMenuItemContainerProps>`
    flex: 1;
    padding: 0px;
    background-color: var(--color-gray-4);
    cursor: pointer;
    text-align: center;
    font-weight: ${props => props.active ? 'bold' : 'none'};
    color: '#ddd!important';
    padding: 10px;
    border-bottom: ${props => props.active ? '4px solid var(--color-gray-3)' : '4px solid var(--color-gray-4)'};

    &:last-of-type {
        margin-left: 0px;
        margin-right: 0px;
    }
`