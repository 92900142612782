import React, { useState } from 'react'
import {Card} from '../../../components/Card/Card'
import { StaticText } from '../../../components/StaticText/StaticText'
import { Container } from '../../../components/Container/Container'
import Field from '../../../components/Field/Field'
import RadioButtonField from '../../../components/RadioButtonField/RadioButtonField'
import { DadosSobreFornecedor } from '../../../models/DadosSobreFornecedor'
import { TipoEntrega } from '../../../enums/TipoEntrega.enum'
import ModalMunicipios from './ModalMunicipios'
import { Area } from '../../../enums/Area.enum'

interface IEtapaEntregaProps {
    dadosFornecedor?: DadosSobreFornecedor
    tipoEntrega?: TipoEntrega
    enderecoLogradouro?: string
    enderecoEstado: string
    enderecoMunicipio?: string
    enderecoArea?: Area
    aplicarTipoEntrega: (value: TipoEntrega) => void
    setAreaAberta: (value: boolean) => void
    setEnderecoLogradouro: (value: string) => void
    setEnderecoMunicipio: (value: string) => void
    setEnderecoArea: (value: Area) => void
}

const EtapaEntrega: React.FC<IEtapaEntregaProps> = ({
    dadosFornecedor,
    tipoEntrega,
    enderecoLogradouro,
    enderecoEstado,
    enderecoMunicipio,
    enderecoArea,
    aplicarTipoEntrega,
    setAreaAberta,
    setEnderecoLogradouro,
    setEnderecoMunicipio,
    setEnderecoArea
}) => {

    const [modalMunicipios, setModalMunicipios] = useState<boolean>(false)

    if (modalMunicipios) {
      return (
        <ModalMunicipios
            visible={modalMunicipios}
            close={() => setModalMunicipios(false)} 
            estado={enderecoEstado}
            select={(municipio) => setEnderecoMunicipio(municipio.nome)}
        />
      )
    }

    const fieldTipoEntrega = !dadosFornecedor?.tipoEntrega
        ? <RadioButtonField
            label='Como prefere receber o pedido'
            options={[
              { value: 1, text: 'Vou retirar no local' },
              { value: 2, text: 'Quero receber no meu endereço' }
            ]}
            click={(value) => aplicarTipoEntrega(value)}
            selectedValue={tipoEntrega}
          />
        : null

    const fieldEndereco = tipoEntrega === TipoEntrega.Delivery
        ? <>
          <StaticText padding='10px' bold textAlign='left' backgroundColor={'#f5f5f5'}>
            Confirme abaixo o endereço de entrega
          </StaticText>
          <Container>
            <Field
                type='statictext' 
                label='UF'
                maxWidth='50px'
                minWidth='50px'
                marginRight='10px'
                value={enderecoEstado}
            />
            <Field
                type='select' 
                label='Município/Distrito'
                maxWidth='100%'
                value={enderecoMunicipio}
                click={() => setModalMunicipios(true)}
            />
          </Container>
          <Field
              type='text' 
              label='Logradouro, Número'
              focus={() => setAreaAberta(false)}
              blur={() => setAreaAberta(true)}
              value={enderecoLogradouro}
              change={(value) => setEnderecoLogradouro(value)}
          />
          <RadioButtonField
            label='Área'
            rowDirection
            options={[
              { value: 1, text: 'Urbana' },
              { value: 2, text: 'Rural' }
            ]}
            selectedValue={enderecoArea}
            click={(value) => setEnderecoArea(value)}
          />
          </>
        : null

    const containerSobreTaxa = dadosFornecedor?.mensagemTaxaEntrega ? (
        <Container padding='10px' borderBottom='1px solid var(--color-body)'>
          <StaticText textColor='#d68623' textSize='0.8em' textAlign='center' minWidth='100%'>
              {dadosFornecedor?.mensagemTaxaEntrega}
          </StaticText>
        </Container>
    ) : null

    return (
        <Card>
          <Container columnDirection>
            {containerSobreTaxa}
            {fieldTipoEntrega}
            {fieldEndereco}
          </Container>
        </Card>
    )
}

export default EtapaEntrega