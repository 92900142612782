import React, {useState} from 'react'
import {Card} from '../Card/Card'
import {Container} from '../Container/Container'
import {StaticText} from '../StaticText/StaticText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import {CardButtons} from '../../components/CardButtons/CardButtons'
import CardButton from '../../components/CardButton/CardButton'
import { Pedido } from '../../models/Pedido'
import { currencyFormat } from '../../services/CurrencyService'
import { PedidoItem } from '../../models/PedidoItem'
import { TipoEntrega } from '../../enums/TipoEntrega.enum'
import { ItemIndisponivel, TextoEmAberto } from './styles'
import { Etapa } from '../../enums/Etapa.enum'
import { useHistory } from 'react-router'
import { PedidoService } from '../../services/PedidoService'
import StorageService from '../../services/StorageService'

interface ICardMeuPedidoProps {
  pedido: Pedido
}

const CardMeuPedido: React.FC<ICardMeuPedidoProps> = ({
  pedido
}) => {
  
  const history = useHistory()
  const [expandir, setExpandir] = useState(false)

  const pedidoService = new PedidoService()
  const storage = new StorageService()

  const icon = expandir ? faChevronDown : faChevronLeft

  const dataPedido = pedido.dataAlteracaoFormatada
  const fornecedorNome = pedido.fornecedor?.nome
  const pedidoAberto = pedido.etapa === Etapa.Aberto

  const renderAdicionais = (item: PedidoItem) => {
    if (!item.adicionais?.length) return null

    return (
      <Container columnDirection marginTop='5px'>
        <StaticText textSize='0.7em' bold>Adicionais</StaticText>
        {item.adicionais.map(adicional => {
          const indisponivel = adicional.indisponivel ? <ItemIndisponivel>(indisponível)</ItemIndisponivel> : null
          return (
            <StaticText key={`adicional-${adicional.pedidoAdicionalId}`} textSize='0.7em' textColor='var(--color-gray-2)'>
              {adicional.itemCardapioAdicionalNome}
              {indisponivel}
            </StaticText>
          )
        })}
      </Container>
    )
  }

  const renderOpcoes = (item: PedidoItem) => {
    if (!item.opcoes?.length) return null

    return (
      <Container columnDirection marginTop='5px'>
        <StaticText textSize='0.7em' bold>Opções</StaticText>
        {item.opcoes.map(opcao => {
          const indisponivel = opcao.indisponivel ? <ItemIndisponivel>(indisponível)</ItemIndisponivel> : null
          const grupoOpcaoNome = item.itemCardapio?.gruposOpcoes?.find(x => x.grupoOpcoesId === opcao.grupoOpcoesId)?.grupoOpcoes?.nome || ''
          const grupoOpcaoNomeRender =  grupoOpcaoNome ? ` (${grupoOpcaoNome})` : ''
          return (
            <StaticText key={`opcao-${opcao.pedidoItemOpcaoId}`} textSize='0.7em' textColor='var(--color-gray-2)'>
              {opcao.itemCardapioNomeOpcao}{grupoOpcaoNomeRender}
              {indisponivel}
            </StaticText>
          )
        })}
      </Container>
    )
  }

  const renderSabores = (item: PedidoItem) => {
    if (!item.sabores?.length) return null

    return (
      <Container columnDirection marginTop='5px'>
        <StaticText textSize='0.7em' bold>Sabores</StaticText>
        {item.sabores.map(sabor => {
          const indisponivel = sabor.indisponivel ? <ItemIndisponivel>(indisponível)</ItemIndisponivel> : null
          return (
            <StaticText key={`opcao-${sabor.saborPedidoId}`} textSize='0.7em' textColor='var(--color-gray-2)'>
              {sabor.itemCardapioNome}
              {indisponivel}
            </StaticText>
          )
        })}
      </Container>
    )
  }

  const renderItens = () => {
    return pedido.itens.map(item => {
      const nome = item.itemCardapioNome + ' ' + item.precoItemCardapioDescricao
      const preco = item.preco || 0
      const precoFormatado = currencyFormat(preco, 'R$')
      const total = item.quantidade * preco
      const totalFormatado = currencyFormat(total, 'R$')
      const valores = `${item.quantidade} x ${precoFormatado} = ${totalFormatado}`
      const indisponivel = item.indisponivel ? <ItemIndisponivel>(indisponível)</ItemIndisponivel> : null
      return (
        <Container key={`item-${item.pedidoItemId}`} columnDirection paddingTop='3px' marginTop='5px' borderTop='1px solid var(--color-gray-4)'>
          <StaticText textSize='0.6em' textColor='var(--color-gray-3)'>
            {item.itemCardapio?.categoria?.descricao}
          </StaticText>
          <StaticText textSize='0.9em'>
            {nome}
            {indisponivel}
          </StaticText>
          <StaticText textSize='0.7em' textColor='var(--color-gray-2)'>{valores}</StaticText>
          {renderSabores(item)}
          {renderOpcoes(item)}
          {renderAdicionais(item)}
        </Container>
      )
    })
  }

  const renderEntrega = () => {
    let tipoEntrega = ''
    let detalhes = ''
    if (pedido.tipoEntrega === TipoEntrega.Delivery) {
      tipoEntrega = 'ENDEREÇO DE ENTREGA'
      detalhes = pedido.enderecoEntrega
    } else if (pedido.tipoEntrega === TipoEntrega.RetirarNoLocal) {
      tipoEntrega = 'ENTREGA'
      detalhes = pedido.enderecoEntrega || 'Retirar no local'
    }
    if (tipoEntrega && detalhes) {
      return (
        <>
        <StaticText bold textSize='0.8em' marginTop='10px'>{tipoEntrega}</StaticText>
        <Container columnDirection>
          <StaticText textSize='0.9em'>{detalhes}</StaticText>
        </Container>
        </>
      )
    }
    return null
  }

  const renderPagamento = () => {
    if (!pedido?.pagamentos.length) return null

    return (
      <>
      <StaticText bold textSize='0.8em' marginTop='10px'>PAGAMENTO</StaticText>
      <Container columnDirection>
        {pedido.pagamentos.map(pagamento => {
          return (
            <StaticText key={`pagamento-${pagamento.pedidoPagamentoId}`} textSize='0.9em'>{pagamento.formaPagamentoFornecedor?.formaPagamento?.nome}</StaticText>
          )
        })}
      </Container>
      </>
    )
  }

  const renderObservacao = () => {
    if (!pedido.observacao) return null

    return (
      <>
      <StaticText bold textSize='0.8em' marginTop='10px'>OBSERVAÇÃO</StaticText>
      <Container columnDirection>
        <StaticText textSize='0.9em'>{pedido.observacao}</StaticText>
      </Container>
      </>
    )
  }

  const continuarPedidoAberto = () => {
    const fornecedor = pedido.fornecedor?.identificador
    let municipio = ''
    let estado = ''
    if (pedido.fornecedor?.enderecos.length) {
      municipio = pedido.fornecedor?.enderecos[0].municipio?.identificador || ''
      estado = pedido.fornecedor?.enderecos[0].municipio?.estado?.uf.toLocaleLowerCase() || ''
    }
    let link = `/carrinho/${fornecedor}`
    if (municipio && estado) {
      link += `/em/${municipio}/${estado}`
    }
    history.push(link)
  }

  const pedirNovamente = () => {
    const clienteId = storage.obterSessaoCliente()?.clienteId || 0
    if (!clienteId) return
    pedidoService.pedirNovamente(clienteId, pedido.pedidoId)
      .then(response => {
        if (response.data.sucesso && response.data.resultado?.valor) {
          continuarPedidoAberto()
        }
      })
  }

  const renderBody = () => {
      if (!expandir) return null

      const indisponivel = pedido.itens.every(item => item.indisponivel)
      
      const renderBotaoPedir = () => {
        if (indisponivel) return null

        return (
          <CardButtons borderTop>
            {pedidoAberto
              ? <CardButton text='Pedir' click={continuarPedidoAberto} />
              : <CardButton text='Pedir Novamente' click={pedirNovamente} />}
          </CardButtons>
        )
      }

      return (
          <Container borderTop='1px solid var(--color-gray-4)' columnDirection backgroundColor='#fafafa'>
            <Container columnDirection padding='10px'>
              <StaticText bold textSize='0.8em'>ITENS</StaticText>
              {renderItens()}
              {renderEntrega()}
              {renderPagamento()}
              {renderObservacao()}
            </Container>
            {renderBotaoPedir()}
        </Container>
      )
  }

  const textoEmAberto = pedidoAberto ? <TextoEmAberto>(em aberto)</TextoEmAberto> : null

  return (
      <Card displayFlex flexColumn>
        <Container flexSize='1' center padding='10px' clickable onClick={() => setExpandir(!expandir)}>
          <Container flexSize='1' columnDirection>
            <StaticText textColor='var(--color-gray-2)' textSize='0.9em'>{dataPedido}{textoEmAberto}</StaticText>
            <StaticText bold textSize='1em'>{fornecedorNome}</StaticText>
          </Container>
          <Container flexSize='0.10' justifyContent='flex-end'>
            <FontAwesomeIcon icon={icon} size='1x' color='var(--color-gray-1)' title='Recolher'/>
          </Container>
        </Container>
        {renderBody()}
      </Card>
  )
}
  
export default CardMeuPedido